import React, { useEffect, useState } from "react";
import { getPartNumbersByVendor, getVendorsAndManufacturers } from "../../api/searchController";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const fetchVendorData = async ({
	setVendorOptions,
	setLoading
}) => {
	setLoading(true);
	try {
		const response = await getVendorsAndManufacturers();
		if (response.success) {
			const sortedVendors = response.data.sort();
			setVendorOptions(sortedVendors);
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const fetchPartNumberData = async ({
	vendor,
	setPartNumberOptions,
	setLoading
}) => {
	if (!vendor) return;

	setLoading(true);
	try {
		const response = await getPartNumbersByVendor(vendor);
		if (response.success) {
			const sortedPartNumbers = response.data.sort();
			setPartNumberOptions(sortedPartNumbers);
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const PartNumberSearch = () => {
	const navigate = useNavigate();
	const [vendorOptions, setVendorOptions] = useState([]);
	const [partNumberOptions, setPartNumberOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [vendorChosen, setVendorChosen] = useState(null);
	const [partNumberChosen, setPartNumberChosen] = useState(null);

	const [mobileModalOpen, setMobileModalOpen] = useState(false);

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	const handleMobileModalOpen = () => {
		setVendorChosen(null);
		setPartNumberChosen(null);
		setMobileModalOpen(true);
	}
	const handleMobileModalClose = () => setMobileModalOpen(false);

	useEffect(() => {
		if (isMobile === false) {
			fetchVendorData({
				setVendorOptions,
				setLoading
			});
		}
	}, []);

	useEffect(() => {
		fetchPartNumberData({
			vendor: vendorChosen,
			setPartNumberOptions,
			setLoading
		});
	}, [vendorChosen]);

	useEffect(() => {
		if (mobileModalOpen === true) {
			fetchVendorData({
				setVendorOptions,
				setLoading
			});
		}
	}, [mobileModalOpen]);

	const submitHandler = (e) => {
		e.preventDefault();
		navigate(`/product/${partNumberChosen}`);
	};

	const handleVendorChange = (e, newValue) => {
		setVendorChosen(newValue);
	};

	const handlePartNumberChange = (e, newValue) => {
		setPartNumberChosen(newValue);
	};

	return (
		<>
			{isMobile === false &&
				<Box sx={{ width: '100%', padding: 4, backgroundColor: '#262626', borderRadius: 2 }}>
					<Typography variant="h5" sx={{ mb: 3, textAlign: 'center', color: '#ffffff' }}>
						Search by Part Number
					</Typography>
					<form onSubmit={submitHandler}>
						<Grid container spacing={2} alignItems="center" justifyContent="center">
							<Grid item xs={3}>
								<Autocomplete
									loading={loading}
									value={vendorChosen}
									onChange={handleVendorChange}
									options={vendorOptions}
									getOptionLabel={(option) => option}
									disabled={loading}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Vendor"
											variant="outlined"
											size="small"
											InputLabelProps={{ style: { color: '#ffffff' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#ffffff' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#888888', // Darker gray border
														borderWidth: '2px', // Thicker border
													},
													'&:hover fieldset': {
														borderColor: '#ffffff', // White border on hover
													},
													'&.Mui-focused fieldset': {
														borderColor: '#ffffff', // White border when focused
													},
												},
												'& .MuiSvgIcon-root': {
													color: '#ffffff', // White arrow color
												},
												'& .MuiAutocomplete-clearIndicator': {
													color: '#ffffff', // White clear button color
												},
											}}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<Autocomplete
									loading={loading}
									value={partNumberChosen}
									onChange={handlePartNumberChange}
									options={partNumberOptions}
									disabled={!partNumberChosen || loading}
									getOptionLabel={(option) => option}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Part Number"
											variant="outlined"
											size="small"
											InputLabelProps={{ style: { color: '#ffffff' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#ffffff' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#888888', // Darker gray border
														borderWidth: '2px', // Thicker border
													},
													'&:hover fieldset': {
														borderColor: '#ffffff', // White border on hover
													},
													'&.Mui-focused fieldset': {
														borderColor: '#ffffff', // White border when focused
													},
												},
												'& .MuiSvgIcon-root': {
													color: '#ffffff', // White arrow color
												},
												'& .MuiAutocomplete-clearIndicator': {
													color: '#ffffff', // White clear button color
												},
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
						<Box sx={{ mt: 4, textAlign: 'center' }}>
							<Button variant="contained" size="small" color="error" type="submit" disabled={loading}>
								Search
							</Button>
						</Box>
					</form>
				</Box>
			}
			{isMobile === true &&
				<Box sx={{ width: '100%', padding: 2, backgroundColor: '#262626', borderRadius: 2, alignItems: 'center' }}>
					<Typography variant="h6" sx={{ mb: 3, textAlign: 'center', color: '#ffffff' }}>
						Search by Part Number
					</Typography>
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center'}}>
						<Button
							variant="contained"
							color="error"
							size="small"
							onClick={handleMobileModalOpen}
							startIcon={<TouchAppIcon fontSize="small" />}
							sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
						>
							Search by Part Number
						</Button>
					</Box>


					<Modal
						open={mobileModalOpen}
						onClose={handleMobileModalClose}
						sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
					>
						<Box
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: "90%",
								bgcolor: "#FFFFFF",
								boxShadow: 24,
								p: 2,
							}}
						>
							<form onSubmit={submitHandler}>
								<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 4 }}>
									<Typography variant="body2" fontWeight="bold">
										Search by Part Number
									</Typography>
									<IconButton onClick={handleMobileModalClose} size="small">
										<CloseIcon fontSize="small" />
									</IconButton>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Autocomplete
											loading={loading}
											disabled={loading}
											value={vendorChosen}
											onChange={handleVendorChange}
											options={vendorOptions}
											getOptionLabel={(option) => option}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Vendor"
													variant="outlined"
													size="small"
													InputLabelProps={{ style: { color: '#000000' } }}
													InputProps={{
														...params.InputProps,
														style: { color: '#000000' },
													}}
													sx={{
														'& .MuiOutlinedInput-root': {
															'& fieldset': {
																borderColor: '#000000',
															},
															'&:hover fieldset': {
																borderColor: '#000000',
															},
															'&.Mui-focused fieldset': {
																borderColor: '#000000',
															},
														},
													}}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Autocomplete
											loading={loading}
											value={partNumberChosen}
											onChange={handlePartNumberChange}
											disabled={!vendorChosen || loading}
											options={partNumberOptions}
											getOptionLabel={(option) => option}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Part Number"
													variant="outlined"
													size="small"
													InputLabelProps={{ style: { color: '#000000' } }}
													InputProps={{
														...params.InputProps,
														style: { color: '#000000' },
													}}
													sx={{
														'& .MuiOutlinedInput-root': {
															'& fieldset': {
																borderColor: '#000000',
															},
															'&:hover fieldset': {
																borderColor: '#000000',
															},
															'&.Mui-focused fieldset': {
																borderColor: '#000000',
															},
														},
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
								<Box sx={{ mt: 4, textAlign: 'center' }}>
									<Button variant="contained" color="error" type="submit" disabled={loading} size="small">
										Search
									</Button>
								</Box>
							</form>
						</Box>
					</Modal>
				</Box>
			}
		</>
		// <section>
		// 	<article>
		// 		<h2 className="color-white text-align-center">PART NUMBER</h2>
		// 		<Form
		// 			className="mt-3"
		// 			inverted
		// 			onSubmit={submitHandler}
		// 			size="small"
		// 			fluid
		// 		>
		// 			<Form.Group inline widths="equal">
		// 				<Form.Field>
		// 					<Form.Select
		// 						fluid
		// 						name="vendor"
		// 						label="Vendor"
		// 						options={vendorOptions}
		// 						placeholder="Vendor"
		// 						value={vendorChosen}
		// 						onChange={handleVendorChange}
		// 						loading={loading}
		// 						search
		// 						clearable
		// 					/>
		// 				</Form.Field>
		// 				<Form.Field className="model_input_container">
		// 					<Form.Select
		// 						fluid
		// 						name="partNumber"
		// 						label="Part Number"
		// 						options={partNumberOptions}
		// 						placeholder="Part Number"
		// 						value={partNumberChosen}
		// 						onChange={handlePartNumberChange}
		// 						loading={loading}
		// 						disabled={!vendorChosen}
		// 						search
		// 						clearable
		// 					/>
		// 				</Form.Field>
		// 				<Button className="form-button" type="submit" color="red">
		// 					Search
		// 				</Button>
		// 			</Form.Group>
		// 		</Form>
		// 	</article>
		// </section>
	);
};

export default PartNumberSearch;
