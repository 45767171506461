import React, { Fragment, useEffect } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const RegisterPage = () => {
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {})

	useEffect(() => {
		const returnTo = location.state?.from?.pathname || '/';
		const handleLogin = () => {
			loginWithRedirect({
				authorizationParams: {
					redirect_uri: `${window.location.origin}/post-login`,
					screen_hint: "signup",
				},
				appState: { returnTo }
			});
		};

		if (isLoading === false) {
			if (isAuthenticated === false){
				handleLogin();
			} else {
				navigate(returnTo);
			}
		}
	}, [loginWithRedirect, location, isLoading, isAuthenticated]);

	return (
		<>
			<div>
				Redirecting to registration...
			</div>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={!!isLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</>
	);
};
export default RegisterPage;
