import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import {
	useLocation,
	useNavigate,
	useParams
} from "react-router-dom";
import { cloneDeep } from "lodash";
import { ApiCallStatus } from "../../utils/constants";
import {
	fetchProductsStockInfo, fetchPromotionProductsData
} from "../PageFunctions/productFunctions";
import ProductGrid from "../../components/ProductGrid";
import { useAuth0 } from "@auth0/auth0-react";
import PromotionProductGrid from "../../components/PromotionProductGrid";
import { fetchProductsPrices } from "../PageFunctions/priceFunctions";


const clearCookies = async () => {
	await removeAuthCookies();
};

const PromotionProductsPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const navigate = useNavigate();
	const location = useLocation();
	const {handle} = useParams();
	const [promotionProducts, setPromotionProducts] = useState(null);
	const [stockInfo, setStockInfo] = useState(null);
	const [pricingData, setPricingData] = useState(null);

	const [total, setTotal] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [activeFilters, setActiveFilters] = useState({
		pageNumber: null
	});
	const [apiParametersReady, setApiParametersReady] = useState(false);

	const [pricesCallStatus, setPricesCallStatus] = useState(ApiCallStatus.NotStarted);
	const [promotionProductsCallStatus, setPromotionProductsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [stockInfoCallStatus, setStockInfoCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchPromotionProductsDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPromotionProductsData({
			auth0Token,
			handle,
			pageNumber: activeFilters.pageNumber,
			setPromotionProducts,
			setTotal,
			setPageSize,
			setStatusInProgress: () => setPromotionProductsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPromotionProductsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPromotionProductsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPromotionProductsCallStatus(ApiCallStatus.Error)
		});
	}

	const fetchPricesDataAsync = async () => {
		const productSkus = promotionProducts.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsPrices({
			auth0Token,
			productSkus,
			setPricingData,
			setStatusInProgress: () => setPricesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPricesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPricesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPricesCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchProductStockInfoAsync = async () => {
		const productSkus = promotionProducts.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsStockInfo({
			auth0Token,
			productSkus,
			setStockInfo,
			setStatusInProgress: () => setStockInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setStockInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setStockInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setStockInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const handlePageNumberChange = async (event, value) => {
		await updateUrlQueryValue("pageNumber", Number(value));
	};

	const updateUrlQueryValue = async (key, value) => {
		setPromotionProductsCallStatus(ApiCallStatus.NotStarted);
		const searchParams = new URLSearchParams(location.search);

		searchParams.set(key, value);
		window.location.href = `${location.pathname}${!!searchParams.size > 0 ? "?" : ""}${searchParams.toString()}`;
	};

	const updateQueryParameters = async (queryParameters) => {
		window.location.href = `${location.pathname}${!!queryParameters ? "?" : ""}${queryParameters}`;
	}

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (apiParametersReady === true) {
			fetchPromotionProductsDataAsync();
		}
	}, [apiParametersReady]);


	useEffect(() => {
		scrollTop();

		const searchParams = new URLSearchParams(location.search);

		const activeFiltersCopy = cloneDeep(activeFilters);

		const pn = searchParams.get("pageNumber");

		activeFiltersCopy.pageNumber = Number(pn) ?? 1;

		setPageNumber(Number(pn) ?? 1);
		setActiveFilters(activeFiltersCopy);
		setApiParametersReady(true);
	}, [location]);

	useEffect(() => {
		if (promotionProductsCallStatus === ApiCallStatus.Succeeded && !!promotionProducts && promotionProducts.length > 0) {
			fetchProductStockInfoAsync();
			fetchPricesDataAsync();
		}
	}, [promotionProductsCallStatus, promotionProducts]);

	const allLoadingStates = [
		promotionProductsCallStatus,
		stockInfoCallStatus,
		pricesCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<PromotionProductGrid
				promotionProducts={promotionProducts}
				stockInfo={stockInfo}
				pricingData={pricingData}
				customerCategory={genericPageContent?.customerCategory}
				activeFilters={activeFilters}
				setActiveFilters={setActiveFilters}
				total={total}
				pageNumber={pageNumber}
				pageSize={pageSize}
				handlePageNumberChange={handlePageNumberChange}
				updateQueryParameters={updateQueryParameters}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default PromotionProductsPage;