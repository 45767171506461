import React, { Fragment, useEffect, useState } from "react";
import GoogleAutoComplete from "../GoogleAutoComplete";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { cloneDeep, get, toLower, trim } from "lodash";
import { createAddressData, fetchGooglePlaceDetails } from "../../main-component/PageFunctions/addressFunctions";
import { ApiCallStatus } from "../../utils/constants";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid, MenuItem, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import PageHeader from "../PageHeader";


const CreateAddress = ({
	countries,
	provinces,
	email,
	closeWithSuccess,
	closeWithFailure
}) => {
	const {isAuthenticated, getAccessTokenSilently} = useAuth0();
	const [googlePlaceDetailsResponse, setGooglePlaceDetailsResponse] =
		useState(null);
	const [googleAddressValue, setGoogleAddressValue] = useState(null);
	const [countryOptions, setCountryOptions] = useState([]);
	const [provinceOptions, setProvinceOptions] = useState([]);
	const [formDropdownOptionsChosen, setFormDropdownOptionsChosen] = useState({
		countryId: null,
		provinceId: null
	});
	const schema = yup
		.object({
			firstName: yup.string().required("First name is required"),
			lastName: yup.string().required("Last name is required"),
			countryId: yup.string(),
			streetAddress: yup.string().required("Street address is required"),
			suburb: yup.string(),
			city: yup.string(),
			postalCode: yup.string(),
			provinceId: yup.string()
		})
		.required();
	const {
		control,
		trigger,
		getValues,
		setValue,
		formState: {errors},
		clearErrors
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: "",
			lastName: "",
			countryId: "",
			streetAddress: "",
			suburb: "",
			city: "",
			postalCode: "",
			provinceId: ""
		}
	});

	const [createAddressDataCallStatus, setCreateAddressDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchGooglePlaceDetailsCallStatus, setFetchGooglePlaceDetailsCallStatus] = useState(ApiCallStatus.NotStarted);

	const onSaveNewAddressClick = async () => {
		const result = await trigger([
			"firstName",
			"lastName",
			"countryId",
			"streetAddress",
			"suburb",
			"city",
			"postalCode",
			"provinceId"
		]);

		if (!!result) {
			await createNewAddressAsync({
				firstName: getValues("firstName") ? getValues("firstName") : null,
				lastName: getValues("lastName") ? getValues("lastName") : null,
				streetAddress: getValues("streetAddress")
					? getValues("streetAddress")
					: null,
				countryId: getValues("countryId") ? getValues("countryId") : null,
				suburb: getValues("suburb") ? getValues("suburb") : null,
				city: getValues("city") ? getValues("city") : null,
				postalCode: getValues("postalCode") ? getValues("postalCode") : null,
				provinceId: getValues("provinceId") ? getValues("provinceId") : null,
				email
			});
		}
	};

	const createNewAddressAsync = async ({
		firstName,
		lastName,
		streetAddress,
		countryId,
		suburb,
		city,
		postalCode,
		provinceId,
		email
	}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createAddressData({
			auth0Token,
			firstName,
			lastName,
			streetAddress,
			countryId,
			suburb,
			city,
			postalCode,
			provinceId,
			email,
			setStatusInProgress: () =>
				setCreateAddressDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () =>
				setCreateAddressDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () =>
				setCreateAddressDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateAddressDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchGooglePlaceDetailsAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchGooglePlaceDetails({
			auth0Token,
			placeId: get(googleAddressValue, "place_id"),
			description: get(googleAddressValue, "description"),
			setGooglePlaceDetailsResponse,
			setStatusInProgress: () =>
				setFetchGooglePlaceDetailsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () =>
				setFetchGooglePlaceDetailsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () =>
				setFetchGooglePlaceDetailsCallStatus(ApiCallStatus.Failed),
			setStatusError: () =>
				setFetchGooglePlaceDetailsCallStatus(ApiCallStatus.Error)
		});
	};

	const onOptionChange = (_, {name, value}) => {
		const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);
		optionsChosenCopy[name] = value;
		setValue(name, value);
		clearErrors(name);
		setFormDropdownOptionsChosen(optionsChosenCopy);
	};

	useEffect(() => {
		let countryOptionsTemp;
		countryOptionsTemp = countries.map((x) => {
			return {
				label: x.name,  // Use 'label' instead of 'text' for MUI Select
				value: x.countryId  // Use 'value' to match MUI Select's required field
			};
		});
		setCountryOptions(countryOptionsTemp);
	}, [countries]);

	useEffect(() => {
		let provinceOptionsTemp;
		provinceOptionsTemp = provinces.map((x) => {
			return {
				label: x.name,  // Use 'label' for MUI Select
				value: x.provinceId  // Use 'value' for MUI Select
			};
		});
		setProvinceOptions(provinceOptionsTemp);
	}, [provinces]);

	useEffect(() => {
		if (
			fetchGooglePlaceDetailsCallStatus === ApiCallStatus.Succeeded &&
			!!googlePlaceDetailsResponse
		) {
			let streetNumber,
				route,
				sublocality,
				locality,
				province,
				country,
				postalCode;

			streetNumber = googlePlaceDetailsResponse?.streetNumber ?? "";
			route = googlePlaceDetailsResponse?.route ?? "";
			sublocality = googlePlaceDetailsResponse?.sublocality ?? "";
			locality = googlePlaceDetailsResponse?.locality ?? "";
			province = googlePlaceDetailsResponse?.province ?? "";
			country = googlePlaceDetailsResponse?.country ?? "";
			postalCode = googlePlaceDetailsResponse?.postalCode ?? "";

			setValue("streetAddress", trim(`${streetNumber} ${route}`));
			setValue("suburb", trim(sublocality));
			setValue("city", trim(locality));
			setValue("postalCode", trim(postalCode));

			const optionsChosenCopy = cloneDeep(formDropdownOptionsChosen);

			if (!!province && !!provinces) {
				const foundProvince = provinces.find(
					(x) => toLower(x.name) === trim(toLower(province))
				);

				if (foundProvince) {
					optionsChosenCopy["provinceId"] = foundProvince.provinceId;
					setValue("provinceId", foundProvince.provinceId);
					clearErrors("provinceId");
				}
			}

			if (!!country && countries) {
				const foundCountry = countries.find(
					(x) => toLower(x.name) === trim(toLower(country))
				);

				if (foundCountry) {
					optionsChosenCopy["countryId"] = foundCountry.countryId;
					setValue("countryId", foundCountry.countryId);
					clearErrors("countryId");
				}
			}

			setFormDropdownOptionsChosen(optionsChosenCopy);
		} else if (
			fetchGooglePlaceDetailsCallStatus === ApiCallStatus.Error ||
			fetchGooglePlaceDetailsCallStatus === ApiCallStatus.Failed
		) {
			closeWithFailure();
		}
	}, [fetchGooglePlaceDetailsCallStatus, googlePlaceDetailsResponse, countries, provinces]);

	useEffect(() => {
		if (!!googleAddressValue && googleAddressValue["place_id"]) {
			fetchGooglePlaceDetailsAsync();
		}
	}, [googleAddressValue]);


	useEffect(() => {
		if (createAddressDataCallStatus === ApiCallStatus.Succeeded) {
			closeWithSuccess();
		}
		else if (
			createAddressDataCallStatus === ApiCallStatus.Error ||
			createAddressDataCallStatus === ApiCallStatus.Failed
		) {
			closeWithFailure();
		}
	}, [createAddressDataCallStatus]);

	const allLoadingStates = [
		fetchGooglePlaceDetailsCallStatus,
		createAddressDataCallStatus
	];

	return (
		<Fragment>
			<PageHeader title="Add Address" />
			<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: 2 }}>
				<Paper elevation={3} sx={{ padding: '2rem' }}>
					{!!provinces && !!countries && (
						<form>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<GoogleAutoComplete
										value={googleAddressValue}
										setValue={setGoogleAddressValue}
									/>
								</Grid>

								{/* First Name */}
								<Grid item xs={12} sm={6}>
									<Controller
										name="firstName"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="First Name"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.firstName?.message}
											/>
										)}
									/>
								</Grid>

								{/* Last Name */}
								<Grid item xs={12} sm={6}>
									<Controller
										name="lastName"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="Last Name"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.lastName?.message}
											/>
										)}
									/>
								</Grid>

								{/* Country */}
								<Grid item xs={12}>
									<TextField
										select
										label="Country"
										variant="outlined"
										fullWidth
										value={formDropdownOptionsChosen.countryId}
										onChange={onOptionChange}
										error={!!errors?.countryId}
										helperText={errors?.countryId?.message}
										SelectProps={{
											native: false, // Ensure you are using the default MUI select style
										}}
										InputLabelProps={{
											shrink: !!formDropdownOptionsChosen.countryId, // Shrink label when a value is selected
										}}
									>
										{countryOptions.map(option => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								{/* Street Address */}
								<Grid item xs={12}>
									<Controller
										name="streetAddress"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="Street Address"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.streetAddress?.message}
											/>
										)}
									/>
								</Grid>

								{/* Suburb */}
								<Grid item xs={12}>
									<Controller
										name="suburb"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="Suburb"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.suburb?.message}
											/>
										)}
									/>
								</Grid>

								{/* City and Postal Code */}
								<Grid item xs={12} sm={6}>
									<Controller
										name="city"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="City"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.city?.message}
											/>
										)}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Controller
										name="postalCode"
										control={control}
										render={({ field, fieldState: { error, isDirty } }) => (
											<TextField
												label="Postal Code"
												variant="outlined"
												fullWidth
												{...field}
												error={!!error && !isDirty}
												helperText={errors?.postalCode?.message}
											/>
										)}
									/>
								</Grid>

								{/* Province */}
								<Grid item xs={12}>
									<TextField
										select
										label="Province"
										variant="outlined"
										fullWidth
										value={formDropdownOptionsChosen.provinceId}
										onChange={onOptionChange}
										error={!!errors?.provinceId}
										helperText={errors?.provinceId?.message}
										SelectProps={{
											native: false, // Ensure you are using the default MUI select style
										}}
										InputLabelProps={{
											shrink: !!formDropdownOptionsChosen.provinceId, // Shrink label when a value is selected
										}}
									>
										{provinceOptions.map(option => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								{/* Save Button */}
								<Grid item xs={12} textAlign="right">
									<Button
										size="small"
										variant="contained"
										color="error"
										onClick={onSaveNewAddressClick}
									>
										Save
									</Button>
								</Grid>
							</Grid>
						</form>
					)}
				</Paper>
			</Box>
		</Fragment>
	);
};

export default CreateAddress;
