import { getManufacturers, getModels, getVariants, getYears } from "../api/vehicleController";
import { useEffect, useState } from "react";

const fetchManufacturersData = async ({
	userInput,
	setManufacturerOptions,
	setLoading
}) => {
	setLoading(true);
	try {
		const response = await getManufacturers(userInput.year);

		if (!!response?.success) {
			const sortedManufacturers = response.data.sort();
			setManufacturerOptions(sortedManufacturers);
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const fetchYearsData = async ({userInput, setYearOptions, setLoading}) => {
	setLoading(true);
	try {
		let response = await getYears(
			userInput.manufacturer,
			userInput.model,
			userInput.variant
		);

		if (!!response?.success) {
			const sortedYears = response.data.sort();
			setYearOptions(sortedYears);
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const fetchModelsData = async ({userInput, setModelOptions, setLoading}) => {
	setLoading(true);
	try {
		if (userInput.manufacturer || userInput.year) {
			const response = await getModels(userInput.manufacturer, userInput.year);

			if (!!response?.success) {
				const sortedModels = response.data.sort();
				setModelOptions(sortedModels);
			}
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const fetchVariantsData = async ({
	userInput,
	setVariantOptions,
	setLoading
}) => {
	setLoading(true);
	try {
		if (userInput.manufacturer && userInput.model) {
			const response = await getVariants(
				userInput.manufacturer,
				userInput.model,
				userInput.year
			);

			if (!!response?.success) {
				const sortedVariants = response.data.sort();
				setVariantOptions(sortedVariants);
			}
		}
	}
	catch (error) {
		console.error(error);
	}
	finally {
		setLoading(false);
	}
};

const useMakeModelYear = () => {
	const [yearOptions, setYearOptions] = useState([]);
	const [manufacturerOptions, setManufacturerOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [variantOptions, setVariantOptions] = useState([]);
	const [loading, setLoading] = useState(true);

	const [userInput, setUserInput] = useState({
		manufacturer: null,
		model: null,
		variant: null,
		year: null
	});

	useEffect(() => {
		fetchManufacturersData({
			userInput,
			setManufacturerOptions,
			setLoading
		});
	}, []);

	useEffect(() => {
		fetchModelsData({
			userInput,
			setModelOptions,
			setLoading
		});
	}, [userInput.manufacturer]);

	useEffect(() => {
		fetchVariantsData({
			userInput,
			setVariantOptions,
			setLoading
		});
	}, [userInput.model]);

	useEffect(() => {
		fetchYearsData({
			userInput,
			setYearOptions,
			setLoading
		});
	}, [userInput.variant]);

	return {
		yearOptions,
		manufacturerOptions,
		modelOptions,
		variantOptions,
		loading,
		userInput,
		setUserInput
	}
}

export default useMakeModelYear;