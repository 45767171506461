import React, { Fragment, useEffect  } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import Popia from "../../components/Popia";


const PopiaPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	const allLoadingStates = [
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false;

	return (
		<Container maxWidth="lg">
			<Popia/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default PopiaPage;
