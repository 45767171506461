import React from 'react';
import { styled } from "@mui/system";
import {
	Box,
	Button,
	Divider, List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper, SwipeableDrawer,
	Typography
} from "@mui/material";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";

const MobileCartSummary = ({
	settlementAmount,
	vat,
	cartNettTotal,
	deliveryFeeToDisplay,
	vatAmount,
	total,
	logisticsFee,
	buttonText,
	onButtonClick,
	buttonDisabled
}) => {
	const SummaryContainer = styled(Paper)(({ theme }) => ({
		padding: theme.spacing(2),
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		boxShadow: theme.shadows[24],
		borderRadius: '16px 16px 0 0',
		zIndex: theme.zIndex.drawer + 2,
	}));

	return (
		<>
			<SummaryContainer elevation={4}>
				<List>
					<ListItem>
						<ListItemText primary="Nett Total" />
						<ListItemSecondaryAction>
							<Typography variant="body2" fontWeight="bold">{SouthAfricanRand.format(cartNettTotal)}</Typography>
						</ListItemSecondaryAction>
					</ListItem>
					{settlementAmount > 0 && (
						<ListItem>
							<ListItemText primary="Settlement Discount" />
							<ListItemSecondaryAction>
								<Typography variant="body2" fontWeight="bold">({SouthAfricanRand.format(settlementAmount)})</Typography>
							</ListItemSecondaryAction>
						</ListItem>
					)}
					<ListItem>
						<ListItemText primary={`VAT (${vat}%)`} />
						<ListItemSecondaryAction>
							<Typography variant="body2" fontWeight="bold">{SouthAfricanRand.format(vatAmount)}</Typography>
						</ListItemSecondaryAction>
					</ListItem>
					{logisticsFee > 0 && (
						<ListItem>
							<ListItemText primary="Delivery Fee" />
							<ListItemSecondaryAction>
								<Typography variant="body2" fontWeight="bold">{deliveryFeeToDisplay()}</Typography>
							</ListItemSecondaryAction>
						</ListItem>
					)}
					<Divider sx={{ my: 1 }} />
					<ListItem>
						<ListItemText primary="Total" />
						<ListItemSecondaryAction>
							<Typography variant="body2" fontWeight="bold">
								{SouthAfricanRand.format(total)}
							</Typography>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
				<Button
					variant="contained"
					color="error"
					size="small"
					fullWidth
					sx={{ marginTop: '1rem', padding: '0.75rem', borderRadius: '12px' }}
					onClick={onButtonClick}
					disabled={buttonDisabled}
				>
					{buttonText}
				</Button>
			</SummaryContainer>
		</>
	)
}

export default MobileCartSummary;