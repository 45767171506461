import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { ApiCallStatus } from "../utils/constants";
import { concat, uniqBy } from "lodash";
import { fetchSearchSuggestions } from "../main-component/PageFunctions/productFunctions";
import { fetchProductsPrices } from "../main-component/PageFunctions/priceFunctions";
import ReactGA from "react-ga4";

const useArgusSearch = (customerCategory) => {
	const {isAuthenticated, getAccessTokenSilently} = useAuth0();
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [products, setProducts] = useState(null);
	const [pricingData, setPricingData] = useState(null);

	const [searchResultsCallStatus, setSearchResultsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [pricesCallStatus, setPricesCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchSearchSuggestionsAsync = async () => {
		await fetchSearchSuggestions({
			searchTerm,
			category: customerCategory,
			setSearchResults,
			setStatusInProgress: () => setSearchResultsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setSearchResultsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setSearchResultsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setSearchResultsCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchPricesDataAsync = async () => {
		const productSkus = products.map((x) => x.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsPrices({
			auth0Token,
			productSkus,
			setPricingData,
			setStatusInProgress: () => setPricesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPricesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPricesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPricesCallStatus(ApiCallStatus.Error)
		});
	};

	useEffect(() => {
		if (searchTerm && searchTerm.length > 2) {

			ReactGA.event({
				category: 'Ecommerce',
				action: 'search',
				value: searchTerm,
				nonInteraction: false
			});

			fetchSearchSuggestionsAsync();
		}
	}, [searchTerm]);

	useEffect(() => {
		if (!!searchResults.products && !!searchResults.skuProducts) {
			const newProductsArray = concat(
				searchResults.products,
				searchResults.skuProducts
			);
			const uniqueArray = uniqBy(newProductsArray, (x) => x.sku);
			setProducts(uniqueArray);
		}
	}, [searchResults?.products, searchResults?.skuProducts]);

	useEffect(() => {
		if (searchResultsCallStatus === ApiCallStatus.Succeeded && !!products && products.length > 0) {
			fetchPricesDataAsync();
		}
	}, [searchResultsCallStatus, products]);

	const allLoadingStates = [
		searchResultsCallStatus,
		pricesCallStatus
	];

	const loading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return {
		setSearchTerm,
		products,
		pricingData,
		loading
	}
}

export default useArgusSearch;