import React, { Fragment } from "react";

import Error from "../../components/Error";
import { Container } from "@mui/material";

const ErrorPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	return (
		<Container maxWidth="lg">
			<Error/>
		</Container>
	);
};
export default ErrorPage;
