import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
	config: {
		connectionString: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTIONSTRING,
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
