import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import "./style.css";
import { useAuth0 } from "@auth0/auth0-react";
import MobileHeader from "../MobileHeader";
import DesktopHeader from "../DesktopHeader";
import { ScreenSize } from "../../utils/constants";
import TabletHeader from "../TabletHeader";
import useSystemHook from "../../hooks/SystemHook";
import SavedVehiclesModal from "../SavedVehiclesModal";
import SavedVehicleModal from "../SavedVehicleModal";
import ChooseVehicleModal from "../ChooseVehicleModal";

const modal_name = {
	savedVehicle: "savedVehicle",
	savedVehicles: "savedVehicles",
	chooseVehicle: "chooseVehicle",
	announcement: "announcement"
};

const SiteHeader = ({
	genericPageContent,
	genericDataLoaded,
	categoriesAndTypes,
	upsertUserPreferenceAsync,
	createUserVehicleAsync,
	removeUserVehicleAsync
}) => {
	const { logout, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
	const navigate = useNavigate();
	const {totalItems} = useCart();
	const { screenSize } = useSystemHook();

	const [openModal, setOpenModal] = useState("");
	const [currentPage, setCurrentPage] = useState(null);
	const location = useLocation();
	const [totalGarageItems, setTotalGarageItems] = useState(0);

	const pagesToExclude = ["account-verification"];

	const handleSavedVehiclesClick = (e) => {
		e.preventDefault();
		openSavedVehiclesModal();
	};

	const openSavedVehiclesModal = () => {
		if (openModal !== modal_name.savedVehicles) {
			setOpenModal(modal_name.savedVehicles);
		}
	};

	const openSavedVehicleModal = () => {
		if (openModal !== modal_name.savedVehicle) {
			setOpenModal(modal_name.savedVehicle);
		}
	};

	const openChooseVehicleModal = () => {
		if (openModal !== modal_name.chooseVehicle) {
			setOpenModal(modal_name.chooseVehicle);
		}
	};

	const openAnnouncementModal = () => {
		setOpenModal(modal_name.announcement);
	};

	const closeModals = () => {
		setOpenModal("");
	};

	const gotoLoginPage = async () => {
		closeModals();
		const returnTo = location.pathname || '/';
		if (isAuthenticated === false) {
			await loginWithRedirect({
				authorizationParams: {
					redirect_uri: `${window.location.origin}/post-login`,
				},
				appState: { returnTo }
			});
		}
	}

	const gotoCartPage = async () => {
		navigate("/cart");
	}

	const gotoRegistrationPage = async () => {
		closeModals();
		const returnTo = location.pathname || '/';
		if (isAuthenticated === false) {
			await loginWithRedirect({
				authorizationParams: {
					screen_hint: "signup",
					redirect_uri: `${window.location.origin}/post-login`,
				},
				appState: { returnTo }
			});
		}
	}

	const performLogout = async () => {
		await logout({logoutParams: {returnTo: window.location.origin}});
	};

	useEffect(() => {
		if (genericPageContent?.userVehicles) {
			setTotalGarageItems(genericPageContent?.userVehicles.length)
		}
	}, [genericPageContent?.userVehicles]);

	return (
		<Fragment>
			{screenSize === ScreenSize.Mobile &&
				<MobileHeader performLogout={performLogout}
				              gotoLoginPage={gotoLoginPage}
				              gotoRegistrationPage={gotoRegistrationPage}
				              gotoCartPage={gotoCartPage}
				              openSavedVehiclesModal={openSavedVehiclesModal}
				              cartItems={totalItems}
				              totalGarageItems={totalGarageItems}
				              categoriesAndTypes={categoriesAndTypes}
				              customerCategory={genericPageContent.customerCategory}
				              firstName={genericPageContent.firstName}
				              lastName={genericPageContent.lastName}
				              tecfinityAccountName={genericPageContent.tecfinityAccountName}
				              tecfinityAccountNumber={genericPageContent.tecfinityAccountNumber}

				/>
			}
			{screenSize === ScreenSize.Tablet &&
				<TabletHeader performLogout={performLogout}
				              gotoLoginPage={gotoLoginPage}
				              gotoRegistrationPage={gotoRegistrationPage}
				              gotoCartPage={gotoCartPage}
				              cartItems={totalItems}
				              totalGarageItems={totalGarageItems}
				              categoriesAndTypes={categoriesAndTypes}
				              openSavedVehiclesModal={openSavedVehiclesModal}
				              customerCategory={genericPageContent.customerCategory}
				              firstName={genericPageContent.firstName}
				              lastName={genericPageContent.lastName}
				              tecfinityAccountName={genericPageContent.tecfinityAccountName}
				              tecfinityAccountNumber={genericPageContent.tecfinityAccountNumber}
				/>
			}
			{screenSize === ScreenSize.Desktop &&
				<DesktopHeader performLogout={performLogout}
				               gotoLoginPage={gotoLoginPage}
				               gotoRegistrationPage={gotoRegistrationPage}
				               gotoCartPage={gotoCartPage}
				               cartItems={totalItems}
				               totalGarageItems={totalGarageItems}
				               categoriesAndTypes={categoriesAndTypes}
				               openSavedVehiclesModal={openSavedVehiclesModal}
				               customerCategory={genericPageContent.customerCategory}
				               firstName={genericPageContent.firstName}
				               lastName={genericPageContent.lastName}
				               tecfinityAccountName={genericPageContent.tecfinityAccountName}
				               tecfinityAccountNumber={genericPageContent.tecfinityAccountNumber}
				/>
			}
			{openModal === modal_name.savedVehicles &&
				<SavedVehiclesModal open={openModal === modal_name.savedVehicles}
				                    setClose={closeModals}
				                    openChooseVehicleModal={openChooseVehicleModal}
				                    userVehicles={genericPageContent.userVehicles}
				                    currentUserVehicleId={genericPageContent.userVehicleId}
				                    upsertUserPreferenceAsync={upsertUserPreferenceAsync}
				                    removeUserVehicleAsync={removeUserVehicleAsync}
				/>
			}
			{openModal === modal_name.savedVehicle &&
				<SavedVehicleModal open={openModal === modal_name.savedVehicle}
				                   setClose={closeModals}
				                   openSavedVehiclesModal={openSavedVehiclesModal}
				/>
			}
			{openModal === modal_name.chooseVehicle &&
				<ChooseVehicleModal open={openModal === modal_name.chooseVehicle}
				                    setClose={closeModals}
				                    openSavedVehiclesModal={openSavedVehiclesModal}
				                    createUserVehicleAsync={createUserVehicleAsync}
				/>
			}
		</Fragment>
	);
};

export default SiteHeader;