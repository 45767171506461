import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_WEB_API_URL;

export const getUserVehicles = async ({
	auth0Token
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/userVehicle/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = ``;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getUserVehicle = async ({
	auth0Token,
	userVehicleId
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/userVehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}


		const requestUrl = `/${userVehicleId}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const putUserVehicle = async ({
	auth0Token,
	userVehicleId,
	vehicleYearId,
	make,
	model,
	year,
	variant
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/userVehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `/${userVehicleId}`;

		const body = {
			vehicleYearId,
			make,
			model,
			year,
			variant
		};

		return await instance
			.put(requestUrl, body)
			.then((response) => {
				if (response.status === 200 || response.status === 204)
					return {
						error: false,
						success: true
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const postUserVehicle = async ({
	auth0Token,
	vehicleYearId,
	make,
	model,
	year,
	variant
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/userVehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = ``;

		const body = {
			vehicleYearId,
			make,
			model,
			year,
			variant
		};

		return await instance
			.post(requestUrl, body)
			.then((response) => {
				if (response.status === 200 || response.status === 204)
					return {
						error: false,
						success: true
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const deleteUserVehicle = async ({
	auth0Token,
	userVehicleId
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/userVehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = `/${userVehicleId}`;

		return await instance
			.delete(requestUrl)
			.then((response) => {
				if (response.status === 204)
					return {
						error: false,
						success: true
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};