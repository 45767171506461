import React, { useEffect, useState } from "react";
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Modal,
	Paper, Table, TableBody,
	TableCell,
	TableContainer, TableRow,
	Typography
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import { ApiCallStatus, PreferenceIds, ScreenSize } from "../../utils/constants";
import {
	fetchUserVehicles,
	removeUserVehicle
} from "../../main-component/PageFunctions/userVehicleFunctions";
import { upsertUserPreference, fetchUserPreference } from "../../main-component/PageFunctions/userFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import useSystemHook from "../../hooks/SystemHook";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CarIcon from '@mui/icons-material/DirectionsCar';
import CloseIcon from "@mui/icons-material/Close";

const desktopStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "35%", // Wider for desktop view
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
};

const tabletStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 3,
};

const mobileStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 2,
};

const SavedVehiclesModal = ({
	open,
	openChooseVehicleModal,
	setClose,
	currentUserVehicleId,
	userVehicles,
	upsertUserPreferenceAsync,
	removeUserVehicleAsync
}) => {
	const {screenSize} = useSystemHook();
	const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
	const navigate = useNavigate();

	const [removeUserVehicleCallStatus, setRemoveUserVehicleCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editCurrentUserVehicleUserPreferenceCallStatus, setEditCurrentUserVehicleUserPreferenceCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchCurrentUserVehicleUserPreferenceCallStatus, setFetchCurrentUserVehicleUserPreferenceCallStatus] = useState(ApiCallStatus.NotStarted);

	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedVehicleId, setSelectedVehicleId] = useState(null);
	const menuOpen = Boolean(anchorEl);

	const handleMenuClick = (event, id) => {
		setAnchorEl(event.currentTarget);
		setSelectedVehicleId(id);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		setSelectedVehicleId(null);
	};

	const fetchCurrentUserVehicleUserPreferenceAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchUserPreference({
			auth0Token,
			preferenceId: PreferenceIds.AccountCurrentUserVehicle,
			setValue: setSelectedVehicleId,
			setStatusInProgress: () => setFetchCurrentUserVehicleUserPreferenceCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchCurrentUserVehicleUserPreferenceCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchCurrentUserVehicleUserPreferenceCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchCurrentUserVehicleUserPreferenceCallStatus(ApiCallStatus.Error)
		})
	}

	const closeModal = () => {
		setClose();
	};

	const handleChangeVehicleClick = async () => {
		await upsertUserPreferenceAsync(PreferenceIds.AccountCurrentUserVehicle, selectedVehicleId);
	};

	const handleRemoveVehicleClick = async () => {
		await removeUserVehicleAsync(selectedVehicleId);
	};

	const handleAddVehicleClick = () => {
		if (isAuthenticated === true) {
			openChooseVehicleModal();
		}

	};

	const loadModal = async () => {
		await Promise.all([
			fetchCurrentUserVehicleUserPreferenceAsync()
		]);
	}

	const allLoadingStates = [
		removeUserVehicleCallStatus,
		editCurrentUserVehicleUserPreferenceCallStatus,
		fetchCurrentUserVehicleUserPreferenceCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || isLoading === true;

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Modal open={open} onClose={setClose}>
					<Box sx={desktopStyle}>
						<Grid container spacing={2}>
							<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
								<Typography variant="body2" fontWeight="bold">
									Your Garage
								</Typography>
								<IconButton onClick={closeModal}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</Grid>

							<>
								{isAuthenticated &&
									<>
										{userVehicles.length > 0 ? (
											<Grid item xs={12}>
												<TableContainer component={Paper}>
													<Table size="small" aria-label="user vehicles table">
														<TableBody>
															{userVehicles.map((vehicle) => {
																const id = vehicle.userVehicleId
																return (
																	<TableRow key={id}>
																		<TableCell>
																			{currentUserVehicleId === id && (
																				<CarIcon fontSize="small" sx={{ color: '#ec3323' }} />
																			)}
																		</TableCell>
																		<TableCell>
																			<Typography variant="caption" fontWeight="bold">
																				{`${vehicle.make} ${vehicle.model} ${vehicle.variant} ${vehicle.year}`}
																			</Typography>
																		</TableCell>
																		<TableCell align="right">

																			{currentUserVehicleId !== id &&
																				<>
																					<IconButton
																						aria-label="more"
																						size="small"
																						onClick={(event) => handleMenuClick(event, id)}
																					>
																						<MoreVertIcon fontSize="small" />
																					</IconButton>
																					<Menu
																						anchorEl={anchorEl}
																						open={menuOpen}
																						onClose={handleMenuClose}
																						PaperProps={{ elevation: 3 }}
																					>
																						<MenuItem onClick={handleChangeVehicleClick}>
																							<Typography variant="caption">
																								Set as active
																							</Typography>
																						</MenuItem>
																						<MenuItem onClick={handleRemoveVehicleClick}>
																							<Typography variant="caption">
																								Remove
																							</Typography>
																						</MenuItem>

																					</Menu>
																				</>

																			}
																		</TableCell>
																	</TableRow>
																)
															})}
														</TableBody>
													</Table>
												</TableContainer>
											</Grid>
										) : (
											<Grid item xs={12} textAlign="center">
												<Typography variant="caption">No vehicles saved</Typography>
											</Grid>
										)}
										<Grid item xs={12} textAlign="center">
											<Button variant="contained" color="error" size="small" onClick={handleAddVehicleClick}>
												{userVehicles.length === 0 ? "Add a vehicle" : "Add another vehicle"}
											</Button>
										</Grid>
									</>
								}
							</>

							{isAuthenticated === false && (
								<Grid item xs={12} textAlign="center">
									<Alert severity="warning">
										Please <a href="/login">log in</a> or <a href="/register">create an account</a> to
										access this feature.
									</Alert>
								</Grid>
							)}

						</Grid>

						{isLoading && (
							<Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
								<CircularProgress color="inherit" />
							</Backdrop>
						)}
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Tablet &&
				<Modal open={open} onClose={closeModal}>
					<Box sx={tabletStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Grid item>
								<Typography variant="body2" fontWeight="bold" textAlign="center">
									Your Garage
								</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={closeModal} size="small">
									<CloseIcon fontSize="small" />
								</IconButton>
							</Grid>
						</Grid>

						{!!userVehicles && userVehicles.length > 0 ? (
							<Grid container>
								<Grid item xs={12}>
									<Table>
										<tbody>
										{userVehicles.map((v, index) => {
											const name = `${v.make || ""} ${v.model || ""} ${v.variant || ""} ${v.year || ""}`;
											return (
												<tr key={index}>
													<td>
														<CarIcon fontSize="small" sx={{ color: '#ff9800' }} />
													</td>
													<td>
														<Typography variant="caption">{name}</Typography>
													</td>
													<td align="right">
														{currentUserVehicleId !== v.userVehicleId &&
															<>
																<Button onClick={handleChangeVehicleClick} size="small">
																	Set as active
																</Button>
																<Button onClick={handleRemoveVehicleClick} size="small">
																	Remove
																</Button>
															</>
														}


													</td>
												</tr>
											);
										})}
										</tbody>
									</Table>
								</Grid>
							</Grid>
						) : (
							<Grid container justifyContent="center">
								<Typography variant="caption">No vehicles saved</Typography>
							</Grid>
						)}

						{isAuthenticated && (
							<Grid container justifyContent="center">
								<Button
									onClick={openChooseVehicleModal}
									sx={{ mt: 2, mb: 1 }}
									variant="outlined"
									size="small"
								>
									{userVehicles.length === 0 ? "Add a vehicle" : "Add another vehicle"}
								</Button>
							</Grid>
						)}

						{isLoading && (
							<Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
								<CircularProgress color="inherit" />
							</Backdrop>
						)}
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Mobile &&
				<Modal open={open} onClose={closeModal}>
					<Box sx={mobileStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								Your Garage
							</Typography>
							<IconButton onClick={closeModal}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						{!!userVehicles && userVehicles.length > 0 ? (
							<Grid container sx={{ mt: 2 }}>
								<Table>
									<tbody>
									{userVehicles.map((v, index) => {
										const name = `${v.make || ""} ${v.model || ""} ${v.variant || ""} ${v.year || ""}`;
										return (
											<tr key={index}>
												<td>
													<CarIcon fontSize="small" sx={{ color: '#ff9800' }} />
												</td>
												<td>
													<Typography variant="caption">{name}</Typography>
												</td>
												<td align="right">
													{currentUserVehicleId !== v.userVehicleId &&
														<>
															<Button size="small" onClick={handleChangeVehicleClick}>
																Set as Active
															</Button>
															<Button size="small" onClick={handleRemoveVehicleClick}>
																Remove
															</Button>
														</>
													}

												</td>
											</tr>
										);
									})}
									</tbody>
								</Table>
							</Grid>
						) : (
							<Grid container justifyContent="center" sx={{ mt: 2 }}>
								<Typography variant="caption">No vehicles saved</Typography>
							</Grid>
						)}

						{isAuthenticated && (
							<Grid container justifyContent="center" sx={{ mt: 2 }}>
								<Button onClick={openChooseVehicleModal} variant="contained" color="error" size="small">
									{userVehicles.length === 0 ? "Add a vehicle" : "Add another vehicle"}
								</Button>
							</Grid>
						)}

						{isLoading && (
							<Backdrop open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
								<CircularProgress color="inherit" />
							</Backdrop>
						)}
					</Box>
				</Modal>
			}
		</>
	);
};

export default SavedVehiclesModal;
