import React, {useEffect, useState} from "react";
import DOMPurify from 'dompurify';
import "./style.css";
import { useMediaQuery } from "react-responsive";
import DesktopGridItem from "../DesktopGridItem";
import MobileGridItem from "../MobileGridItem";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { ProductAvailability } from "../../utils/constants";

const GridItem = ({
    title,
    price,
	invoicePrice,
    promo,
    promoInvoicePrice,
    customerCategory,
    vendor,
    image,
    productId,
    vendorImage,
    quality,
    fitment,
    availability,
    warranty,
    warrantyText,
    applicationPosition,
    applicationPositionImage,
    vendorDescription,
    showVehicleSpecificInformation,
    isKiosk,
    bodyHtml,
    sku,
    quantityInCart
}) => {
    const {handleAddToCart} = useArgusCart();
    const [updatedQuantity, setUpdatedQuantity] = useState(quantityInCart);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const fitmentImage = "https://argusweb.azureedge.net/argusblob01/web/fitment.jpg";
    const qualityImage = "https://argusweb.azureedge.net/argusblob01/web/quality.jpg";
    const availabilityImage = "https://argusweb.azureedge.net/argusblob01/web/availability.jpg";


    const applicationPositionParts = applicationPosition?.split(":");
    if (applicationPositionParts && applicationPositionParts.length > 1) {
        applicationPosition = applicationPositionParts[1].trim();
    }

    let fitmentText = fitment?.split("$");
    let fitmentTextSplit = [];
    if (fitmentText) {
        if (fitmentText.length > 1) {
            fitmentTextSplit = fitmentText[1].split("|");
        }
        else {
            fitmentTextSplit = fitmentText;
        }
    }

    const colourForAvailability = (() => {
        switch (availability) {
            case ProductAvailability.InStock: return "success";
            case ProductAvailability.LowStock: return "warning";
            case ProductAvailability.ThreeToFiveDays: return "info";
            case ProductAvailability.No_Stock: return "error";
            default: return "default";
        }
    })();

    const colourForQuality = (() => {
        switch (quality) {
            case "A+": return "primary";
            case "A": return "info";
            case "B": return "secondary";
            case "Other": return "default";
            default: return "default";
        }
    })();

    const handleAddToCartClick = () => {
        handleAddToCart({
            id: productId,
            name: title,
            vendor,
            image,
            sku
        });
    };

    const handleOpenModal = (content) => {
        setModalContent(content);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalContent(null);
    };

    const sanitizedData = () => ({
        __html: DOMPurify.sanitize(bodyHtml)
    });

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    useEffect(() => {
        setUpdatedQuantity(Number(quantityInCart));
    }, [quantityInCart]);

    return (
        <>
            {isMobile === true &&
                <MobileGridItem price={price}
                                invoicePrice={invoicePrice}
                                promo={promo}
                                promoInvoicePrice={promoInvoicePrice}
                                customerCategory={customerCategory}
                                vendorImage={vendorImage}
                                warranty={warranty}
                                warrantyText={warrantyText}
                                applicationPositionImage={applicationPositionImage}
                                vendorDescription={vendorDescription}
                                showVehicleSpecificInformation={showVehicleSpecificInformation}
                                openModal={openModal}
                                modalContent={modalContent}
                                fitmentImage={fitmentImage}
                                qualityImage={qualityImage}
                                availabilityImage={availabilityImage}
                                colourForAvailability={colourForAvailability}
                                colourForQuality={colourForQuality}
                                handleAddToCartClick={handleAddToCartClick}
                                handleOpenModal={handleOpenModal}
                                handleCloseModal={handleCloseModal}
                                sanitizedData={sanitizedData}
                                image={image}
                                title={title}
                                vendor={vendor}
                                fitment={fitment}
                                sku={sku}
                                availability={availability}
                                quality={quality}
                />
            }
            {isMobile === false &&
                <DesktopGridItem price={price}
                                 invoicePrice={invoicePrice}
                                 promo={promo}
                                 promoInvoicePrice={promoInvoicePrice}
                                 customerCategory={customerCategory}
                                 vendorImage={vendorImage}
                                 warranty={warranty}
                                 warrantyText={warrantyText}
                                 applicationPositionImage={applicationPositionImage}
                                 vendorDescription={vendorDescription}
                                 showVehicleSpecificInformation={showVehicleSpecificInformation}
                                 openModal={openModal}
                                 modalContent={modalContent}
                                 fitmentImage={fitmentImage}
                                 qualityImage={qualityImage}
                                 availabilityImage={availabilityImage}
                                 colourForAvailability={colourForAvailability}
                                 colourForQuality={colourForQuality}
                                 handleAddToCartClick={handleAddToCartClick}
                                 handleOpenModal={handleOpenModal}
                                 handleCloseModal={handleCloseModal}
                                 sanitizedData={sanitizedData}
                                 image={image}
                                 title={title}
                                 vendor={vendor}
                                 fitment={fitment}
                                 sku={sku}
                                 availability={availability}
                                 quality={quality}
                />
            }
        </>
    );
};

export default GridItem;

