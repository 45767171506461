import React from 'react';
import { SouthAfricanRand } from "../../utils/helpers";
import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";

const DesktopCartSummary = ({
	vat,
	settlementAmount,
	cartNettTotal,
	total,
	vatAmount,
	logisticsFee,
	deliveryFeeToDisplay,
	buttonText,
	buttonDisabled,
	onButtonClick
}) => {
	return (
		<>
			<Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
				<Box sx={{ mb: 2 }}>
					<Typography variant="body2" fontWeight="bold" align="center">
						Order Summary
					</Typography>
				</Box>
				<Divider sx={{ mb: 2 }} />

				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Typography variant="caption" component="p">Nett Total</Typography>
					</Grid>
					<Grid item xs={6} textAlign="right">
						<Typography variant="caption" fontWeight="bold" component="p">
							{SouthAfricanRand.format(cartNettTotal)}
						</Typography>
					</Grid>

					{!!settlementAmount && settlementAmount > 0 && (
						<>
							<Grid item xs={6}>
								<Typography variant="caption" component="p">Settlement Discount</Typography>
							</Grid>
							<Grid item xs={6} textAlign="right">
								<Typography variant="caption" color="error" component="p">
									-{SouthAfricanRand.format(settlementAmount)}
								</Typography>
							</Grid>
						</>
					)}

					<Grid item xs={6}>
						<Typography variant="caption" component="p">VAT ({vat}%)</Typography>
					</Grid>
					<Grid item xs={6} textAlign="right">
						<Typography variant="caption" fontWeight="bold" component="p">
							{SouthAfricanRand.format(vatAmount)}
						</Typography>
					</Grid>

					{!!logisticsFee && (
						<>
							<Grid item xs={6}>
								<Typography variant="caption" component="p">Delivery Fee</Typography>
							</Grid>
							<Grid item xs={6} textAlign="right">
								<Typography variant="caption" fontWeight="bold" component="p">
									{deliveryFeeToDisplay()}
								</Typography>
							</Grid>
						</>
					)}

					<Divider sx={{ width: '100%', my: 2 }} />

					<Grid item xs={6}>
						<Typography variant="body2" fontWeight="bold">
							Total
						</Typography>
					</Grid>
					<Grid item xs={6} textAlign="right">
						<Typography variant="body2" fontWeight="bold">
							{SouthAfricanRand.format(total)}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
			<Button
				variant="contained"
				color="error"
				size="small"
				fullWidth
				disabled={buttonDisabled}
				onClick={onButtonClick}
				endIcon={<ArrowForwardIcon fontSize="small" />}
				sx={{ marginTop: '0.75rem' }}
			>
				{buttonText}
			</Button>
		</>
	)
}

export default DesktopCartSummary;