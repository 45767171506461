import React, { Fragment } from "react";
import { Avatar, Backdrop, Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { capitalize, find } from "lodash";
import GridItem from "../GridItem";
import CategorySlider from "../CategorySlider";


const MobileFindProductsByCategory = ({
	categoriesAndTypes,
	activeCategory,
	handleActiveCategoryChange,
	products,
	pricingData,
	stockInfo,
	productCategoryLink,
	handleAddToCart,
	customerCategory,
	loading
}) => {
	return (
		<Fragment>
			<Box sx={{ mt: 2, p: 2 }}>
				<Typography variant="body2" gutterBottom sx={{ textAlign: 'center' }}>
					Find Part by Category
				</Typography>
			</Box>

			<CategorySlider categoriesAndTypes={categoriesAndTypes}
			                activeCategory={activeCategory}
			                handleActiveCategoryChange={handleActiveCategoryChange} />

			<Box sx={{ mt: 2, p: 2 }}>
				<Grid sx={{ mb: 5}} container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Typography variant="h6"
						            sx={{ borderLeft: "3px solid red"}}>
							&nbsp;{activeCategory}
						</Typography>
					</Grid>
					<Grid item>
						<Button variant="contained" size="small" color="error" href={productCategoryLink}>
							View all
						</Button>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					{!!products && !!pricingData && products.slice(0, 4).map((product, itemNumber) => {
						const priceObject = find(pricingData, p => p.sku === product.sku);
						const stockInfoObject = find(stockInfo, p => p.sku === product.sku);
						const promoPrice = priceObject?.promo ?? 0;
						const promoInvoicePrice = priceObject?.promoInvoicePrice ?? 0;

						let itemPrice = priceObject?.price ?? 0;
						let invoicePrice = priceObject?.invoicePrice ?? 0;
						
						return (
							<Grid item xs={6} key={itemNumber}>
								<GridItem
									key={itemNumber}
									title={product.name}
									price={itemPrice}
									invoicePrice={invoicePrice}
									promo={promoPrice}
									promoInvoicePrice={promoInvoicePrice}
									customerCategory={customerCategory}
									vendor={product.vendor}
									image={product.image}
									productId={product.productId}
									vendorImage={product.vendorImage}
									handle={product.handle}
									vendorDescription={product.vendorDescription}
									quality={product.quality}
									fitment={product.fitment}
									availability={stockInfoObject?.quantityOnHand ?? product.availability}
									warranty={product.warranty}
									warrantyText={product.warrantyText}
									applicationPosition={product.applicationPosition}
									applicationPositionImage={product.applicationPositionImage}
									// showVehicleSpecificInformation={showVehicleSpecificInformation}
									// isKiosk={isKiosk}
									bodyHtml={product.html}
									sku={product.sku}
									addToCart={() => handleAddToCart(product)}
								/>
							</Grid>
						)
					})}
				</Grid>

				<Backdrop sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				          open={loading}>
					<CircularProgress color="inherit"/>
				</Backdrop>

			</Box>
		</Fragment>
	)
}

export default MobileFindProductsByCategory;