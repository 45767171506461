import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccountType, ScreenSize } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextField from "@mui/material/TextField";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PageHeader from "../PageHeader";
import Autocomplete from "@mui/material/Autocomplete";

const CheckoutOrderInformation = ({
	salesPeople,
	accountType,
	phoneNumber,
	firstName,
	lastName,
	email,
	tecfinityAccountName,
	tecfinityAccountNumber,
	salesPersonCode,
	checkoutData,
	onSubmit
}) => {
	const { screenSize } = useSystemHook();
	const [salesPersonOptions, setSalesPersonOptions] = useState([]);
	const schema = yup
		.object({
			salesPerson: yup.string(),
			email: yup.string().email().required("Email is required"),
			phone: yup.string().required("Mobile number is required to register")
		})
		.required();
	const {
		control,
		handleSubmit,
		trigger,
		getValues,
		setValue,
		formState: {errors}
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			salesPerson: "No Sales Person",
			email: "",
			phone: ""
		}
	});

	useEffect(() => {
		if (!!salesPeople) {
			const options = salesPeople.map((x) => x.name).sort();
			setSalesPersonOptions(options);
		}
	}, [salesPeople]);

	useEffect(() => {
		if (!!salesPersonCode) {
			if (salesPeople.length > 0) {
				const salesPersonChosen = salesPeople.find(x => String(x.code) === String(salesPersonCode));
				setValue("salesPerson", salesPersonChosen?.name ?? "No Sales Person");
			}
		}
	}, [salesPersonCode]);

	useEffect(() => {
		if (!!phoneNumber) {
			setValue("phone", phoneNumber);
		}
	}, [phoneNumber]);

	useEffect(() => {
		if (!!firstName) {
			setValue("firstName", firstName);
		}
	}, [firstName]);

	useEffect(() => {
		if (!!lastName) {
			setValue("lastName", lastName);
		}
	}, [lastName]);

	useEffect(() => {
		if (!!email) {
			setValue("email", email);
		}
	}, [email]);

	const onSaveOrderInformationClick = async () => {
		const result = await trigger(["phone", "email"]);

		if (!!result && !!checkoutData) {
			const checkoutDataClone = cloneDeep(checkoutData);

			const salesPersonName = getValues("salesPerson");
			const salesPersonChosen = salesPeople.find(x => x.name.trim() === salesPersonName);
			checkoutDataClone.salesPersonCode = salesPersonChosen?.code ?? "155";
			if (!!getValues("phone")) {
				checkoutDataClone.phoneNumber = getValues("phone");
			}

			if (!!getValues("email")) {
				checkoutDataClone.email = getValues("email");
			}


			await onSubmit({
				checkoutData: checkoutDataClone
			});
		}
	};

	return (
		<>
			<PageHeader title="Checkout" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: 2 }}>
					{!!checkoutData && (
						<Grid container spacing={2}>
							<Grid item xs={12} md={9}>
								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1rem' }}>
									<form onSubmit={handleSubmit(onSubmit)}>
										{accountType === AccountType.Commercial &&
											!!tecfinityAccountName &&
											!!tecfinityAccountNumber && (
												<Box sx={{ marginBottom: '1.5rem' }}>
													<Typography
														variant="body2"
														gutterBottom
														sx={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}
													>
														<InfoIcon fontSize="small" />
														Account
													</Typography>
													<TextField
														label="Account"
														variant="outlined"
														fullWidth
														size="small"
														value={`${tecfinityAccountName} - ${tecfinityAccountNumber}`}
														disabled
														InputProps={{
															startAdornment: (
																<AccountCircleIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
															),
														}}
														sx={{ marginBottom: '1rem' }}
													/>
													<Controller
														name="salesPerson"
														control={control}
														render={({ field }) => (
															<Autocomplete
																{...field}
																options={salesPersonOptions}
																getOptionLabel={(option) => option}
																onChange={(event, value) => field.onChange(value)}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Sales Person"
																		variant="outlined"
																		fullWidth
																		size="small"
																		error={!!errors?.salesPerson}
																		helperText={errors?.salesPerson?.message}
																		InputProps={{
																			...params.InputProps,
																			startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
																			endAdornment: params.InputProps.endAdornment, // Preserve clear button
																		}}
																	/>
																)}
																clearOnEscape
																isOptionEqualToValue={(option, value) => option === value}
																sx={{
																	'& .MuiAutocomplete-option': {
																		fontSize: '0.875rem',
																	},
																}}
															/>
														)}
													/>
												</Box>
											)}

										<Typography
											variant="body2"
											gutterBottom
											sx={{ display: 'flex', alignItems: 'center', mb: 4}}
										>
											<InfoIcon sx={{ marginRight: '0.5rem' }} />
											Contact Information
										</Typography>
										<Controller
											name="email"
											control={control}
											render={({ field }) => (
												<TextField
													label="Email"
													variant="outlined"
													fullWidth
													{...field}
													size="small"
													error={!!errors?.email}
													helperText={errors?.email?.message}
													InputProps={{
														startAdornment: (
															<EmailIcon sx={{ marginRight: '0.5rem' }} />
														),
													}}
													disabled={!!accountType}
													sx={{ marginBottom: '1rem' }}
												/>
											)}
										/>
										<Controller
											name="phone"
											control={control}
											render={({ field }) => (
												<TextField
													label="Phone"
													variant="outlined"
													fullWidth
													{...field}
													size="small"
													error={!!errors?.phone}
													helperText={errors?.phone?.message}
													InputProps={{
														startAdornment: (
															<PhoneIcon sx={{ marginRight: '0.5rem' }} />
														),
													}}
													disabled={!!accountType}
												/>
											)}
										/>
									</form>
								</Paper>
							</Grid>
							<Grid item xs={12} md={3}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									total={checkoutData.total}
									sx={{ marginBottom: '2rem' }}
									buttonText="Continue"
									onButtonClick={onSaveOrderInformationClick}
								/>
							</Grid>
						</Grid>
					)}
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ backgroundColor: '#f9f9f9', minHeight: '50vh', padding: 1 }}>
					{!!checkoutData && (
						<>
							<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1.5rem' }}>
								<form onSubmit={handleSubmit(onSubmit)}>
									{accountType === AccountType.Commercial && (
										<Box sx={{ marginBottom: '1.5rem' }}>
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
												<InfoIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
												Account
											</Typography>
											<TextField
												label="Account"
												variant="outlined"
												fullWidth
												size="small"
												value={`${tecfinityAccountName} - ${tecfinityAccountNumber}`}
												disabled
												InputProps={{
													startAdornment: (
														<AccountCircleIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
													),
												}}
												sx={{ marginBottom: '1rem' }}
											/>
											<Controller
												name="salesPerson"
												control={control}
												render={({ field }) => (
													<Autocomplete
														{...field}
														options={salesPersonOptions}
														getOptionLabel={(option) => option}
														onChange={(event, value) => field.onChange(value)}
														renderInput={(params) => (
															<TextField
																{...params}
																label="Sales Person"
																variant="outlined"
																fullWidth
																size="small"
																error={!!errors?.salesPerson}
																helperText={errors?.salesPerson?.message}
																InputProps={{
																	...params.InputProps,
																	startAdornment: params.InputProps.startAdornment, // Preserve adornment (like icons)
																	endAdornment: params.InputProps.endAdornment, // Preserve clear button
																}}
															/>
														)}
														clearOnEscape
														isOptionEqualToValue={(option, value) => option === value}
														sx={{
															'& .MuiAutocomplete-option': {
																fontSize: '0.875rem',
															},
														}}
													/>
												)}
											/>
										</Box>
									)}

									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
										<InfoIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
										Contact Information
									</Typography>
									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<TextField
												label="Email"
												variant="outlined"
												fullWidth
												{...field}
												size="small"
												error={!!errors?.email}
												helperText={errors?.email?.message}
												InputProps={{
													startAdornment: (
														<EmailIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
													),
												}}
												disabled={!!accountType}
												sx={{ marginBottom: '1rem' }}
											/>
										)}
									/>
									<Controller
										name="phone"
										control={control}
										render={({ field }) => (
											<TextField
												label="Phone"
												variant="outlined"
												fullWidth
												size="small"
												{...field}
												error={!!errors?.phone}
												helperText={errors?.phone?.message}
												InputProps={{
													startAdornment: (
														<PhoneIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
													),
												}}
												disabled={!!accountType}
											/>
										)}
									/>
								</form>
							</Paper>

							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								buttonText="Continue"
								onButtonClick={onSaveOrderInformationClick}
							/>
						</>
					)}
				</Box>
			}
		</>
	);
};

export default CheckoutOrderInformation;
