import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import SiteHeader from "../../components/SiteHeader";
import SiteFooter from "../../components/SiteFooter";
import ArgusRoleModal from "../../components/ArgusRoleModal";
import { Backdrop, CircularProgress, Container, Typography } from "@mui/material";
import { ApiCallStatus, PreferenceIds } from "../../utils/constants";
import { fetchGenericPageContentData } from "../PageFunctions/pageContentFunctions";
import SiteTopbar from "../../components/SiteTopbar";
import { fetchCategoriesAndTypes } from "../PageFunctions/productFunctions";
import AddedToCart from "../../components/AddedToCart";
import { upsertUserPreference } from "../PageFunctions/userFunctions";
import { createUserVehicle, removeUserVehicle } from "../PageFunctions/userVehicleFunctions";
import Announcements from "../../components/Announcements";
import { useNavigate } from "react-router-dom";


const PageWrapper = (props) => {
	const navigate = useNavigate();
	const {getAccessTokenSilently, isLoading, isAuthenticated} = useAuth0();
	const [argusRoleModalOpen, setArgusRoleModalOpen] = useState(false);
	const [auth0Token, setAuth0Token] = useState(null);
	const [tokenFetched, setTokenFetched] = useState(false);
	const [genericPageContent, setGenericPageContent] = useState(null);
	const [genericDataLoaded, setGenericDataLoaded] = useState(false);
	const [categoriesAndTypes, setCategoriesAndTypes] = useState([]);

	const [fetchCategoriesAndTypesCallStatus, setFetchCategoriesAndTypesCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchGenericPageContentCallStatus, setFetchGenericPageContentCallStatus] = useState(ApiCallStatus.NotStarted);
	const [upsertUserPreferenceCallStatus, setUpsertUserPreferenceCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createUserVehicleCallStatus, setCreateUserVehicleCallStatus] = useState(ApiCallStatus.NotStarted);
	const [removeUserVehicleCallStatus, setRemoveUserVehicleCallStatus] = useState(ApiCallStatus.NotStarted);

	const scrollTop = () => {
	};

	const fetchCategoriesAndTypesAsync = async () => {
		await fetchCategoriesAndTypes({
			setCategoriesAndTypesData: setCategoriesAndTypes,
			setStatusInProgress: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchCategoriesAndTypesCallStatus(ApiCallStatus.Error)
		});
	};

	const upsertUserPreferenceAsync = async (preferenceId, value) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await upsertUserPreference({
			auth0Token,
			preferenceId,
			value,
			setStatusInProgress: () => setUpsertUserPreferenceCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setUpsertUserPreferenceCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setUpsertUserPreferenceCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpsertUserPreferenceCallStatus(ApiCallStatus.Error)
		})
	}

	const createUserVehicleAsync = async (vehicleToSave) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createUserVehicle({
			auth0Token,
			vehicleYearId: vehicleToSave.vehicleYearId,
			make: vehicleToSave.make,
			model: vehicleToSave.model,
			year: vehicleToSave.year,
			variant: vehicleToSave.variant,
			setStatusInProgress: () => setCreateUserVehicleCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreateUserVehicleCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreateUserVehicleCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateUserVehicleCallStatus(ApiCallStatus.Error),
		});
	};

	const removeUserVehicleAsync = async (userVehicleId) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await removeUserVehicle({
			auth0Token,
			userVehicleId,
			setStatusInProgress: () => setRemoveUserVehicleCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setRemoveUserVehicleCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setRemoveUserVehicleCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setRemoveUserVehicleCallStatus(ApiCallStatus.Error)
		})
	}

	const callFetchGenericPageContent = () => {
		if (isAuthenticated === true && !!auth0Token) {
			setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress);
			fetchGenericPageContentData({
				auth0Token,
				setPageContent: setGenericPageContent,
				setStatusInProgress: () => setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Error)
			});
		} else if (isAuthenticated === false) {
			setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress);
			fetchGenericPageContentData({
				setPageContent: setGenericPageContent,
				setStatusInProgress: () => setFetchGenericPageContentCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchGenericPageContentCallStatus(ApiCallStatus.Error)
			});
		}
	}

	const refreshPage = () => {
		navigate(0);
	}

	useEffect(() => {
		document.querySelector("body").scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, []);

	useEffect(() => {
		const fetchToken = async () => {
			const token = await getAccessTokenSilently();
			setTokenFetched(true);
			setAuth0Token(token);
		}

		if (isLoading === false) {
			if (isAuthenticated === true) {
				fetchToken();
			} else {
				setTokenFetched(true);
			}

		}
	}, [getAccessTokenSilently, isLoading, isAuthenticated]);

	useEffect(() => {
		if (tokenFetched === true && isLoading === false) {
			callFetchGenericPageContent();
		}
	}, [auth0Token, tokenFetched, isLoading, isAuthenticated]);

	useEffect(() => {
		if (fetchGenericPageContentCallStatus === ApiCallStatus.Succeeded) {
			setGenericDataLoaded(true);
		}
	}, [fetchGenericPageContentCallStatus]);

	useEffect(() => {
		if (upsertUserPreferenceCallStatus === ApiCallStatus.Succeeded ||
			createUserVehicleCallStatus === ApiCallStatus.Succeeded ||
			removeUserVehicleCallStatus === ApiCallStatus.Succeeded) {
			setGenericDataLoaded(false);
			callFetchGenericPageContent();
		}
	}, [upsertUserPreferenceCallStatus, createUserVehicleCallStatus, removeUserVehicleCallStatus]);

	useEffect(() => {
		if (genericDataLoaded === true && categoriesAndTypes.length === 0) {
			fetchCategoriesAndTypesAsync();
		}
	}, [genericDataLoaded, categoriesAndTypes]);

	useEffect(() => {
		if (genericDataLoaded === true && !!genericPageContent?.userId && isAuthenticated === true) {
			setArgusRoleModalOpen(!genericPageContent.accountType);
		}
	}, [genericPageContent, genericDataLoaded]);


	const allLoadingStates = [
		fetchGenericPageContentCallStatus,
		fetchCategoriesAndTypesCallStatus,
		upsertUserPreferenceCallStatus,
		createUserVehicleCallStatus,
		removeUserVehicleCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	let additionalProps;
	let childrenWithProps;

	if (genericDataLoaded === true && !!genericPageContent && !!categoriesAndTypes.length > 0) {
		additionalProps = {
			genericDataLoaded,
			genericPageContent,
			categoriesAndTypes
		};

		childrenWithProps = React.Children.map(props.children, (child) => {
			return React.cloneElement(child, { ...props, ...additionalProps });
		});
	}

	return (
		<Fragment>
			{!!pageIsLoading &&
				<Typography variant="body2">Please wait...</Typography>
			}
			{!pageIsLoading && !!additionalProps && childrenWithProps &&
				<>
					<SiteTopbar />
					<SiteHeader genericPageContent={genericPageContent}
					            genericDataLoaded={genericDataLoaded}
					            categoriesAndTypes={categoriesAndTypes}
					            upsertUserPreferenceAsync={upsertUserPreferenceAsync}
					            createUserVehicleAsync={createUserVehicleAsync}
					            removeUserVehicleAsync={removeUserVehicleAsync}
					/>
					{!!argusRoleModalOpen && isAuthenticated === true && (
						<ArgusRoleModal
							open={!!argusRoleModalOpen}
							setClose={() => setArgusRoleModalOpen(false)}
							refreshPage={refreshPage}
						/>
					)}
					<Announcements genericDataLoaded={genericDataLoaded} />
					<AddedToCart />
					{childrenWithProps}

					<SiteFooter />
				</>
			}
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Fragment>
	)
}
export default PageWrapper;