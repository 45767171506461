import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import PaymentAccountCredit from "../../components/PaymentAccountCredit";
import {
	ApiCallStatus,
	PaymentMethod, PaymentType
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { removeAuthCookies } from "../../api/authentication";
import {
	createAccountCreditPaymentData,
	fetchPaymentOrderData
} from "../PageFunctions/paymentFunctions";
import { fetchLiveTradeAccountData } from "../PageFunctions/tecfinityFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const PaymentAccountCreditPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [paymentOrderInfo, setPaymentOrderInfo] = useState(null);
	const [fullTradeAccount, setFullTradeAccount] = useState(null);

	const [fullTradeAccountCallStatus, setFullTradeAccountCallStatus] = useState(ApiCallStatus.NotStarted);
	const [paymentOrderInfoCallStatus, setPaymentOrderInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createAccountCreditPaymentCallStatus, setCreateAccountCreditPaymentCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoLoginPage = () => {
		return navigate("/login", {state: {from: location}});
	};

	const gotoPaymentPage = () => paymentOrderInfo?.orderId ? navigate(`/payment`) : navigate(-1);
	const gotoOrderConfirmationPage = (orderId) => navigate(`/order-confirmation/${orderId}`);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchPaymentOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchPaymentOrderData({
			auth0Token,
			paymentType: PaymentType.AccountCredit,
			setPaymentOrderInfo,
			setStatusInProgress: () => setPaymentOrderInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setPaymentOrderInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchFullTradeAccountDataAsync = async () => {
		if (!!genericPageContent?.tradeAccountId) {
			let auth0Token;
			if (isAuthenticated === true) {
				auth0Token = await getAccessTokenSilently();
			}
			await fetchLiveTradeAccountData({
				auth0Token,
				setFullTradeAccount,
				setStatusInProgress: () => setFullTradeAccountCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFullTradeAccountCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFullTradeAccountCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFullTradeAccountCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const createAccountCreditPaymentDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createAccountCreditPaymentData({
			auth0Token,
			setStatusInProgress: () => setCreateAccountCreditPaymentCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateAccountCreditPaymentCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedPageLoad = async () => {

	};

	const unAuthorizedPageLoad = async () => {
		await fetchPaymentOrderDataAsync();
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				gotoLoginPage();
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (createAccountCreditPaymentCallStatus === ApiCallStatus.Succeeded && !!paymentOrderInfo.orderId) {
			gotoOrderConfirmationPage(paymentOrderInfo.orderId);
		}
	}, [createAccountCreditPaymentCallStatus, paymentOrderInfo]);

	useEffect(() => {
		if (genericDataLoaded === true && !!genericPageContent?.tradeAccountId) {
			fetchFullTradeAccountDataAsync();
		}
	}, [genericPageContent?.tradeAccountId, genericDataLoaded]);

	const allLoadingStates = [
		paymentOrderInfoCallStatus,
		createAccountCreditPaymentCallStatus,
		fullTradeAccountCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<PaymentAccountCredit
				paymentOrderInfo={paymentOrderInfo}
				fullTradeAccount={fullTradeAccount}
				gotoPaymentPage={gotoPaymentPage}
				onSubmit={createAccountCreditPaymentDataAsync}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};

export default PaymentAccountCreditPage;
