import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_WEB_API_URL;

export const upsertUserAnnouncement = async (
	announcementId,
	acknowledged,
	auth0Token
) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const body = {
			acknowledged
		};

		const requestUrl = `${announcementId}`;

		return await instance
			.put(requestUrl, body)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (error) {
		console.error(error);
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getUserAnnouncements = async (auth0Token) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/announcement/`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] =
				`Bearer ${auth0Token}`;
		}

		const requestUrl = ``;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
