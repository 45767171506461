import React, { useEffect, useState } from "react";
import { LogisticsMethod, PaymentMethod, PaymentType, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";
import {
	Avatar, Box,
	Button, Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import { SouthAfricanRand } from "../../utils/helpers";
import PageHeader from "../PageHeader";

const Payment = ({
	accountCreditPaymentOrderInfo,
	fullTradeAccount,
	gotoPaymentPayfastPage,
	gotoPaymentAccountCredit,
	gotoPaymentEftPage,
	gotoPaymentCreditCardOnCollectionPage,
	paymentMethods
}) => {
	const {screenSize} = useSystemHook();
	const [recommendedPaymentMethod, setRecommendedPaymentMethod] = useState(
		PaymentMethod.EFT
	);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!fullTradeAccount && !!accountCreditPaymentOrderInfo) {
			if (fullTradeAccount.availableCredit >= accountCreditPaymentOrderInfo.amount) {
				setRecommendedPaymentMethod(PaymentMethod.AccountCredit);
			}
		}
	}, [fullTradeAccount, accountCreditPaymentOrderInfo]);

	return (
		<>
			<PageHeader title="Payment" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Paper elevation={3} sx={{ padding: '2rem', marginBottom: '1.5rem' }}>
								<Typography variant="body2" gutterBottom>
									Choose your payment method
								</Typography>
								<List>
									{!!paymentMethods && paymentMethods.includes(PaymentType.AccountCredit) && !!fullTradeAccount &&
										<ListItem>
											<Avatar
												variant="square"
												alt="Account Credit"
												src="https://argusweb.azureedge.net/argus-web/logo.svg"
												sx={{ width: 150, height: 50 }}
											/>
											<ListItemText
												primary="Pay with Account Credit"
												secondary={recommendedPaymentMethod === PaymentMethod.AccountCredit && (
													<Typography variant="caption" color="error">Recommended</Typography>
												)}
											/>
											<ListItemSecondaryAction>
												<Button
													variant="contained"
													color="error"
													size="small"
													onClick={gotoPaymentAccountCredit}
													sx={{ marginLeft: '1rem' }}
												>
													Pay Now
												</Button>
											</ListItemSecondaryAction>
										</ListItem>
									}
									{!!paymentMethods && paymentMethods.includes(PaymentType.EFT) &&
										<ListItem>
											<Avatar
												variant="square"
												alt="EFT"
												src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
												sx={{ width: 50, height: 50 }}
											/>
											<ListItemText
												primary="Pay via EFT"
												secondary={recommendedPaymentMethod === PaymentMethod.EFT && (
													<Typography variant="caption" color="error">Recommended</Typography>
												)}
											/>
											<ListItemSecondaryAction>
												<Button
													variant="contained"
													color="error"
													size="small"
													onClick={gotoPaymentEftPage}
													sx={{ marginLeft: '1rem' }}
												>
													Pay Now
												</Button>
											</ListItemSecondaryAction>
										</ListItem>
									}

									{!!paymentMethods && paymentMethods.includes(PaymentType.CreditCardOnCollection) &&
										<ListItem>
											<Avatar
												variant="square"
												alt="Credit Card"
												src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
												sx={{ width: 50, height: 50 }}
											/>
											<ListItemText
												primary="Pay via Credit Card on Collection"
												secondary={recommendedPaymentMethod === PaymentMethod.CardOnCollection && (
													<Typography variant="caption" color="error">Recommended</Typography>
												)}
											/>
											<ListItemSecondaryAction>
												<Button
													variant="contained"
													color="error"
													size="small"
													onClick={gotoPaymentCreditCardOnCollectionPage}
													sx={{ marginLeft: '1rem' }}
												>
													Pay Now
												</Button>
											</ListItemSecondaryAction>
										</ListItem>
									}
								</List>
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '50vh', backgroundColor: '#f7f7f7', padding: '16px' }}>
					<Typography variant="body2" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
						Choose your payment method
					</Typography>

					<Paper elevation={3} sx={{ padding: 2, mb: 2 }}>
						<List>
							{!!paymentMethods && paymentMethods.includes(PaymentType.AccountCredit) && !!fullTradeAccount &&
								<ListItem sx={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
									<Avatar
										variant="square"
										alt="Account Credit"
										src="https://argusweb.azureedge.net/argus-web/logo.svg"
										sx={{ width: 80, height: 40, mr: 2 }}
									/>
									<Box sx={{ flex: 1 }}>
										<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
											Account Credit
										</Typography>
										{recommendedPaymentMethod === PaymentMethod.AccountCredit && (
											<Typography variant="caption" color="error">
												Recommended
											</Typography>
										)}
									</Box>
									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={gotoPaymentAccountCredit}
									>
										Pay
									</Button>
								</ListItem>
							}
							{!!paymentMethods && paymentMethods.includes(PaymentType.EFT) &&
								<ListItem sx={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
									<Avatar
										variant="square"
										alt="EFT"
										src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
										sx={{ width: 40, height: 40, mr: 2 }}
									/>
									<Box sx={{ flex: 1 }}>
										<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
											EFT
										</Typography>
										{recommendedPaymentMethod === PaymentMethod.EFT && (
											<Typography variant="caption" color="error">
												Recommended
											</Typography>
										)}
									</Box>
									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={gotoPaymentEftPage}
									>
										Pay
									</Button>
								</ListItem>
							}
							{!!paymentMethods && paymentMethods.includes(PaymentType.CreditCardOnCollection) &&
								<ListItem sx={{ display: 'flex', alignItems: 'center', padding: '12px' }}>
									<Avatar
										variant="square"
										alt="Credit Card"
										src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
										sx={{ width: 60, height: 60, mr: 2 }}
									/>
									<Box sx={{ flex: 1 }}>
										<Typography variant="body2" sx={{ fontWeight: 'bold' }}>
											Credit Card on Collection
										</Typography>
										{recommendedPaymentMethod === PaymentMethod.CardOnCollection && (
											<Typography variant="caption" color="error">
												Recommended
											</Typography>
										)}
									</Box>
									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={gotoPaymentCreditCardOnCollectionPage}
									>
										Pay
									</Button>
								</ListItem>
							}
						</List>
					</Paper>
				</Box>
			}
		</>
	);
};

export default Payment;
