import { getUserAnnouncements, upsertUserAnnouncement } from "../../api/announcementController";

export const editAnnouncementDataMarkAsSeen = async ({
	auth0Token,
	announcementId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await upsertUserAnnouncement(
			announcementId,
			null,
			auth0Token
		);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const editAnnouncementDataMarkAsAcknowledged = async ({
	auth0Token,
	announcementId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await upsertUserAnnouncement(
			announcementId,
			true,
			auth0Token
		);

		if (!!response?.success) {
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchUserAnnouncements = async ({
	auth0Token,
	setAnnouncements,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getUserAnnouncements(auth0Token);

		if (!!response?.success) {
			setAnnouncements(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
}