import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "../TabPanel/TabPanel";
import MakeModelYear from "../MakeModelYear";
import PartNumberSearch from "../PartNumberSearch";
import "./style.css";
import { useMediaQuery } from "react-responsive";

const SearchForParts = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
	const [tabValue, setTabValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<section className="search-for-parts-section">
			<main className="search-for-parts-main">
				<Typography variant={isMobile ? "h6" : "h5"} sx={{ mb: 3, textAlign: 'center', color: '#ffffff' }}>
					We have everything your car needs
				</Typography>
				<Box sx={{backgroundColor: "#262626", color: "#FFFFFF"}}>
					<Tabs
						value={tabValue}
						onChange={handleTabChange}
						centered
						variant="fullWidth"
					>
						<Tab
							sx={{textTransform: "none"}}
							label="Make Model Year"
						/>
						<Tab
							sx={{textTransform: "none"}}
							label="Part Number"
						/>
					</Tabs>
				</Box>
				<TabPanel value={tabValue} index={0}>
					<MakeModelYear/>
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<PartNumberSearch/>
				</TabPanel>
			</main>
		</section>
	);
};

export default SearchForParts;