import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { AnnouncementType, ApiCallStatus, DefaultAnnouncementObject } from "../../utils/constants";
import {
	editAnnouncementDataMarkAsAcknowledged,
	editAnnouncementDataMarkAsSeen,
	fetchUserAnnouncements
} from "../../main-component/PageFunctions/announcementFunctions";
import { getItem, setItem } from "../../utils/storageHelper";
import { storageKeys } from "../../api/storageKeys";
import AnnouncementModal from "../AnnouncementModal";
import { cloneDeep, filter, uniq, uniqBy } from "lodash";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import moment from "moment";

const Announcements = ({
	genericDataLoaded
}) => {
	const navigate = useNavigate();
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [announcements, setAnnouncements] = useState(null);
	const [announcementsToShow, setAnnouncementsToShow] = useState([]);
	const [announcement, setAnnouncement] = useState(null);
	const [announcementOpen, setAnnouncementOpen] = useState(false);

	const [fetchUserAnnouncementsCallStatus, setFetchUserAnnouncementsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editAnnouncementDataMarkAsSeenCallStatus, setEditAnnouncementDataMarkAsSeenCallStatus] = useState(ApiCallStatus.NotStarted);
	const [editAnnouncementDataMarkAsAcknowledgedCallStatus, setEditAnnouncementDataMarkAsAcknowledgedCallStatus] = useState(ApiCallStatus.NotStarted);
	const [syncAnnouncementDataCallStatus, setSyncAnnouncementDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const syncAnnouncementDataAsync = async () => {
		setSyncAnnouncementDataCallStatus(ApiCallStatus.InProgress);
		if (isAuthenticated === true) {
			const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS) ?? DefaultAnnouncementObject;
			const userAnnouncements = JSON.parse(userAnnouncementsString);
			const userAnnouncementsClone = cloneDeep(userAnnouncements);
			const seenAnnouncements = cloneDeep(userAnnouncementsClone.seen);
			const acknowledgedAnnouncements = cloneDeep(userAnnouncementsClone.acknowledged);
			let auth0Token;
			if (seenAnnouncements.length > 0 || acknowledgedAnnouncements.length > 0) {
				auth0Token = await getAccessTokenSilently();
			}

			seenAnnouncements.map(async announcement => {
				await editAnnouncementDataMarkAsSeen({
					auth0Token,
					announcementId: announcement.announcementId,
					setStatusInProgress: () => {},
					setStatusSuccess: () => {
						return {...announcement, delete: true, dateUpdated: moment() }
					},
					setStatusFailed: () => {},
					setStatusError: () => {}
				});
			})

			acknowledgedAnnouncements.map(async announcement => {
				await editAnnouncementDataMarkAsAcknowledged({
					auth0Token,
					announcementId: announcement.announcementId,
					setStatusInProgress: () => {},
					setStatusSuccess: () => {
						return {...announcement, delete: true, dateUpdated: moment() }
					},
					setStatusFailed: () => {},
					setStatusError: () => {}
				});
			});

			const newSeenArray = filter(userAnnouncementsClone, x => x.delete === false);
			const newAcknowledgedArray = filter(userAnnouncementsClone, x => x.delete === false);

			userAnnouncementsClone.seen = newSeenArray;
			userAnnouncementsClone.acknowledged = newAcknowledgedArray;

			setItem(
				storageKeys.USER_ANNOUNCEMENTS,
				JSON.stringify(userAnnouncementsClone)
			);
		}
		setSyncAnnouncementDataCallStatus(ApiCallStatus.Succeeded);
	}

	const fetchUserAnnouncementsAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchUserAnnouncements({
			auth0Token,
			setAnnouncements,
			setStatusInProgress: () => setFetchUserAnnouncementsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchUserAnnouncementsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchUserAnnouncementsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchUserAnnouncementsCallStatus(ApiCallStatus.Error)
		});
	};

	const editAnnouncementDataMarkAsSeenAsync = async (announcementId) => {
		if (isAuthenticated === false) {
			const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS) ?? DefaultAnnouncementObject;
			let userAnnouncements = JSON.parse(userAnnouncementsString);

			const userAnnouncementsClone = cloneDeep(userAnnouncements);
			const arrayClone = userAnnouncementsClone.seen;
			arrayClone.push({ announcementId, dateUpdated: moment() });
			userAnnouncementsClone.seen = uniqBy(arrayClone, x => x.announcementId);
			setItem(
				storageKeys.USER_ANNOUNCEMENTS,
				JSON.stringify(userAnnouncementsClone)
			);
		}

		if (isAuthenticated === true) {
			let auth0Token = await getAccessTokenSilently();
			await editAnnouncementDataMarkAsSeen({
				auth0Token,
				announcementId,
				setStatusInProgress: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setEditAnnouncementDataMarkAsSeenCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const editAnnouncementDataMarkAsAcknowledgedAsync = async (announcementId) => {
		if (isAuthenticated === false) {
			const userAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS) ?? DefaultAnnouncementObject;
			let userAnnouncements = JSON.parse(userAnnouncementsString);

			if (!userAnnouncements) {
				userAnnouncements = {
					seen: [],
					acknowledged: []
				};
			}

			const userAnnouncementsClone = cloneDeep(userAnnouncements);
			const seenArrayClone = userAnnouncementsClone.seen;
			seenArrayClone.push({ announcementId, dateUpdated: moment() });
			userAnnouncementsClone.seen = uniqBy(seenArrayClone, x => x.announcementId);
			const arrayClone = userAnnouncementsClone.acknowledged;
			arrayClone.push({ announcementId, dateUpdated: moment() });
			userAnnouncementsClone.acknowledged = uniqBy(arrayClone, x => x.announcementId);
			setItem(
				storageKeys.USER_ANNOUNCEMENTS,
				JSON.stringify(userAnnouncementsClone)
			);
		}

		if (isAuthenticated === true) {
			let auth0Token = await getAccessTokenSilently();
			await editAnnouncementDataMarkAsAcknowledged({
				auth0Token,
				announcementId,
				setStatusInProgress: () => setEditAnnouncementDataMarkAsAcknowledgedCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setEditAnnouncementDataMarkAsAcknowledgedCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setEditAnnouncementDataMarkAsAcknowledgedCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setEditAnnouncementDataMarkAsAcknowledgedCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const closeAnnouncement = () => {
		setAnnouncementOpen(false);
	};

	const gotoAnnouncementLink = () => {
		if (!!announcement?.link) {
			navigate(announcement.link);
		}
	};

	useEffect(() => {
		const loadData = async () => {
			await syncAnnouncementDataAsync();
		}

		if (genericDataLoaded === true) {
			loadData();
		}
	}, [genericDataLoaded]);

	useEffect(() => {
		const loadData = async () => {
			await fetchUserAnnouncementsAsync();
		}

		if (syncAnnouncementDataCallStatus === ApiCallStatus.Succeeded) {
			loadData();
		}
	}, [syncAnnouncementDataCallStatus]);

	useEffect(() => {
		if (fetchUserAnnouncementsCallStatus === ApiCallStatus.Succeeded && !!announcements) {
			if (announcements.length > 0) {
				const localAnnouncementsString = getItem(storageKeys.USER_ANNOUNCEMENTS) ?? DefaultAnnouncementObject;
				const localAnnouncements = JSON.parse(localAnnouncementsString);

				const seenAnnouncementIds = localAnnouncements.seen?.map(x => x.announcementId) ?? [];
				const acknowledgedAnnouncementIds = localAnnouncements.acknowledged?.map(x => x.announcementId)  ?? [];

				const announcementsToShowArray = announcements.filter(announcement => {
					const isSeen = seenAnnouncementIds.includes(announcement.announcementId);
					const isAcknowledged = acknowledgedAnnouncementIds.includes(announcement.announcementId);

					if (isSeen === false && isAcknowledged === false) {
						return true;
					}
					if (announcement.Type === AnnouncementType.Acknowledgement) {
						return isAcknowledged === true;
					}

					if (announcement.Type === AnnouncementType.Informational || announcement.Type === AnnouncementType.Promotional) {
						return isSeen === true;
					}

					return false;
				});

				setAnnouncementsToShow(announcementsToShowArray);
			}
		}
	}, [fetchUserAnnouncementsCallStatus]);

	useEffect(() => {
		if (announcementsToShow.length > 0) {
			setAnnouncement(announcementsToShow[0]);
			setAnnouncementOpen(true);
		}
	}, [announcementsToShow]);

	const allLoadingStates = [
		fetchUserAnnouncementsCallStatus,
		editAnnouncementDataMarkAsSeenCallStatus,
		editAnnouncementDataMarkAsAcknowledgedCallStatus,
		syncAnnouncementDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<>
			{!!announcement && !!announcementOpen &&
				<AnnouncementModal
					open={announcementOpen}
					setClose={closeAnnouncement}
					type={announcement.type}
					html={announcement.html}
					image={announcement.bannerImage}
					gotoLink={gotoAnnouncementLink}
					announcementId={announcement.announcementId}
					markAnnouncementAsSeen={editAnnouncementDataMarkAsSeenAsync}
					markAnnouncementAsAcknowledged={editAnnouncementDataMarkAsAcknowledgedAsync}
				/>
			}
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>

		</>
	)
};

export default Announcements;