import React, { useEffect, useState } from "react";
import { removeAuthCookies } from "../../api/authentication";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import Home from "../../components/Home";
import { ApiCallStatus } from "../../utils/constants";
import { fetchHomePageContentData } from "../PageFunctions/pageContentFunctions";
import { fetchVendorsData } from "../PageFunctions/productFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const clearCookies = async () => {
	await removeAuthCookies();
};

const HomePage = ({
	genericDataLoaded,
	genericPageContent,
	categoriesAndTypes,
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const [pageContent, setPageContent] = useState(null);
	const [vendors, setVendors] = useState(null);

	const [fetchHomePageContentCallStatus, setFetchHomePageContentCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVendorsDataCallStatus, setFetchVendorsDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchHomePageContentDataAsync = async () => {
		if (fetchHomePageContentCallStatus === ApiCallStatus.Succeeded && !!pageContent) {
			return;
		}
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchHomePageContentData({
			auth0Token,
			setPageContent,
			setStatusInProgress: () => setFetchHomePageContentCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchHomePageContentCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchHomePageContentCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchHomePageContentCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchVendorsDataAsync = async () => {
		await fetchVendorsData({
			setVendorData: setVendors,
			setStatusInProgress: () => setFetchVendorsDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVendorsDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVendorsDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVendorsDataCallStatus(ApiCallStatus.Error)
		});
	};

	const authorizedLoad = async () => {
		await Promise.all([

		]);
	};

	const unauthorizedLoad = async () => {
		await Promise.all([
			fetchVendorsDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			fetchHomePageContentDataAsync();
			unauthorizedLoad();
			if (isAuthenticated === false) {
				clearCookiesAsync();
			} else {
				authorizedLoad();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	const allLoadingStates = [
		fetchHomePageContentCallStatus,
		fetchVendorsDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<Home
				vendors={vendors}
				categoriesAndTypes={categoriesAndTypes}
				customerCategory={genericPageContent?.customerCategory}
				pageContent={pageContent}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default HomePage;
