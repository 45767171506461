import React, { Fragment } from "react";
import { Box, Button, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import Image from "../Image";

export const confirmationChoices = {
	cancel: "cancel",
	confirm: "confirm"
};

export const ConfirmationModal = ({
	open,
	closeModal,
	heading,
	subHeading,
	content,
	disableConfirm,
	confirmLabel = "Confirm",
	confirmLoading,
	rejectLabel = "Cancel",
	forChanges
}) => {

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	return (
		<Fragment>
			{isMobile === true &&
				<Modal open={open} onClose={() => closeModal(confirmationChoices.cancel)}>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '90%',
							bgcolor: 'background.paper',
							boxShadow: 24,
							p: 4,
							borderRadius: 2,
						}}
					>
						<Grid container direction="column" spacing={2} textAlign="center">
							<Grid item xs={12}>
								<img
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Argus Logo"
									style={{ width: '100px', margin: '0 auto' }}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h6" gutterBottom>
									{heading}
								</Typography>
								<Typography variant="body2" color="textSecondary">
									{subHeading}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								{content}
							</Grid>
							<Grid item xs={12}>
								<Button
									size="small"
									type="button"
									variant="contained"
									color="error"
									onClick={() => closeModal(confirmationChoices.confirm)}
									disabled={disableConfirm || confirmLoading}
									fullWidth
									sx={{ mb: 2 }}
								>
									{confirmLoading ? <CircularProgress size={24} /> : (forChanges ? "Save Changes" : confirmLabel)}
								</Button>
								<Button
									size="small"
									type="button"
									variant="outlined"
									onClick={() => closeModal(confirmationChoices.cancel)}
									fullWidth
								>
									{forChanges ? "Discard Changes" : rejectLabel}
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
			{isMobile === false &&
				<Modal open={open} onClose={() => closeModal(confirmationChoices.cancel)}>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '50%',
							maxWidth: 600,
							bgcolor: 'background.paper',
							borderRadius: 4,
							boxShadow: 24,
							p: 4,
						}}
					>
						<Grid container spacing={3} justifyContent="center">
							<Grid item xs={12} textAlign="center">
								<Image
									src="https://argusweb.azureedge.net/argus-web/logo.svg"
									alt="Argus Logo"
									width={80}
									height={80}
								/>
							</Grid>
							<Grid item xs={12} textAlign="center">
								<Typography variant="h5" component="h2">
									{heading}
								</Typography>
								<Typography variant="body1" color="text.secondary" mt={1}>
									{subHeading}
								</Typography>
							</Grid>
							{content && (
								<Grid item xs={12} textAlign="center">
									{content}
								</Grid>
							)}
							<Grid item xs={12} container justifyContent="flex-end" spacing={2}>
								<Grid item>
									<Button
										size="small"
										type="button"
										variant="outlined"
										onClick={() => closeModal(confirmationChoices.cancel)}
									>
										{forChanges ? 'Discard Changes' : rejectLabel}
									</Button>
								</Grid>
								<Grid item>
									<Button
										size="small"
										type="button"
										variant="contained"
										color="error"
										onClick={() => closeModal(confirmationChoices.confirm)}
										disabled={disableConfirm || confirmLoading}
									>
										{confirmLoading ? (
											<CircularProgress size={24} sx={{ color: '#fff' }} />
										) : (
											forChanges ? 'Save Changes' : confirmLabel
										)}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
		</Fragment>
	);
};
export default ConfirmationModal;
