import { Box, Button, Card, CardContent, CardMedia, Chip, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { CustomerCategory, ProductAvailability } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { SouthAfricanRand } from "../../utils/helpers";
import { round } from "lodash";


const MobileGridItem = ({
	availabilityImage,
	applicationPositionImage,
	colourForAvailability,
	handleCloseModal,
	handleOpenModal,
	colourForQuality,
	customerCategory,
	fitmentImage,
	handleAddToCartClick,
	modalContent,
	openModal,
	qualityImage,
	sanitizedData,
	vendorImage,
	warranty,
	warrantyText,
	showVehicleSpecificInformation,
	vendorDescription,
	invoicePrice,
	price,
	promo,
	promoInvoicePrice,
	image,
	title,
	vendor,
	fitment,
	sku,
	availability,
	quality
}) => {
	return (
		<Fragment>
			<Card raised sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column', p: 1 }}>
				<a href={`/product/${sku}`} style={{textDecoration: 'none', color: 'inherit'}}>
					<CardMedia
						component="img"
						image={image}
						alt={title}
						sx={{
							width: '100%',
							height: 'auto',
							objectFit: 'contain'
						}}
					/>
				</a>

				<CardContent sx={{display: 'flex', flexDirection: 'column', gap: 1, p: 0.5}}>
					{title ? (
						<a href={`/product/${sku}`} style={{textDecoration: 'none', color: 'inherit'}}>
							<Typography variant="caption"
							            sx={{
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											display: '-webkit-box',
											WebkitLineClamp: 2,
											WebkitBoxOrient: 'vertical',
											minHeight: '3.2em',
											maxHeight: '3.2em',
										}}>
								{title}
							</Typography>
						</a>
					) : ( <Box sx={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						display: '-webkit-box',
						WebkitLineClamp: 2,
						WebkitBoxOrient: 'vertical',
						minHeight: '3.2em',
						maxHeight: '3.2em',
					}}/> )
					}

					{vendor ? (
							<Tooltip title={vendorDescription}>
								<Typography variant="caption"
								            color="textSecondary"
								            sx={{
									            height: '1.2em',
									            maxHeight: '1.2em',
								            }}>
									{vendor}
								</Typography>
							</Tooltip>
						) :
						(<Box sx={{height: '1.2em', maxHeight: '1.2em',}}/>)
					}

					<Typography
						variant="body1"
						sx={{
							fontWeight: "bold",
							textDecoration: promo > 0 ? "line-through" : "none"
						}}
					>
						{SouthAfricanRand.format(round(price, 2))}
					</Typography>

					{invoicePrice > 0 && invoicePrice !== price && !promoInvoicePrice && (
						<Typography
							variant="body2"
							color="textSecondary"
							sx={{
								fontSize: '0.7rem',
								textDecoration: promoInvoicePrice > 0 ? "line-through" : "none"
							}}
						>
							Invoice: <strong>{SouthAfricanRand.format(round(invoicePrice, 2))}</strong>
						</Typography>
					)}

					{promo > 0 && (
						<Typography variant="body1" color="error" sx={{ fontWeight: "bold", mt: 1 }}>
							{SouthAfricanRand.format(round(promo, 2))}
						</Typography>
					)}

					{promoInvoicePrice > 0 && promoInvoicePrice !== promo && (
						<Typography variant="body2" color="error" sx={{ fontSize: '0.7rem', mt: 1 }}>
							Invoice: <strong>{SouthAfricanRand.format(round(promoInvoicePrice, 2))}</strong>
						</Typography>
					)}

					<Box sx={{ mt: 1 }}>
						{availability !== ProductAvailability.No_Stock &&
							<Button
								variant="contained"
								color="error"
								type="button"
								fullWidth
								size="small"
								onClick={handleAddToCartClick}
							>
								Add to cart
							</Button>
						}
					</Box>
				</CardContent>
			</Card>
		</Fragment>
	)
}

export default MobileGridItem;