import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import { Avatar, Box, Button, Grid, Paper, Typography } from "@mui/material";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";

const PaymentEft = ({paymentOrderInfo, onSubmit}) => {
	const { screenSize } = useSystemHook();
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					<Grid container justifyContent="center" spacing={4}>
						<Grid item xs={12}>
							<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
								<Avatar
									src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
									alt="EFT Payment"
									sx={{ width: 100, height: 100, margin: '0 auto 1.5rem' }}
								/>
								<Typography variant="body1" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
									Direct EFT
								</Typography>
								<Typography variant="body2" sx={{ marginBottom: '0.5rem' }}>
									Please note that shipment will only take place once the payment reflects in our account.
								</Typography>
								<Typography variant="body2" sx={{ marginBottom: '1.5rem' }}>
									(Payment is through Nedbank or Standard Bank)
								</Typography>

								{!!paymentOrderInfo && (
									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={onSubmit}
										sx={{ marginTop: '1.5rem' }}
									>
										Pay
									</Button>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '50vh', backgroundColor: '#f7f7f7', padding: '16px' }}>
					<Grid container justifyContent="center" spacing={2}>
						<Grid item xs={12}>
							<Paper elevation={3} sx={{ padding: '16px', textAlign: 'center' }}>
								<Avatar
									src="https://argusweb.azureedge.net/argus-web/payment/eft.svg"
									alt="EFT Payment"
									sx={{ width: 80, height: 80, margin: '0 auto 16px' }}
								/>
								<Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
									Direct EFT
								</Typography>
								<Typography variant="caption" sx={{ marginBottom: '8px' }}>
									Please note that shipment will only take place once the payment reflects in our account.
								</Typography>
								<Typography variant="caption" sx={{ marginBottom: '16px' }}>
									(Payment is through Nedbank or Standard Bank)
								</Typography>

								{!!paymentOrderInfo && (
									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={onSubmit}
										sx={{ width: '100%', marginTop: '16px' }}
									>
										Pay
									</Button>
								)}
							</Paper>
						</Grid>
					</Grid>
				</Box>
			}
		</>
	);
};

export default PaymentEft;
