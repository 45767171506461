import React from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Checkbox,
	FormGroup,
	FormControlLabel,
	Typography,
	Box, Button, Link
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FilterAccordion = ({
	name,
	title,
	options,
	activeFilters,
	onFilterChange
}) => {
	return (
		<Accordion>
			<AccordionSummary expandIcon={<ExpandMoreIcon fontSize="small" />}>
				<Box sx={{ flexGrow: 1, justifyContent: "space-between", display: 'flex', width: '100%' }}>
					<Typography variant="caption" edge="start">
						{title}
					</Typography>
					{!!activeFilters[name] &&
						<Box edge="end">
							<Link href="#"
							      variant="caption"
							      underline="hover"
							      onClick={() => onFilterChange({ target: { checked: false, name }})}
							      sx={{ fontWeight: "bold" }}
								>
								Clear
							</Link>
						</Box>
					}
				</Box>



			</AccordionSummary>
			<AccordionDetails>
				<FormGroup>
					{options.map((option, index) => (
						<FormControlLabel
							key={index}
							disableTypography
							label={<Typography variant="caption">{option.text}</Typography>}
							control={
								<Checkbox
									size="small"
									checked={activeFilters[name] === option.value}
									onChange={onFilterChange}
									name={name}
									value={option.value}
								/>
							}
						/>
					))}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};

export default FilterAccordion;
