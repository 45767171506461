import React, { useState } from 'react';
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const MobileMakeModelYear = ({
	manufacturerOptions,
	handleUserVehicleChange,
	loading,
	variantOptions,
	yearOptions,
	modelOptions,
	userInput,
	submit
}) => {

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Box sx={{ width: '100%', padding: 2, backgroundColor: '#262626', borderRadius: 2 }}>
			<Typography variant="h6" sx={{ mb: 2, textAlign: 'center', color: '#ffffff' }}>
				Search by Make Model Year
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "center"}}>
				<Button variant="contained"
				        color="error"
				        size="small"
				        onClick={handleOpen}
				        startIcon={<TouchAppIcon />}
				        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					Find Parts for Your Vehicle
				</Button>
			</Box>

			<Modal
				open={open}
				onClose={handleClose}
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<Box sx={{
					width: '95%',
					maxWidth: '550px',
					bgcolor: '#ffffff',
					color: '#000000',
					borderRadius: 2,
					p: 3,
					position: 'relative',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<form onSubmit={submit}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								Vehicle Details
							</Typography>
							<IconButton onClick={handleClose}>
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Autocomplete
									loading={loading}
									disabled={loading}
									value={userInput.manufacturer}
									onChange={handleUserVehicleChange("manufacturer")}
									options={manufacturerOptions}
									getOptionLabel={(option) => option}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Make"
											variant="outlined"
											InputLabelProps={{ style: { color: '#000000' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#000000' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#000000',
													},
													'&:hover fieldset': {
														borderColor: '#000000',
													},
													'&.Mui-focused fieldset': {
														borderColor: '#000000',
													},
												},
											}}
										/>
									)}
									clearOnEscape
									isOptionEqualToValue={(option, value) => option === value}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									loading={loading}
									disabled={loading}
									value={userInput.model}
									onChange={handleUserVehicleChange("model")}
									options={modelOptions}
									getOptionLabel={(option) => option}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Model"
											variant="outlined"
											InputLabelProps={{ style: { color: '#000000' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#000000' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#000000',
													},
													'&:hover fieldset': {
														borderColor: '#000000',
													},
													'&.Mui-focused fieldset': {
														borderColor: '#000000',
													},
												},
											}}
										/>
									)}
									clearOnEscape
									isOptionEqualToValue={(option, value) => option === value}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									loading={loading}
									value={userInput.variant}
									onChange={handleUserVehicleChange("variant")}
									options={variantOptions}
									getOptionLabel={(option) => option}
									disabled={(!userInput.manufacturer && !userInput.model) || loading}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Variant"
											variant="outlined"

											InputLabelProps={{ style: { color: '#000000' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#000000' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#000000',
													},
													'&:hover fieldset': {
														borderColor: '#000000',
													},
													'&.Mui-focused fieldset': {
														borderColor: '#000000',
													},
												},
											}}
										/>
									)}
									clearOnEscape
									isOptionEqualToValue={(option, value) => option === value}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									loading={loading}
									value={userInput.year}
									onChange={handleUserVehicleChange("year")}
									options={yearOptions}
									getOptionLabel={(option) => option}
									disabled={
										(!userInput.manufacturer &&
										!userInput.model &&
										!userInput.variant) || loading
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Year"
											variant="outlined"

											InputLabelProps={{ style: { color: '#000000' } }}
											InputProps={{
												...params.InputProps,
												style: { color: '#000000' },
											}}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#000000',
													},
													'&:hover fieldset': {
														borderColor: '#000000',
													},
													'&.Mui-focused fieldset': {
														borderColor: '#000000',
													},
												},
											}}
										/>
									)}
									clearOnEscape
									isOptionEqualToValue={(option, value) => option === value}
								/>
							</Grid>
						</Grid>
						<Box sx={{ mt: 4, textAlign: 'center' }}>
							<Button variant="contained" size="small" color="error" type="submit" disabled={loading}>
								Search
							</Button>
						</Box>
					</form>
				</Box>
			</Modal>
		</Box>
	)
}

export default MobileMakeModelYear;