import React from 'react';
import { Button, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import cssVariables from "../../utils/cssVariables";

const CustomButton = ({
	text,
	startIcon,
	endIcon,
	basic = false,
	color = 'primary',
	variant = 'contained',
	size = 'medium',
	onClick,
	disabled = false,
	type = 'button',
	href,
	target,
	customColor,
	customBackgroundColor={},
	...props
}) => {
	const theme = useTheme();

	const resolveColor = (colorProp) => {
		return theme.palette[colorProp]?.main || colorProp;
	};

	const textColor = basic
		? resolveColor(customColor || color)
		: resolveColor(color);

	const backgroundColor = basic
		? 'transparent'
		: customBackgroundColor || resolveColor(color);

	const borderColor = basic ? 'transparent' : textColor;

	return (
		<Button
			onClick={onClick}
			variant={basic ? 'text' : variant}
			size={size ?? "small"}
			startIcon={startIcon}
			endIcon={endIcon}
			disabled={disabled}
			type={type}
			href={href}
			target={target}
			sx={{
				borderRadius: cssVariables.borderRadiusS,
				textTransform: 'none',
				fontWeight: 'bold',
				color: textColor,
				backgroundColor: backgroundColor,
				padding: text ? undefined : 0,
				minWidth: text ? undefined : 0,
				'&:hover': {
					backgroundColor: basic ? 'transparent' : resolveColor(backgroundColor),
					borderColor: basic ? 'transparent' : resolveColor(borderColor),
				},
				...props.sx,
			}}
		>
			{text}
		</Button>
	);
};

CustomButton.propTypes = {
	text: PropTypes.string,
	startIcon: PropTypes.element,
	endIcon: PropTypes.element,
	basic: PropTypes.bool,
	color: PropTypes.string,
	customColor: PropTypes.string,
	customBackgroundColor: PropTypes.string,
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	href: PropTypes.string,
	target: PropTypes.string,
	sx: PropTypes.object,
};

export default CustomButton;
