import React from 'react';
import { Box, Typography, Link, Container } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from "react-responsive";

const SiteTopBar = () => {
	const { user, isLoading, isAuthenticated } = useAuth0();

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	if (isLoading) return null;

	return (
		<>
			{isMobile === true &&
				<Box
					sx={{
						width: '100%',
						backgroundColor: '#333',
						color: '#fff',
						padding: '8px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<Box sx={{ marginBottom: 1 }}>
						{(isAuthenticated === true && !!user && !!user.email_verified) || (isAuthenticated === false) &&
							<Link href="tel:0117475200" variant="caption" color="inherit" underline="none">
								Call us: +27 (0) 11 747 5200
							</Link>
						}
					</Box>

					<Box sx={{ marginBottom: 1 }}>
						{isAuthenticated === true && !!user && !user.email_verified && (
							<Typography variant="body2" color="inherit">
								Please verify your email address
							</Typography>
						)}
					</Box>

					<Box>
						{(isAuthenticated === true && !!user && !!user.email_verified) || (isAuthenticated === false) &&
							<Link href="mailto:info@argusmotoring.co.za" variant="caption" color="inherit" underline="none">
								info@argusmotoring.co.za
							</Link>
						}
					</Box>
				</Box>
			}
			{isMobile === false &&
				<Box
					sx={{
						width: '100%',
						backgroundColor: '#333',
						color: '#fff',
						padding: '8px'
					}}
				>
					<Container maxWidth="md">
						<Box sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
							<Box sx={{ flex: 1, textAlign: 'left' }}>
								{(isAuthenticated === true && !!user && !!user.email_verified) || (isAuthenticated === false) &&
									<Link href="tel:0117475200" variant="body2" color="inherit" underline="none">
										Call us: +27 (0) 11 747 5200
									</Link>
								}
							</Box>

							<Box sx={{ flex: 1, textAlign: 'center' }}>
								{isAuthenticated && user && !user.email_verified && (
									<Typography variant="body2" color="inherit">
										Please verify your email address
									</Typography>
								)}
							</Box>

							<Box sx={{ flex: 1, textAlign: 'right' }}>
								{(isAuthenticated === true && !!user && !!user.email_verified) || (isAuthenticated === false) &&
									<Link href="mailto:info@argusmotoring.co.za" variant="body2" color="inherit" underline="none">
										info@argusmotoring.co.za
									</Link>
								}
							</Box>
						</Box>

					</Container>
				</Box>
			}
		</>
	);
};

export default SiteTopBar;
