import React, { Fragment  } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { ApiCallStatus } from "../../utils/constants";
import TermsOfService from "../../components/TermsOfService";


const TermsOfServicePage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const allLoadingStates = [

	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false;

	return (
		<Container maxWidth="lg">
			<TermsOfService/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default TermsOfServicePage;
