import React, { Fragment, useEffect, useState } from "react";
import Cart from "../../components/Cart";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import { useNavigate } from "react-router-dom";
import { ApiCallStatus } from "../../utils/constants";
import { useCart } from "react-use-cart";
import { find } from "lodash";
import {
	fetchCartPriceData,
	fetchCartSummaryPriceData
} from "../PageFunctions/priceFunctions";
import { fetchProductsBySkus } from "../PageFunctions/productFunctions";
import {
	createCheckout,
	fetchOrderCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const CartPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const navigate = useNavigate();
	const {items} = useCart();
	const [productData, setProductData] = useState(null);
	const [pricingData, setPricingData] = useState(null);
	const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
	const [vat, setVat] = useState(null);
	const [cartSummaryPricingData, setCartSummaryPricingData] = useState([]);
	const [cartNettTotal, setCartNettTotal] = useState(0);
	const [settlementAmount, setSettlementAmount] = useState(0);
	const [cartVatAmount, setCartVatAmount] = useState(0);
	const [cartGrandTotal, setCartGrandTotal] = useState(0);

	const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [createCheckoutCallStatus, setCreateCheckoutCallStatus] = useState(ApiCallStatus.NotStarted);
	const [productDataCallStatus, setProductDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [cartPriceDataCallStatus, setCartPriceDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [cartSummaryPriceDataCallStatus, setCartSummaryPriceDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const goBack = () => navigate(-1);
	const gotoCheckoutPage = () => navigate("/checkout/summary");

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderCheckoutData({
			auth0Token,
			setOrderCheckoutInfo,
			setStatusInProgress: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const createCheckoutAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await createCheckout({
			auth0Token,
			items,
			setStatusInProgress: () => setCreateCheckoutCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCreateCheckoutCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCreateCheckoutCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCreateCheckoutCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchProductDataAsync = async () => {
		const productSkus = items?.map((p) => p.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchProductsBySkus({
			auth0Token,
			productSkus,
			setProductData,
			setStatusInProgress: () => setProductDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setProductDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setProductDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setProductDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchPricesDataAsync = async () => {
		const productSkus = productData?.map((p) => p.sku);
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await Promise.all([
			fetchCartPriceData({
				auth0Token,
				productSkus,
				setPricingData,
				setStatusInProgress: () => setCartPriceDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setCartPriceDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setCartPriceDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setCartPriceDataCallStatus(ApiCallStatus.Error)
			}),
			fetchCartSummaryPriceData({
				auth0Token,
				productSkus,
				setCartSummaryPricingData,
				setStatusInProgress: () => setCartSummaryPriceDataCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setCartSummaryPriceDataCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setCartSummaryPriceDataCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setCartSummaryPriceDataCallStatus(ApiCallStatus.Error)
			})
		]);
	};

	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutOrderDataAsync(),
			fetchProductDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				unAuthorizedPageLoad();
			}
			else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}
	}, [isAuthenticated, genericDataLoaded]);

	useEffect(() => {
		if (orderCheckoutInfo?.vat) {
			const vatTemp = orderCheckoutInfo.vat;
			setVat(vatTemp);
		}
	}, [orderCheckoutInfo?.vat]);

	useEffect(() => {
		if (createCheckoutCallStatus === ApiCallStatus.Succeeded) {
			gotoCheckoutPage();
		}
	}, [createCheckoutCallStatus]);

	useEffect(() => {
		if (productDataCallStatus === ApiCallStatus.Succeeded && !!productData) {
			fetchPricesDataAsync();
		}
	}, [productData, productDataCallStatus]);

	useEffect(() => {
		if (cartSummaryPriceDataCallStatus === ApiCallStatus.Succeeded && !!cartSummaryPricingData) {
			let newCartNettTotal = 0;
			let newSettlementAmount = 0;
			let newCartVatAmount = 0;
			let newCartGrandTotal = 0;

			if (!!items && cartSummaryPricingData.length > 0) {
				items.map((item) => {
					const skuPrices = find(
						cartSummaryPricingData,
						(p) => p.sku === item.sku
					);

					if (!!skuPrices) {
						newCartNettTotal += skuPrices.nettTotal * item.quantity;
						newSettlementAmount += skuPrices.settlementAmount * item.quantity;
						newCartVatAmount += skuPrices.vatAmount * item.quantity;
						newCartGrandTotal += skuPrices.total * item.quantity;
					}
				});

				setCartNettTotal(newCartNettTotal);
				setSettlementAmount(newSettlementAmount);
				setCartVatAmount(newCartVatAmount);
				setCartGrandTotal(newCartGrandTotal);
			}
		}
	}, [items, cartSummaryPricingData, cartSummaryPriceDataCallStatus]);

	const allLoadingStates = [
		orderCheckoutInfoCallStatus,
		createCheckoutCallStatus,
		productDataCallStatus,
		cartPriceDataCallStatus,
		cartSummaryPriceDataCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<Cart
				loading={pageIsLoading}
				cartNettTotal={cartNettTotal}
				settlementAmount={settlementAmount}
				vatAmount={cartVatAmount}
				total={cartGrandTotal}
				vat={vat}
				productData={productData}
				pricingData={pricingData}
				customerCategory={genericPageContent?.customerCategory}
				createCheckout={createCheckoutAsync}
				goBack={goBack}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default CartPage;
