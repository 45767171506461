import React, { Fragment } from "react";
import { Box, Container, Divider, Grid, Link, List, ListItem, ListItemText, Typography } from "@mui/material";
import PageHeader from "../PageHeader";

const Popia = () => {
	return (
		<Fragment>
			<PageHeader title="Protection of Personal Information Act (“POPI Act”)" />
			<Box sx={{ p: 2 }}>
				<Grid container spacing={4}>

					<Grid item xs={12}>
						<Typography variant="caption" sx={{ lineHeight: 1.7, color: '#555' }}>
							POPIA promotes the protection of personal information effective
							from the 1st of July 2021. <strong>Argus Motor Company</strong> takes the protection of your personal information very seriously.
							We will only collect, use, and keep personal information if there
							is a lawful purpose.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="caption" sx={{ lineHeight: 1.7, color: '#555' }}>
							Personal Information is information that identifies you or relates
							to you personally. For example: your name, race, gender, marital
							status, identity number, health, and financial status, contact
							details, and biometric information, etc. Wherever reference is made
							to “Private Body” in this manual, it refers to <strong>Argus Motor Company</strong>.
							These records include, but are not limited to, the records which pertain
							to the Private Body’s own affairs.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="caption" sx={{ color: '#555', mb: 2 }}>
							<strong>Argus Motor Company</strong> collects and uses personal information purposely for:
						</Typography>
						<Box sx={{ pl: 3, color: '#555' }}>
							<List>
								<ListItem>
									<ListItemText>
										<Typography variant="caption">
											&#8226; Communication and interaction with its suppliers and customers via
											email, phone, or online (through our social platforms or websites).
										</Typography>
									</ListItemText>
								</ListItem>

								<ListItem>
									<ListItemText>
										<Typography variant="caption">
											&#8226; Providing information or services that suppliers
											and/or customers may request from us.
										</Typography>

									</ListItemText>
								</ListItem>

								<ListItem>
									<ListItemText>
										<Typography variant="caption">
											&#8226; Compliance with legal obligations to Legislative Acts,
											such as the Companies Act and Income Tax Act, etc.
										</Typography>
									</ListItemText>
								</ListItem>

								<ListItem>
									<ListItemText>
										<Typography variant="caption">
											&#8226; Improving our products and/or services by conducting research and surveys
											to obtain feedback from our suppliers and/or customers,
											and informing them about changes, events, or promotions.
										</Typography>
									</ListItemText>
								</ListItem>

							</List>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="caption">
							<strong>Argus Motor Company</strong> primarily collects personal information directly from our suppliers and/or our customers. We will take
							reasonable practical steps to ensure that all personal information is complete, accurate, and up to date wherever necessary. To enable
							Argus Motor Company to do so, we require that you promptly notify us of any changes to your personal information. We have implemented
							best practices to safeguard your personal information against access by unauthorized persons, unlawful disclosure, or breach.
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<Link
							href="https://argusweb.azureedge.net/argus-web/popia/Form 2 - Request for Access to Record[67][72].pdf"
							target="_blank"
							rel="noopener"
							underline="hover"
							variant="caption"
							sx={{ display: 'block', color: "#0b79bf", fontWeight: 'bold' }}
						>
							REQUEST FOR ACCESS TO RECORD
						</Link>
						<Link
							href="https://argusweb.azureedge.net/argus-web/popia/Form 3 - Outcome of Request and of Fees Payable[53][75].pdf"
							target="_blank"
							rel="noopener"
							underline="hover"
							variant="caption"
							sx={{ display: 'block', color: "#0b79bf", fontWeight: 'bold' }}
						>
							OUTCOME OF REQUEST AND OF FEES PAYABLE
						</Link>
						<Link
							href="https://argusweb.azureedge.net/argus-web/popia/Form 4 - Internal Appeal Form[37][74].pdf"
							target="_blank"
							rel="noopener"
							underline="hover"
							variant="caption"
							sx={{ display: 'block', color: "#0b79bf", fontWeight: 'bold' }}
						>
							INTERNAL APPEAL FORM
						</Link>
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	);
};
export default Popia;
