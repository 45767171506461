import React, { Fragment } from 'react';
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { capitalize } from "lodash";
import { Avatar, Box, Paper, Typography } from "@mui/material";

const CategorySlider = ({
	categoriesAndTypes,
	activeCategory,
	handleActiveCategoryChange
}) => {
	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });


	return (
		<Fragment>
			{isMobile === true &&
				<section>
					<Swiper modules={[Autoplay, Navigation]}
					        spaceBetween={10}
					        slidesPerView={2.5}
					        loop={true}
					        autoplay={{
						        delay: 3500,
						        disableOnInteraction: true
					        }}
					>
						{categoriesAndTypes.map((category) => {
							const categoryName = capitalize(
								category.category.replaceAll("_", " ").toLowerCase()
							);

							return (
								<SwiperSlide key={categoryName}>
									<Paper
										sx={{
											textAlign: 'center',
											padding: 1,
											backgroundColor: '#fff',
											cursor: 'pointer',
											fontWeight: activeCategory === categoryName ? 'bold' : 'normal',
											minHeight: "10rem",
											border: activeCategory === categoryName ? "2px solid red" : "none"
										}}
										onClick={() => handleActiveCategoryChange(categoryName)}
									>
										<Box sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center"
										}}>
											<Avatar
												src={category.image}
												alt={categoryName}
												sx={{
													width: 56,
													height: 56,
													mb: 1
												}}
											/>
											<Typography variant="caption">
												{categoryName}
											</Typography>
										</Box>
									</Paper>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</section>
			}
			{isMobile === false &&
				<section>
					<Swiper
						modules={[Autoplay, Navigation]}
						spaceBetween={40}
						slidesPerView={4}
						navigation
						loop={true}
						pagination={{clickable: true}}
						autoplay={{
							delay: 5000,
							disableOnInteraction: true
						}}
					>
						{categoriesAndTypes.map((category) => {
							const categoryName = capitalize(
								category.category.replaceAll("_", " ").toLowerCase()
							);
							return (
								<SwiperSlide key={categoryName}>
									<Typography
										variant="subtitle2"
										sx={{
											cursor: 'pointer',
											fontWeight: 'bold',
											color: activeCategory === categoryName ? 'black' : 'gray',
											paddingBottom: '4px',
											marginBottom: '4px',
											transition: 'color 0.3s, border-bottom 0.3s',
											'&:hover': {
												color: 'black'
											},
											width: 'fit-content',
											margin: '0 auto',
											background: 'none',
											textDecoration: 'none',
											borderBottom: activeCategory === categoryName ? '3px solid red' : '1px solid red'
										}}
										onClick={() => handleActiveCategoryChange(categoryName)}
									>
										{categoryName}
									</Typography>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</section>
			}
		</Fragment>
	)
}

export default CategorySlider;