import React, { Fragment } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import Team from "../../components/Team";
import { ApiCallStatus } from "../../utils/constants";



const TeamPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const allLoadingStates = [
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false;

	return (
		<Container maxWidth="lg">
			<Team/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default TeamPage;
