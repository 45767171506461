import {
	deleteUserVehicle,
	getUserVehicle,
	getUserVehicles,
	postUserVehicle,
	putUserVehicle
} from "../../api/userVehicleController";

export const fetchUserVehicles = async ({
	auth0Token,
	setUserVehicles,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getUserVehicles({auth0Token});

		if (!!response?.success) {
			setUserVehicles(response?.data);
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const createUserVehicle = async ({
	auth0Token,
	vehicleYearId,
	make,
	model,
	year,
	variant,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await postUserVehicle({
			auth0Token,
			vehicleYearId,
			make,
			model,
			year,
			variant
		});

		if (!!response?.success) {
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const removeUserVehicle = async ({
	auth0Token,
	userVehicleId,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await deleteUserVehicle({
			auth0Token,
			userVehicleId
		});

		if (!!response?.success) {
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};