import React, { Fragment, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import {
	ApiCallStatus
} from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { removeAuthCookies } from "../../api/authentication";
import CheckoutShippingAddress from "../../components/CheckoutShippingAddress";
import {
	fetchAddressCheckoutData,
	fetchCheckoutData,
	fetchOrderCheckoutData,
	updateCheckoutData
} from "../PageFunctions/checkoutFunctions";
import { useAuth0 } from "@auth0/auth0-react";


const clearCookies = async () => {
	await removeAuthCookies();
};

const CheckoutShippingAddressPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const location = useLocation();
	const navigate = useNavigate();
	const [checkoutData, setCheckoutData] = useState(null);
	const [orderCheckoutInfo, setOrderCheckoutInfo] = useState(null);
	const [addressCheckoutInfo, setAddressCheckoutInfo] = useState(null);
	const [countries, setCountries] = useState([]);
	const [provinces, setProvinces] = useState([]);
	const [userAddresses, setUserAddresses] = useState([]);

	const [orderCheckoutInfoCallStatus, setOrderCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [checkoutDataCallStatus, setCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);
	const [addressCheckoutInfoCallStatus, setAddressCheckoutInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [updateCheckoutDataCallStatus, setUpdateCheckoutDataCallStatus] = useState(ApiCallStatus.NotStarted);

	const gotoCreateAddressPage = () => navigate("/checkout/shipping/create");


	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchCheckoutDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchCheckoutData({
			auth0Token,
			setCheckoutData,
			setStatusInProgress: () => setCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutAddressDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchAddressCheckoutData({
			auth0Token,
			setAddressCheckoutInfo,
			setStatusInProgress: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setAddressCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchCheckoutOrderDataAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchOrderCheckoutData({
			auth0Token,
			setOrderCheckoutInfo,
			setStatusInProgress: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setOrderCheckoutInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const updateCheckoutDataAsync = async ({checkoutData}) => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await updateCheckoutData({
			auth0Token,
			checkoutData,
			setStatusInProgress: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setUpdateCheckoutDataCallStatus(ApiCallStatus.Error)
		});
	};


	const authorizedPageLoad = async () => {
		await Promise.all([

		]);
	};

	const unAuthorizedPageLoad = async () => {
		await Promise.all([
			fetchCheckoutDataAsync(),
			fetchCheckoutOrderDataAsync(),
			fetchCheckoutAddressDataAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				navigate("/login", {state: {from: location}});
			} else {
				authorizedPageLoad();
				unAuthorizedPageLoad();
			}
		}

	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (addressCheckoutInfoCallStatus === ApiCallStatus.Succeeded && !!addressCheckoutInfo) {
			let userAddressesTemp = [];
			let countries = [];
			let provinces = [];

			if (addressCheckoutInfo?.countries) {
				const countriesInAlphabeticalOrder = _.sortBy(
					addressCheckoutInfo.countries,
					({name}) => name
				);
				countries = _.sortBy(countriesInAlphabeticalOrder, ({name}) =>
					name === "South Africa" ? 0 : 1
				);
			}

			if (addressCheckoutInfo?.provinces) {
				provinces = addressCheckoutInfo.provinces;
			}

			if (addressCheckoutInfo?.userAddresses) {
				userAddressesTemp = addressCheckoutInfo.userAddresses;
			}

			setProvinces(provinces);
			setCountries(countries);
			setUserAddresses(userAddressesTemp);
		}
	}, [addressCheckoutInfo, addressCheckoutInfoCallStatus]);

	useEffect(() => {
		if (updateCheckoutDataCallStatus === ApiCallStatus.Succeeded) {
			navigate("/checkout/summary");
		}
	}, [updateCheckoutDataCallStatus]);

	const allLoadingStates = [
		checkoutDataCallStatus,
		addressCheckoutInfoCallStatus,
		updateCheckoutDataCallStatus,
		orderCheckoutInfoCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<CheckoutShippingAddress
				orderCheckoutInfo={orderCheckoutInfo}
				checkoutData={checkoutData}
				countries={countries}
				provinces={provinces}
				userAddresses={userAddresses}
				onSubmit={updateCheckoutDataAsync}
				gotoCreateAddressPage={gotoCreateAddressPage}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};

export default CheckoutShippingAddressPage;