import React, { useEffect } from "react";
import PageHeader from "../PageHeader";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import { Avatar, Box, Button, Container, Paper, Typography } from "@mui/material";

const PaymentCreditCardOnCollection = ({paymentOrderInfo, onSubmit}) => {
	const { screenSize } = useSystemHook();
	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<>
			<PageHeader title="Payment" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: '2rem' }}>
					<Container maxWidth="md">
						<Paper elevation={3} sx={{ padding: '2rem', textAlign: 'center' }}>
							<Avatar
								src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
								alt="Credit Card"
								sx={{ width: 100, height: 100, margin: '0 auto 1.5rem' }}
							/>

							{/* Payment Information */}
							{!!paymentOrderInfo && (
								<Box>
									<Typography variant="body2" fontWeight="bold" sx={{ marginBottom: '1.5rem' }}>
										Credit Card Payment
									</Typography>
									<Typography variant="caption" color="textSecondary" sx={{ marginBottom: '2rem' }}>
										Please click the "Pay" button below to proceed with your payment.
									</Typography>

									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={onSubmit}
										sx={{
											width: '50%',
											height: '48px',
											fontSize: '1rem',
										}}
									>
										Pay
									</Button>
								</Box>
							)}
						</Paper>
					</Container>
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '50vh', backgroundColor: '#f7f7f7', padding: '1rem' }}>
					<Container maxWidth="sm">
						<Paper elevation={3} sx={{ padding: '1.5rem', textAlign: 'center' }}>
							<Avatar
								src="https://argusweb.azureedge.net/argus-web/payment/creditcard.svg"
								alt="Credit Card"
								sx={{ width: 80, height: 80, margin: '0 auto 1rem' }}
							/>
							{!!paymentOrderInfo && (
								<Box>
									<Typography variant="body2" fontWeight="bold" sx={{ marginBottom: '1rem' }}>
										Credit Card Payment
									</Typography>
									<Typography variant="caption" color="textSecondary" sx={{ marginBottom: '1.5rem' }}>
										Please click the "Pay" button below to proceed with your payment.
									</Typography>

									<Button
										variant="contained"
										color="error"
										size="small"
										onClick={onSubmit}
										sx={{
											width: '100%',
											height: '40px',
											fontSize: '0.875rem',
										}}
									>
										Pay
									</Button>
								</Box>
							)}
						</Paper>
					</Container>
				</Box>
			}
		</>
	);
};

export default PaymentCreditCardOnCollection;
