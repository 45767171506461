import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import FilterAccordion from '../FilterAccordion';

const DesktopFilters = ({
	productCategoriesListToUse,
	changePageCategory,
	showMoreCategories,
	showAllCategories,
	activeFilters,
	productTypesListToUse,
	changePageProductType,
	showMoreTypes,
	showAllTypes,
	clearPageProductType,
	vendorOptions,
	makeOptions,
	modelOptions,
	variantOptions,
	yearOptions,
	handleFilterChange,
	clearFilter,
}) => {
	return (
		<Grid container spacing={2}>

			<Grid item xs={12}>
				<FilterAccordion
					name="vendor"
					title="Vendor"
					options={vendorOptions}
					activeFilters={activeFilters}
					onFilterChange={handleFilterChange}
				/>
			</Grid>

			{!!makeOptions.length && (
				<Grid item xs={12}>
					<FilterAccordion
						name="make"
						title="Make"
						options={makeOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!modelOptions.length && (
				<Grid item xs={12}>
					<FilterAccordion
						name="model"
						title="Model"
						options={modelOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!variantOptions.length && (
				<Grid item xs={12}>
					<FilterAccordion
						name="variant"
						title="Variant"
						options={variantOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}

			{!!yearOptions.length && (
				<Grid item xs={12}>
					<FilterAccordion
						name="year"
						title="Year"
						options={yearOptions}
						activeFilters={activeFilters}
						onFilterChange={handleFilterChange}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default DesktopFilters;
