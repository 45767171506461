import axios from "axios";
import axiosRetry from "axios-retry";
import { ApiHeaders } from "../utils/constants";

const url = process.env.REACT_APP_PRODUCT_API_URL;

export const getVehicles = async ({
	make,
	model,
	variant,
	year
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const searchParams = new URLSearchParams();
		if (make) searchParams.append("make", make);
		if (model) searchParams.append("model", model);
		if (variant) searchParams.append("variant", variant);
		if (year) searchParams.append("year", year);

		const requestUrl = `?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const fetchVehicleDetails = async ({
	make,
	model,
	variant,
	year,
	setVehicleToSave,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getVehicles({
			make,
			model,
			variant,
			year
		});
		if (!!response?.success) {
			if (response.data.length > 0) {
				setVehicleToSave(response.data[0]);
			}
			setStatusSuccess();
		}
		else {
			setVehicleToSave({
				make,
				model,
				variant,
				year
			});
			setStatusSuccess();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const getManufacturers = async (year) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const searchParams = new URLSearchParams();
		if (year) searchParams.append("year", year);

		const requestUrl = `/manufacturers?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getApplicationManufacturers = async ({
	auth0Token,
	sku
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const requestUrl = `/applications/manufacturers/${sku}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getModels = async (manufacturer, year) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const searchParams = new URLSearchParams();
		if (manufacturer) searchParams.append("manufacturer", manufacturer);
		if (year) searchParams.append("year", year);

		const requestUrl = `/models?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getApplicationModels = async ({
	auth0Token,
	sku,
	make
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (!!make) searchParams.append("make", make);

		const requestUrl = `/applications/models/${sku}?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getVariants = async (manufacturer, model, year) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const searchParams = new URLSearchParams();
		if (manufacturer) searchParams.append("manufacturer", manufacturer);
		if (model) searchParams.append("model", model);
		if (year) searchParams.append("year", year);

		const requestUrl = `/variants?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getApplicationVariants = async ({
	auth0Token,
	sku,
	make,
	model
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (!!make) searchParams.append("make", make);
		if (!!model) searchParams.append("model", model);

		const requestUrl = `/applications/variants/${sku}?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getYears = async (manufacturer, model, variant) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		const searchParams = new URLSearchParams();
		if (manufacturer) searchParams.append("manufacturer", manufacturer);
		if (model) searchParams.append("model", model);
		if (variant) searchParams.append("variant", variant);

		const requestUrl = `/years?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};

export const getApplicationYears = async ({
	auth0Token,
	sku,
	make,
	model,
	variant
}) => {
	try {
		const instance = axios.create({
			withCredentials: true,
			baseURL: `${url}/api/vehicle`,
			headers: {
				"Content-type": 'application/json',
				[ApiHeaders.WebsiteId]: process.env.REACT_APP_WEBSITE_ID
			},
		});

		axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

		if (!!auth0Token) {
			instance.defaults.headers.common["Authorization"] = `Bearer ${auth0Token}`;
		}

		const searchParams = new URLSearchParams();
		if (!!make) searchParams.append("make", make);
		if (!!model) searchParams.append("model", model);
		if (!!variant) searchParams.append("variant", variant);

		const requestUrl = `/applications/years/${sku}?${searchParams.toString()}`;

		return await instance
			.get(requestUrl)
			.then((response) => {
				if (response.status === 200)
					return {
						error: false,
						success: true,
						message: response.data.message,
						data: response.data.data
					};
				else
					return {
						error: true,
						success: false,
						message: response.data.message
					};
			})
			.catch((error) => {
				if (error.response) {
					console.error("Server Error:", error.response.status);
				}
				else if (error.request) {
					console.error("Network Error:", error.request);
				}
				else {
					console.error("Error:", error.message);
				}
			});
	}
	catch (e) {
		return {
			error: true,
			success: false,
			message: "Something went wrong. Please try again."
		};
	}
};
