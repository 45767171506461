import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Link, Modal, Typography } from "@mui/material";
import { storageKeys } from "../../api/storageKeys";
import { getItem, removeItem } from "../../utils/storageHelper";
import { createSearchParams, useNavigate } from "react-router-dom";
import "./style.css";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { confirmationChoices } from "../ChooseVehicleModal";

const desktopStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "35%", // Wider for desktop view
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
};

const tabletStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 3,
};

const mobileStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 2,
};

const SavedVehicleModal = ({
	open,
	setOpen,
	openSavedVehiclesModal,
	setClose
}) => {
	const {screenSize} = useSystemHook();
	const navigate = useNavigate();
	const [currentVehicle, setCurrentVehicle] = useState(null);
	const [currentVehicleName, setCurrentVehicleName] = useState("");

	useEffect(() => {
		const vehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);

		if (vehicleString) {
			const vehicle = JSON.parse(vehicleString);
			setCurrentVehicle(vehicle);
		}
	}, []);

	useEffect(() => {
		if (currentVehicle) {
			let name = "";
			name += currentVehicle.make ? `${currentVehicle.make} ` : "";
			name += currentVehicle.model ? `${currentVehicle.model} ` : "";
			name += currentVehicle.variant ? `${currentVehicle.variant} ` : "";
			name += currentVehicle.year ? `${currentVehicle.year} ` : "";

			setCurrentVehicleName(name);
		}
	}, [currentVehicle]);

	const closeModal = () => {
		setClose();
	};

	const handleRemoveVehicleClick = () => {
		removeItem(storageKeys.GUEST_CURRENT_VEHICLE);
		closeModal();
		navigate(0);
	};

	const handleSearchForPartsClick = async () => {
		const currentVehicleString = getItem(storageKeys.GUEST_CURRENT_VEHICLE);
		if (!currentVehicleString) {
			closeModal();
		}

		const currentVehicleObject = JSON.parse(currentVehicleString);

		if (!currentVehicleObject) {
			closeModal();
		}

		const params = {};

		if (currentVehicleObject?.vyid) {
			params.vehicle_year_id = currentVehicleObject.vyid;

			await navigate({
				pathname: "/products",
				search: `?${createSearchParams(params)}`
			});
		}
		else if (
			!!currentVehicleObject.make ||
			!!currentVehicleObject.model ||
			!!currentVehicleObject.variant ||
			!!currentVehicleObject.year
		) {
			if (currentVehicleObject.make) {
				params.make = currentVehicleObject.make;
			}

			if (currentVehicleObject.model) {
				params.model = currentVehicleObject.model;
			}

			if (currentVehicleObject.variant) {
				params.variant = currentVehicleObject.variant;
			}

			if (currentVehicleObject.year) {
				params.year = currentVehicleObject.year;
			}

			await navigate({
				pathname: "/products",
				search: `?${createSearchParams(params)}`
			});
		}

		closeModal();
	};

	const handleChangeVehicleClick = () => {
		openSavedVehiclesModal();
	};

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Modal open={open} onClose={closeModal}>
					<Box sx={desktopStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								{currentVehicleName}
							</Typography>
							<IconButton onClick={closeModal} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Grid container spacing={2} sx={{ mt: 2 }}>
							<Grid item xs={12} textAlign="center">
								<Typography variant="caption" sx={{ color: '#555' }}>
									This will allow us to find parts specifically for your vehicle.
								</Typography>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									onClick={handleChangeVehicleClick}
									fontSize="small"
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Choose a different vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									onClick={handleRemoveVehicleClick}
									fontSize="small"
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Browse without a vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Button
									variant="contained"
									color="error"
									fontSize="small"
									onClick={handleSearchForPartsClick}
									sx={{ width: '100%', mt: 2 }}
								>
									Search for parts
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Tablet &&
				<Modal open={open} onClose={closeModal}>
					<Box sx={tabletStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								{currentVehicleName}
							</Typography>
							<IconButton onClick={closeModal} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Grid container spacing={2} sx={{ mt: 1 }}>
							<Grid item xs={12} textAlign="center">
								<Typography variant="caption" sx={{ color: '#555' }}>
									This will allow us to find parts specifically for your vehicle.
								</Typography>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									fontSize="small"
									onClick={handleChangeVehicleClick}
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Choose a different vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									fontSize="small"
									onClick={handleRemoveVehicleClick}
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Browse without a vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleSearchForPartsClick}
									sx={{ width: '100%', mt: 1.5 }} // Reduced margin for tablet
								>
									Search for parts
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Mobile &&
				<Modal open={open} onClose={closeModal}>
					<Box sx={mobileStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								{currentVehicleName}
							</Typography>
							<IconButton onClick={closeModal} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Grid container spacing={2} sx={{ mt: 1 }}>
							<Grid item xs={12} textAlign="center">
								<Typography variant="caption" sx={{ color: '#555' }}>
									This will allow us to find parts specifically for your vehicle.
								</Typography>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									fontSize="small"
									onClick={handleChangeVehicleClick}
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Choose a different vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Link
									href="#"
									onClick={handleRemoveVehicleClick}
									fontSize="small"
									sx={{ textDecoration: 'none', color: '#0073e6' }}
								>
									Browse without a vehicle
								</Link>
							</Grid>

							<Grid item xs={12} textAlign="center">
								<Button
									variant="contained"
									color="error"
									onClick={handleSearchForPartsClick}
									size="small"
									sx={{ width: '100%', mt: 1.5 }}
								>
									Search for parts
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
		</>
	);
};
export default SavedVehicleModal;
