import React, { Fragment, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useArgusCart } from "../../contexts/ArgusCartContext";
import { Avatar, Box, Button, Drawer, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import cssVariables from "../../utils/cssVariables";

const AddedToCart = () => {
	const {
		addedToCartViewOpen,
		handleCloseAddedToCartView,
		itemAddedToCart
	} = useArgusCart();

	const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

	return (
		<Fragment>
			{!!addedToCartViewOpen &&
				<Drawer anchor="right"
						open={addedToCartViewOpen}
						onClose={handleCloseAddedToCartView}
						PaperProps={{
							sx: { width: !isMobile ? '40%' : '75%' }
						}}>
					<Box sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						p: 2,
						borderBottom: '1px solid #ccc',
					}}>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>Added to cart</Typography>
						<IconButton onClick={handleCloseAddedToCartView}>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Box>

					<Box sx={{ p: 2 }}>
						<Paper elevation={3} sx={{ p: 2, mb: 2 }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<Avatar src={itemAddedToCart.image}
										alt={itemAddedToCart.name}
										variant="square"
										sx={{
											width: !isMobile ? 80 : 64,
											height: !isMobile ? 80 : 64,
											mr: 2,
										}}
								/>
								<Box>
									<Typography variant="caption">
										{itemAddedToCart.name}
									</Typography>
								</Box>
							</Box>
						</Paper>

						<Box sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
						}}>
							<Button variant="contained"
							        color="error"
							        fullWidth
							        href="/cart"
							        size="small"
							        startIcon={<ShoppingCartIcon fontSize="small" />}
							>
								Go to cart
							</Button>
							<Button fullWidth
									onClick={handleCloseAddedToCartView}
									size="small"
							        sx={{
										backgroundColor: "#00000000",
								        color: cssVariables.blueButtonColor,
								        "&:hover": {
									        backgroundColor: "#00000000",
									        textDecoration: "underline"
								        }
							        }}
							>
								Continue shopping
							</Button>
						</Box>
					</Box>
				</Drawer>
			}
		</Fragment>
		// <Modal open={open}>
		// 	<Box sx={style}>
		// 		<Grid>
		// 			<Grid.Row textAlign="center">
		// 				<Grid.Column width={16} textAlign="center">
		// 					<Image
		// 						src="https://argusweb.azureedge.net/argus-web/logo.svg"
		// 						size="small"
		// 						centered
		// 					/>
		// 				</Grid.Column>
		// 			</Grid.Row>
		// 			<Grid.Row>
		// 				<Grid.Column width={16} textAlign="center">
		// 					<div className="argus-modal-title">
		// 						Successfully added to cart
		// 					</div>
		// 				</Grid.Column>
		// 			</Grid.Row>
		// 			<Grid.Row>
		// 				<Grid.Column width={isMobile ? 16 : 8}>
		// 					<Image src={item.image} size="small" centered/>
		// 				</Grid.Column>
		// 				<Grid.Column width={isMobile ? 16 : 8}>
		// 					<p className="b1">{item.name}</p>
		// 				</Grid.Column>
		// 			</Grid.Row>
		// 			<Grid.Row>
		// 				<Grid.Column width={16} textAlign="right">
		// 					<Button
		// 						type="button"
		// 						className="blue-button"
		// 						floated="right"
		// 						size="medium"
		// 						onClick={() => navigate("/cart")}
		// 					>
		// 						Go to cart
		// 					</Button>
		// 					<Button
		// 						floated="right"
		// 						size="medium"
		// 						onClick={closeModal}
		// 						type="button"
		// 					>
		// 						Continue shopping
		// 					</Button>
		// 				</Grid.Column>
		// 			</Grid.Row>
		// 		</Grid>
		// 	</Box>
		// </Modal>
	);
};
export default AddedToCart;
