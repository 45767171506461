import React, { Fragment, useEffect, useState } from "react";
import Product from "../../components/Product";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import { removeAuthCookies } from "../../api/authentication";
import { AccountType, ApiCallStatus } from "../../utils/constants";
import { fetchProductBySku, fetchProductsStockInfo } from "../PageFunctions/productFunctions";
import { fetchProductPrice } from "../PageFunctions/priceFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import {
	fetchApplicationMakes,
	fetchApplicationModels,
	fetchApplicationVariants, fetchApplicationYears
} from "../PageFunctions/vehicleFunctions";

const clearCookies = async () => {
	await removeAuthCookies();
};

const ProductPage = ({
	genericDataLoaded,
	genericPageContent
}) => {
	const {getAccessTokenSilently, isAuthenticated, isLoading} = useAuth0();
	const { productSku} = useParams();
	const [productData, setProductData] = useState(null);
	const [pricingData, setPricingData] = useState(null);
	const [stockInfo, setStockInfo] = useState(null);
	const [applicationMakes, setApplicationMakes] = useState([]);
	const [applicationModels, setApplicationModels] = useState([]);
	const [applicationVariants, setApplicationVariants] = useState([]);
	const [applicationYears, setApplicationYears] = useState([]);
	const [applicationFilters, setApplicationFilters] = useState({
		make: null,
		model: null,
		variant: null
	});

	const [productFetchCallStatus, setProductFetchCallStatus] = useState(ApiCallStatus.NotStarted);
	const [priceFetchCallStatus, setPriceFetchCallStatus] = useState(ApiCallStatus.NotStarted);
	const [stockInfoCallStatus, setStockInfoCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchApplicationMakesCallStatus, setFetchApplicationMakesCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchApplicationModelsCallStatus, setFetchApplicationModelsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchApplicationVariantsCallStatus, setFetchApplicationVariantsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchApplicationYearsCallStatus, setFetchApplicationYearsCallStatus] = useState(ApiCallStatus.NotStarted);

	const clearCookiesAsync = async () => {
		await clearCookies();
	};

	const fetchProductBySkuAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (productFetchCallStatus === ApiCallStatus.NotStarted) {
			await fetchProductBySku({
				auth0Token,
				productSku,
				setProductData,
				setStatusInProgress: () => setProductFetchCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setProductFetchCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setProductFetchCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setProductFetchCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchProductPriceAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		if (priceFetchCallStatus === ApiCallStatus.NotStarted) {
			await fetchProductPrice({
				auth0Token,
				productSku,
				setPricingData,
				setStatusInProgress: () => setPriceFetchCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setPriceFetchCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setPriceFetchCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setPriceFetchCallStatus(ApiCallStatus.Error)
			});
		}
	};

	const fetchProductStockInfoAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		const productSkus = [productSku];
		await fetchProductsStockInfo({
			auth0Token,
			productSkus,
			setStockInfo,
			setStatusInProgress: () => setStockInfoCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setStockInfoCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setStockInfoCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setStockInfoCallStatus(ApiCallStatus.Error)
		});
	};

	const fetchApplicationMakesAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchApplicationMakes({
			auth0Token,
			sku: productSku,
			setApplicationMakes,
			setStatusInProgress: () => setFetchApplicationMakesCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchApplicationMakesCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchApplicationMakesCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchApplicationMakesCallStatus(ApiCallStatus.Error)
		})
	}

	const fetchApplicationModelsAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchApplicationModels({
			auth0Token,
			sku: productSku,
			make: applicationFilters.make,
			setApplicationModels,
			setStatusInProgress: () => setFetchApplicationModelsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchApplicationModelsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchApplicationModelsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchApplicationModelsCallStatus(ApiCallStatus.Error)
		})
	}

	const fetchApplicationVariantsAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchApplicationVariants({
			auth0Token,
			sku: productSku,
			make: applicationFilters.make,
			model: applicationFilters.model,
			setApplicationVariants,
			setStatusInProgress: () => setFetchApplicationVariantsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchApplicationVariantsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchApplicationVariantsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchApplicationVariantsCallStatus(ApiCallStatus.Error)
		})
	}

	const fetchApplicationYearsAsync = async () => {
		let auth0Token;
		if (isAuthenticated === true) {
			auth0Token = await getAccessTokenSilently();
		}
		await fetchApplicationYears({
			auth0Token,
			sku: productSku,
			make: applicationFilters.make,
			model: applicationFilters.model,
			variant: applicationFilters.variant,
			setApplicationYears,
			setStatusInProgress: () => setFetchApplicationYearsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchApplicationYearsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchApplicationYearsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchApplicationYearsCallStatus(ApiCallStatus.Error)
		})
	}

	const unauthorizedCalls = async () => {
		await Promise.all([
			fetchProductBySkuAsync()
		]);
	};

	const authorizedCalls = async () => {
		await Promise.all([
			fetchApplicationMakesAsync()
		]);
	};

	useEffect(() => {
		if (genericDataLoaded === true && isLoading === false) {
			if (isAuthenticated === false) {
				clearCookiesAsync();
				unauthorizedCalls();
			} else {
				authorizedCalls();
				unauthorizedCalls();
			}
		}
	}, [genericDataLoaded, isAuthenticated, isLoading]);

	useEffect(() => {
		if (productFetchCallStatus === ApiCallStatus.Succeeded) {
			fetchProductPriceAsync();
			fetchProductStockInfoAsync();
		}
	}, [productFetchCallStatus]);

	useEffect(() => {
		if (!!applicationFilters){
			if (!applicationFilters.make) {
				fetchApplicationMakesAsync();
			} else if (!!applicationFilters.make && !applicationFilters.model) {
				fetchApplicationModelsAsync();
			} else if (!!applicationFilters.make && !!applicationFilters.model && !applicationFilters.variant) {
				fetchApplicationVariantsAsync();
			} else if (!!applicationFilters.make && !!applicationFilters.model && !!applicationFilters.variant) {
				fetchApplicationYearsAsync();
			}
		}
	}, [applicationFilters]);

	useEffect(() => {
		if (fetchApplicationMakesCallStatus === ApiCallStatus.Succeeded) {
			setApplicationYears([]);
			setApplicationModels([]);
			setApplicationVariants([]);
		}
	}, [fetchApplicationMakesCallStatus]);

	useEffect(() => {
		if (fetchApplicationModelsCallStatus === ApiCallStatus.Succeeded) {
			setApplicationYears([]);
			setApplicationVariants([]);
		}
	}, [fetchApplicationModelsCallStatus]);

	useEffect(() => {
		if (fetchApplicationVariantsCallStatus === ApiCallStatus.Succeeded) {
			setApplicationYears([]);
		}
	}, [fetchApplicationVariantsCallStatus]);

	const allLoadingStates = [
		productFetchCallStatus,
		priceFetchCallStatus,
		stockInfoCallStatus
	];

	const applicationTabLoadingStates = [
		fetchApplicationMakesCallStatus,
		fetchApplicationModelsCallStatus,
		fetchApplicationVariantsCallStatus,
		fetchApplicationYearsCallStatus
	]

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;
	const applicationTabIsLoading = applicationTabLoadingStates.includes(ApiCallStatus.InProgress) || genericDataLoaded === false || isLoading === true;

	return (
		<Container maxWidth="lg">
			<Product
				product={productData}
				pricingData={pricingData}
				stockInfo={stockInfo}
				applicationMakes={applicationMakes}
				applicationModels={applicationModels}
				applicationVariants={applicationVariants}
				applicationYears={applicationYears}
				applicationFilters={applicationFilters}
				setApplicationFilters={setApplicationFilters}
				accountType={genericPageContent?.accountType ?? AccountType.Retail}
				loading={pageIsLoading}
				applicationTabIsLoading={applicationTabIsLoading}
				isAuthenticated={isAuthenticated}
			/>
			<Backdrop
				sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
				open={pageIsLoading}
			>
				<CircularProgress color="inherit"/>
			</Backdrop>
		</Container>
	);
};
export default ProductPage;
