import React, { useEffect, useState } from "react";
import Hero from "../Hero";
import VendorSlider from "../VendorSlider";
import SearchForParts from "../SearchForParts";
import FindProductsByCategory from "../FindProductsByCategory";
import { useNavigate } from "react-router-dom";

const Home = ({
	customerCategory,
	vendors,
	categoriesAndTypes,
	pageContent
}) => {
	const navigate = useNavigate();


	return (
		<div>
			<Hero/>
			<SearchForParts/>
			<FindProductsByCategory
				categoriesAndTypes={categoriesAndTypes}
				customerCategory={customerCategory}
			/>
			<VendorSlider vendors={vendors} />
		</div>
	);
};
export default Home;
