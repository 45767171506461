import {
	getFilters,
	getProductData,
	getProducts,
	getProductsForVehicle, getPromotionProductsByHandle, getStockInfo
} from "../../api/productController";
import {
	getSearchSuggestions,
	getVendors,
	search
} from "../../api/searchController";
import { take } from "lodash";
import { getProductCategoriesAndTypes } from "../../api/staticDataController";

export const fetchProductsData = async ({
	auth0Token,
	productCategory,
	productType,
	vendor,
	vehicleYearId,
	make,
	model,
	variant,
	year,
	pageNumber,
	setProducts,
	setAllProductIds,
	setTotal,
	setPageSize,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response;
		const processedProductCategory = productCategory === "all" ? null : productCategory;

		if (!!vehicleYearId) {
			response = await getProductsForVehicle({
				productCategory: processedProductCategory,
				productType,
				vendor,
				vehicleYearId,
				pageNumber,
				auth0Token
			});
		}
		else {
			response = await getProducts({
				productCategory: processedProductCategory,
				productType,
				vendor,
				make,
				model,
				variant,
				year,
				pageNumber,
				auth0Token
			});
		}

		if (!!response?.success) {
			const productsData = response.data.products.data;
			const allProductIds = response.data.allProductIds;
			const total = response.data.products.total;
			const limit = response.data.products.limit;

			setProducts(productsData);
			setAllProductIds(allProductIds);
			setTotal(total);
			setPageSize(limit);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchPromotionProductsData = async ({
	auth0Token,
	handle,
	pageNumber,
	setPromotionProducts,
	setTotal,
	setPageSize,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPromotionProductsByHandle({
			auth0Token,
			handle,
			pageNumber
		});

		if (!!response?.success) {
			const productsData = response.data.products.data;
			const total = response.data.products.total;
			const limit = response.data.products.limit;

			setPromotionProducts(productsData);
			setTotal(total);
			setPageSize(limit);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchProductsStockInfo = async ({
	auth0Token,
	productSkus,
	setStockInfo,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getStockInfo({
			productSkus,
			auth0Token
		});

		if (!!response?.success) {
			setStockInfo(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchFiltersData = async ({
	auth0Token,
	allProductIds,
	setFilters,
	currentProductCategory,
	currentProductType,
	currentVendor,
	currentMake,
	currentModel,
	currentVariant,
	currentYear,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		if (!allProductIds || allProductIds.length === 0) {
			setStatusFailed();
			return;
		}

		if (currentProductCategory === "all") currentProductCategory = null;

		const response = await getFilters({
			productIds: allProductIds,
			currentProductCategory,
			currentProductType,
			currentVendor,
			currentMake,
			currentModel,
			currentVariant,
			currentYear,
			auth0Token
		});

		if (!!response?.success) {
			setFilters(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchProductBySku = async ({
	auth0Token,
	productSku,
	setProductData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const productSkus = [productSku];
		const response = await getProductData(productSkus, auth0Token);
		if (response.success) {
			if (response.data.length > 0) {
				setProductData(response.data[0]);
			}
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchProductsBySkus = async ({
	auth0Token,
	productSkus,
	setProductData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getProductData(productSkus, auth0Token);
		if (response.success) {
			if (response.data.length > 0) {
				setProductData(response.data);
			}
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const performSearch = async ({
	searchTerm,
	setProductTypeSearchResults,
	setSearchTermSearchResults,
	accessories,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await search(searchTerm, accessories);
		if (response.success) {
			setProductTypeSearchResults(response.data.productTypeSearchResults);
			setSearchTermSearchResults(take(response.data.searchStringResults, 10));
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchVendorsData = async ({
	setVendorData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getVendors();
		if (response.success) {
			setVendorData(response.data);
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchCategoriesAndTypes = async ({
	setCategoriesAndTypesData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getProductCategoriesAndTypes();
		if (response.success) {
			setCategoriesAndTypesData(response.data);
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
}

export const fetchSearchSuggestions = async ({
	searchTerm,
	category,
	setSearchResults,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError

}) => {
	setStatusInProgress();
	try {
		const response = await getSearchSuggestions(searchTerm, category);
		if (response.success) {
			setSearchResults(response.data);
			setStatusSuccess();
		} else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};