import React, { useEffect, useState } from "react";
import { DeliveryMethod, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { cloneDeep } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import { Box, Button, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import StoreIcon from "@mui/icons-material/Store";
import PageHeader from "../PageHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CheckoutDeliveryMethod = ({
	checkoutData,
	chosenLogisticsMethod,
	setChosenLogisticsMethod,
	onSubmit
}) => {
	const {screenSize} = useSystemHook();
	const [showError, setShowError] = useState(false);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		scrollTop();
	}, []);

	const handleDeliveryMethodClick = async (deliveryMethod) => {
		let logisticsMethod;
		if (deliveryMethod === DeliveryMethod.Ship) {
			logisticsMethod = LogisticsMethod.Ship;
		}
		else if (deliveryMethod === DeliveryMethod.Collection) {
			logisticsMethod = LogisticsMethod.Collection;
		}

		setShowError(false);
		setChosenLogisticsMethod(logisticsMethod);
	};

	const onSaveClick = async () => {
		if (chosenLogisticsMethod) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.logisticsMethod = chosenLogisticsMethod;
			checkoutDataClone.shippingAddressId = null;
			checkoutDataClone.collectionAddressId = null;

			await onSubmit({
				checkoutData: checkoutDataClone
			});
		}
	};

	let showAsCurrent = null;
	if (chosenLogisticsMethod) {
		showAsCurrent = chosenLogisticsMethod;
	}
	else if (checkoutData?.logisticsMethod) {
		showAsCurrent = checkoutData.logisticsMethod;
	}

	return (
		<>
			<PageHeader title="Checkout" />
			{!!checkoutData && (
				<>
					{screenSize === ScreenSize.Desktop &&
						<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: 2 }}>
							<Grid container spacing={4}>
								<Grid item xs={12} md={9}>
									{showError && (
										<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem', backgroundColor: '#ffe6e6', color: '#d32f2f' }}>
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<ErrorOutlineIcon fontSize="small"/> Something went wrong
											</Typography>
											<Typography variant="caption">Please choose a delivery method</Typography>
										</Paper>
									)}

									<Paper elevation={3} sx={{ padding: '1rem', marginBottom: '1rem' }}>
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<LocalShippingIcon fontSize="small" /> Delivery
											</Typography>
											<Button
												size="small"
												disabled={chosenLogisticsMethod === LogisticsMethod.Ship}
												variant={chosenLogisticsMethod === LogisticsMethod.Ship ? 'contained' : 'outlined'}
												color={chosenLogisticsMethod === LogisticsMethod.Ship ? 'success' : 'error'}
												onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}
												startIcon={chosenLogisticsMethod === LogisticsMethod.Ship && <CheckIcon />}
											>
												{chosenLogisticsMethod === LogisticsMethod.Ship ? 'Selected' : 'Choose'}
											</Button>
										</Box>
										<Divider sx={{ margin: '1rem 0' }} />
										<Typography variant="caption" component="p">
											Orders received Monday to Thursday before 9am will be dispatched the same day.
										</Typography>
										<Typography variant="caption" component="p" sx={{ marginTop: '0.5rem' }}>
											Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.
										</Typography>
										<Typography variant="caption" component="p" sx={{ marginTop: '0.5rem' }}>
											Delivery typically takes between 2 and 3 working days to all main centers.
										</Typography>
										<Typography variant="caption" component="p" sx={{ marginTop: '0.5rem' }}>
											Smaller cities and rural areas may take 5 to 7 working days.
										</Typography>
									</Paper>

									<Paper elevation={3} sx={{ padding: '1rem' }}>
										<Box display="flex" justifyContent="space-between" alignItems="center">
											<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
												<StoreIcon fontSize="small" /> Collection
											</Typography>
											<Button
												size="small"
												disabled={chosenLogisticsMethod === LogisticsMethod.Collection}
												variant={chosenLogisticsMethod === LogisticsMethod.Collection ? 'contained' : 'outlined'}
												color={chosenLogisticsMethod === LogisticsMethod.Collection ? 'success' : 'error'}
												onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}
												startIcon={chosenLogisticsMethod === LogisticsMethod.Collection && <CheckIcon />}
											>
												{chosenLogisticsMethod === LogisticsMethod.Collection ? 'Selected' : 'Choose'}
											</Button>
										</Box>
										<Divider sx={{ margin: '1rem 0' }} />
										<Typography variant="caption" component="p">
											Orders are generally ready within 60 minutes from confirmation of payment.
										</Typography>
										<Box sx={{ marginTop: '1rem' }}>
											<Typography variant="caption" component="p"><b>Collection address:</b></Typography>
											<Typography variant="caption" component="p">28 Great North Road, Brentwood Park, Benoni, 1501</Typography>
										</Box>
										<Box sx={{ marginTop: '1rem' }}>
											<Typography variant="caption" component="p"><b>Collection times:</b></Typography>
											<Typography variant="caption" component="p">Mon-Thu 8:00am-4:30pm</Typography>
											<Typography variant="caption" component="p">Fri: 8:00am-1:30pm</Typography>
										</Box>
									</Paper>
								</Grid>
								<Grid item xs={12} md={3}>
									<CartSummary
										vat={checkoutData.vatPercentage}
										cartNettTotal={checkoutData.subtotal}
										settlementAmount={checkoutData.settlementAmount}
										vatAmount={checkoutData.vatAmount}
										total={checkoutData.total}
										sx={{ marginBottom: '2rem' }}
										buttonText="Continue"
										buttonDisabled={!chosenLogisticsMethod}
										onButtonClick={onSaveClick}
									/>
								</Grid>
							</Grid>
						</Box>
					}
					{screenSize === ScreenSize.Mobile &&
						<Box sx={{ backgroundColor: '#f9f9f9', minHeight: '50vh', padding: 1 }}>
							{showError && (
								<Paper elevation={2} sx={{ padding: '1rem', backgroundColor: '#ffe6e6', color: '#d32f2f', marginBottom: '1rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<ErrorOutlineIcon fontSize="small" /> Something went wrong
									</Typography>
									<Typography variant="caption">Please choose a delivery method</Typography>
								</Paper>
							)}

							<Paper elevation={2} sx={{ padding: '1rem', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<LocalShippingIcon fontSize="small" /> Delivery
									</Typography>
									{chosenLogisticsMethod === LogisticsMethod.Ship &&
										<IconButton color="success">
											<CheckCircleIcon fontSize="small" />
										</IconButton>
									}
									{chosenLogisticsMethod !== LogisticsMethod.Ship &&
										<Button size="small"
										        variant="contained"
										        color="error"
										        onClick={() => handleDeliveryMethodClick(DeliveryMethod.Ship)}>
											Choose
										</Button>
									}
								</Box>
								<Divider sx={{ margin: '1rem 0' }} />
								<Typography variant="caption">
									Orders received Monday to Thursday before 9am will be dispatched the same day.
								</Typography>
								<Typography variant="caption" sx={{ marginTop: '0.5rem' }}>
									Orders received after 9am on Friday to Sunday will be dispatched on the following Monday.
								</Typography>
								<Typography variant="caption" sx={{ marginTop: '0.5rem' }}>
									Delivery typically takes between 2 and 3 working days to all main centers.
								</Typography>
								<Typography variant="caption" sx={{ marginTop: '0.5rem' }}>
									Smaller cities and rural areas may take 5 to 7 working days.
								</Typography>
							</Paper>

							<Paper elevation={2} sx={{ padding: '1rem', marginBottom: '1rem' }}>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
										<StoreIcon fontSize="small" /> Collection
									</Typography>
									{chosenLogisticsMethod === LogisticsMethod.Collection &&
										<IconButton color="success">
											<CheckCircleIcon fontSize="small" />
										</IconButton>
									}
									{chosenLogisticsMethod !== LogisticsMethod.Collection &&
										<Button size="small"
										        variant="contained"
										        color="error"
										        onClick={() => handleDeliveryMethodClick(DeliveryMethod.Collection)}>
											Choose
										</Button>
									}
								</Box>
								<Divider sx={{ margin: '1rem 0' }} />
								<Typography variant="caption">
									Orders are generally ready within 60 minutes from confirmation of payment.
								</Typography>
								<Box sx={{ marginTop: '1rem' }}>
									<Typography variant="caption"><b>Collection address:</b></Typography>
									<Typography variant="caption">28 Great North Road, Brentwood Park, Benoni, 1501</Typography>
								</Box>
								<Box sx={{ marginTop: '1rem' }}>
									<Typography variant="caption"><b>Collection times:</b></Typography>
									<Typography variant="caption">Mon-Thu 8:00am-4:30pm</Typography>
									<Typography variant="caption">Fri: 8:00am-1:30pm</Typography>
								</Box>
							</Paper>

							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								buttonText="Continue"
								buttonDisabled={!chosenLogisticsMethod}
								onButtonClick={onSaveClick}
							/>
						</Box>
					}
				</>
			)}
		</>
	);
};

export default CheckoutDeliveryMethod;
