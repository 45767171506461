import { Box, Button, IconButton, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { AnnouncementType, ScreenSize } from "../../utils/constants";
import useSystemHook from "../../hooks/SystemHook";

const desktopModalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: 24,
	bgcolor: 'background.paper',
	padding: 2,
	width: '60vw', // Increased width
	height: '80vh', // Increased height
	overflowY: 'auto',
	borderRadius: 2,
};

const desktopImageStyle = {
	maxWidth: '90%', // Reduced to fit better in the increased modal size
	height: 'auto',
	marginBottom: '12px', // Increased margin for spacing
};

const desktopButtonContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	gap: 2,
	marginTop: '24px',
};

const mobileModalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: 24,
	bgcolor: 'background.paper',
	padding: 2,
	width: '90vw', // Mobile-friendly width
	height: 'auto', // Auto height for better content fitting
	maxHeight: '90vh', // Max height to allow scrolling
	overflowY: 'auto',
	borderRadius: 2,
};

const mobileImageStyle = {
	maxWidth: '100%',
	height: 'auto',
	marginBottom: '16px',
};

const mobileButtonContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	gap: 1,
	marginTop: '16px',
	flexDirection: 'column',
};

const AnnouncementModal = ({
	open,
	setClose,
	type,
	image,
	gotoLink,
	announcementId,
	markAnnouncementAsSeen,
	markAnnouncementAsAcknowledged
}) => {
	const { screenSize } = useSystemHook();

	const closeModal = () => {
		setClose();
	};

	const handleAcknowledge = async () => {
		closeModal();
		await markAnnouncementAsAcknowledged(announcementId);
	};

	const handleSeen = async () => {
		closeModal();
		await markAnnouncementAsSeen(announcementId);
	};

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Modal
					open={open}
					onClose={closeModal}
					disableBackdropClick
					disableEscapeKeyDown
					aria-labelledby="announcement-modal-title"
					aria-describedby="announcement-modal-description"
				>
					<Box sx={desktopModalStyle} className="modalContent">
						<IconButton
							onClick={handleSeen}
							sx={{
								position: 'absolute',
								top: 8,
								right: 8,
								color: 'text.secondary',
							}}
						>
							<CloseIcon />
						</IconButton>

						<Box component="img" src={image} alt="Announcement" sx={desktopImageStyle} />

						<Box sx={desktopButtonContainerStyle}>
							{type === AnnouncementType.Acknowledgement && (
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleAcknowledge}
								>
									Acknowledge
								</Button>
							)}
							{type === AnnouncementType.Promotional && (
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={gotoLink}
								>
									View
								</Button>
							)}
						</Box>
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Mobile &&
				<Modal
					open={open}
					onClose={closeModal}
					disableBackdropClick
					disableEscapeKeyDown
					aria-labelledby="announcement-modal-title"
					aria-describedby="announcement-modal-description"
				>
					<Box sx={mobileModalStyle} className="modalContent">
						<IconButton
							onClick={handleSeen}
							sx={{
								position: 'absolute',
								top: 8,
								right: 8,
								color: 'text.secondary',
							}}
						>
							<CloseIcon fontSize="small" />
						</IconButton>

						<Box component="img" src={image} alt="Announcement" sx={mobileImageStyle} />

						<Box sx={mobileButtonContainerStyle}>
							{type === AnnouncementType.Acknowledgement && (
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleAcknowledge}
								>
									Acknowledge
								</Button>
							)}
							{type === AnnouncementType.Promotional && (
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={gotoLink}
								>
									View
								</Button>
							)}
						</Box>
					</Box>
				</Modal>
			}
		</>
	)
};

export default AnnouncementModal;
