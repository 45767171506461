import React from 'react';
import {
	Avatar,
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	IconButton, InputAdornment,
	Link,
	MenuItem,
	Paper,
	Select,
	Tooltip,
	Typography
} from "@mui/material";
import { SouthAfricanRand } from "../../utils/helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { CustomerCategory } from "../../utils/constants";
import { CartSummary } from "../CartSummary";
import TextField from "@mui/material/TextField";

const DesktopCart = ({
	loading,
	onBackClick,
	items,
	pricingData,
	customerCategory,
	onRemoveItemClick,
	total,
	cartNettTotal,
	settlementAmount,
	vat,
	vatAmount,
	clearCartModalOpen,
	handleClearCartModalClose,
	handleClearCart,
	goToCheckout,
	updateItemQuantity,
	handleQuantityChange,
	errorMessages,
	setErrorMessages,
	handleDecrement,
	handleIncrement
}) => {
	return (
		<>
			{loading === false &&
				<Box sx={{ padding: '1rem', minHeight: '60vh' }}>
					{!!items && !!pricingData && items.length > 0 ? (
						<Grid container spacing={4}>
							<Grid item xs={12} md={9}>
								{items.map((item, index) => {
									const productLink = `/product/${item.sku}`;
									const itemPricingData = pricingData.find((p) => p.sku === item.sku);

									return (
										<Paper elevation={4} sx={{ padding: '1rem', marginBottom: '1.5rem', borderRadius: 2 }} key={item.sku}>
											<Grid container alignItems="center" spacing={2}>
												<Grid item xs={12} md={2}>
													<Link href={productLink}>
														<Avatar
															src={item.image}
															alt={item.name}
															variant="square"
															sx={{ width: 80, height: 80, margin: '0 auto' }}
														/>
													</Link>
												</Grid>
												<Grid item xs={12} md={4}>
													<Link href={productLink} underline="hover">
														<Typography variant="body2" fontWeight="bold" color="textPrimary">
															{item.name}
														</Typography>
													</Link>
													<Typography variant="caption" color="textSecondary">
														SKU: {item.sku}
													</Typography>
												</Grid>
												<Grid item xs={4} md={2} textAlign="center">
													<Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem' }}>
														Qty
													</Typography>
													<TextField
														variant="outlined"
														type="number"
														value={item.quantity}
														size="small"
														onChange={(e) => handleQuantityChange(item.id, item.sku, e.target.value)}
														inputProps={{
															min: 1,
															max: 500,
															step: 1,
															'aria-label': 'quantity',
															style: { textAlign: 'center', fontSize: '0.875rem' },
														}}
														error={!!errorMessages[item.sku]}
														helperText={errorMessages[item.sku]}
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<IconButton
																		onClick={() => handleDecrement(item.id, item.sku, item.quantity)}
																		disabled={item.quantity <= 1}
																		sx={{ padding: 0 }}
																	>
																		<RemoveIcon fontSize="small" />
																	</IconButton>
																</InputAdornment>
															),
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		onClick={() => handleIncrement(item.id, item.sku, item.quantity)}
																		disabled={item.quantity >= 500}
																		sx={{ padding: 0 }}
																	>
																		<AddIcon fontSize="small" />
																	</IconButton>
																</InputAdornment>
															),
														}}
													/>
												</Grid>
												<Grid item xs={8} md={3} textAlign="right">
													<Typography variant="body2" fontWeight="bold">
														{SouthAfricanRand.format(itemPricingData.price * item.quantity)}
													</Typography>
													{itemPricingData.invoicePrice > 0 && itemPricingData.invoicePrice !== itemPricingData.price && (
														<Typography component="p" variant="caption">
															Invoice: {SouthAfricanRand.format(itemPricingData.invoicePrice * item.quantity)}
														</Typography>
													)}
													{customerCategory !== CustomerCategory.Retail && (
														<Typography component="p" variant="caption">
															Ex. VAT
														</Typography>
													)}
												</Grid>
												<Grid item xs={12} md={1} textAlign="center">
													<Tooltip title="Remove item" arrow>
														<IconButton
															color="error"
															onClick={() => onRemoveItemClick(item.sku)}
														>
															<DeleteIcon fontSize="small" />
														</IconButton>
													</Tooltip>
												</Grid>
											</Grid>
										</Paper>
									);
								})}
							</Grid>
							<Grid item xs={12} md={3}>
								<CartSummary
									total={total}
									cartNettTotal={cartNettTotal}
									settlementAmount={settlementAmount}
									vat={vat}
									vatAmount={vatAmount}
									buttonText="Checkout"
									onButtonClick={goToCheckout}
								/>
							</Grid>
						</Grid>
					) : (
						<Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
							<Typography variant="h6">Your cart is empty</Typography>
							<Button
								size="small"
								variant="contained"
								color="error"
								onClick={onBackClick}
								sx={{ marginTop: '1rem' }}
							>
								Back to Shopping
							</Button>
						</Box>
					)}
				</Box>
			}
		</>
	)
}

export default DesktopCart;