import React, { useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import "./style.css";
import GoogleLogo from "../../img/google_on_white.png";
import { CircularProgress } from "@mui/material";

const GOOGLE_MAPS_API_KEY =
	process.env.REACT_GOOGLE_MAPS_API_KEY ??
	"AIzaSyB4Lu-jVIbxq2RU5lu6jeThdCR2wPdmHb4";

const loadScript = (src, position, id) => {
	if (!position) {
		return;
	}

	const script = document.createElement("script");
	script.setAttribute("async", "");
	script.setAttribute("id", id);
	script.src = src;
	position.appendChild(script);
};

const autocompleteService = {current: null};

const GoogleAutoComplete = ({value, setValue, loading}) => {
	const [inputValue, setInputValue] = useState("");
	const [options, setOptions] = useState([]);
	const loaded = useRef(false);
	let sessionToken;

	if (typeof window !== "undefined" && !loaded.current) {
		if (!document.querySelector("#google-maps")) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector("head"),
				"google-maps"
			);
		}

		loaded.current = true;
	}

	const fetch = useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 600),
		[]
	);

	useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
			sessionToken = new window.google.maps.places.AutocompleteSessionToken();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === "") {
			setOptions(value ? [value] : []);
			return undefined;
		}

		fetch({input: inputValue, sessionToken}, (results) => {
			if (active) {
				let newOptions = [];

				if (value) {
					newOptions = [value];
				}

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<>
			<Box sx={{ width: '100%', padding: '1rem 0' }}>
				<Grid container>
					<Grid item xs={12}>
						<Autocomplete
							id="address-autocomplete"
							sx={{ width: '100%' }}
							size="small"
							getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
							filterOptions={(x) => x}
							options={options}
							autoComplete
							loading={loading}
							includeInputInList
							filterSelectedOptions
							value={value}
							noOptionsText="No locations"
							onChange={(event, newValue) => {
								setOptions(newValue ? [newValue, ...options] : options);
								setValue(newValue);
							}}
							onInputChange={(event, newInputValue) => {
								setInputValue(newInputValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Address"
									variant="outlined"
									fullWidth
									size="small"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loading ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
							renderOption={(props, option) => {
								const matches = option.structured_formatting.main_text_matched_substrings || [];
								const parts = parse(
									option.structured_formatting.main_text,
									matches.map((match) => [match.offset, match.offset + match.length])
								);

								return (
									<li {...props}>
										<Grid container alignItems="center">
											<Grid item sx={{ width: '100%', wordWrap: 'break-word' }}>
												{parts.map((part, index) => (
													<Box
														key={index}
														component="span"
														sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
													>
														{part.text}
													</Box>
												))}
												<Typography variant="body2" color="textSecondary">
													{option.structured_formatting.secondary_text}
												</Typography>
											</Grid>
										</Grid>
									</li>
								);
							}}
						/>
					</Grid>
				</Grid>

				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
					<Typography variant="body2" className="google-font" sx={{ marginRight: '0.5rem' }}>
						Powered by
					</Typography>
					<Box component="img" src={GoogleLogo} alt="Google" sx={{ width: '50px' }} />
				</Box>
			</Box>
		</>
	);
};

export default GoogleAutoComplete;
