import { PricingOutput } from "../../utils/constants";
import { getPrices, getStockInfo } from "../../api/productController";
import { getCheckoutLogisticsFeeData } from "../../api/checkoutController";

export const fetchProductPrice = async ({
	auth0Token,
	productSku,
	setPricingData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	const productSkus = [productSku];
	await fetchProductsPrices({
		productSkus,
		auth0Token,
		setPricingData,
		setStatusInProgress,
		setStatusSuccess,
		setStatusFailed,
		setStatusError
	});
};

export const fetchProductsPrices = async ({
	auth0Token,
	productSkus,
	setPricingData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getPrices({
			productSkus,
			output: PricingOutput.Product,
			auth0Token
		});

		if (!!response?.success) {
			setPricingData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchCartPriceData = async ({
	auth0Token,
	productSkus,
	setPricingData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getPrices({
			productSkus,
			output: PricingOutput.Cart,
			auth0Token
		});

		if (!!response?.success) {
			setPricingData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchCartSummaryPriceData = async ({
	auth0Token,
	productSkus,
	setCartSummaryPricingData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getPrices({
			productSkus,
			output: PricingOutput.CartSummary,
			auth0Token
		});

		if (!!response?.success) {
			setCartSummaryPricingData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};

export const fetchCheckoutLogisticsFeeData = async ({
	auth0Token,
	subtotal,
	setLogisticsFeeData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		let response = await getCheckoutLogisticsFeeData(subtotal, auth0Token);
		if (!!response?.success) {
			setLogisticsFeeData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (error) {
		console.error(error);
		setStatusError();
	}
};
