import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Typography } from "@mui/material";
import {
	fetchManufacturers,
	fetchModels,
	fetchVariants,
	fetchYears
} from "../../main-component/PageFunctions/vehicleFunctions";
import { ApiCallStatus, ScreenSize } from "../../utils/constants";
import { createUserVehicle } from "../../main-component/PageFunctions/userVehicleFunctions";
import { fetchVehicleDetails } from "../../api/vehicleController";
import { useAuth0 } from "@auth0/auth0-react";
import useSystemHook from "../../hooks/SystemHook";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { cloneDeep } from "lodash";

export const confirmationChoices = {
	cancel: "cancel",
	confirm: "confirm"
};

const desktopStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "35%", // Wider for desktop view
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
};

const tabletStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 3,
};

const mobileStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	bgcolor: "#FFFFFF",
	boxShadow: 24,
	p: 2,
};

const ChooseVehicleModal = ({
	open,
	setClose,
	openSavedVehiclesModal,
	createUserVehicleAsync
}) => {
	const {screenSize} = useSystemHook();
	const [yearData, setYearData] = useState([]);
	const [manufacturerData, setManufacturerData] = useState([]);
	const [modelData, setModelData] = useState([]);
	const [variantData, setVariantData] = useState([]);

	const [yearOptions, setYearOptions] = useState([]);
	const [manufacturerOptions, setManufacturerOptions] = useState([]);
	const [modelOptions, setModelOptions] = useState([]);
	const [variantOptions, setVariantOptions] = useState([]);
	const [userInput, setUserInput] = useState({
		manufacturer: "",
		model: "",
		variant: "",
		year: ""
	});
	const [vehicleToSave, setVehicleToSave] = useState(null);

	const [fetchVehicleManufacturersCallStatus, setFetchVehicleManufacturersCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVehicleModelsCallStatus, setFetchVehicleModelsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVehicleYearsCallStatus, setFetchVehicleYearsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVehicleVariantsCallStatus, setFetchVehicleVariantsCallStatus] = useState(ApiCallStatus.NotStarted);
	const [fetchVehicleDetailsCallStatus, setFetchVehicleDetailsCallStatus] = useState(ApiCallStatus.NotStarted);

	const fetchVehicleMakesAsync = async () => {
		await fetchManufacturers({
			year: userInput.year,
			setManufacturerData,
			setStatusInProgress: () => setFetchVehicleManufacturersCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVehicleManufacturersCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVehicleManufacturersCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVehicleManufacturersCallStatus(ApiCallStatus.Error),
		});
	};

	const fetchVehicleModelsAsync = async () => {
		await fetchModels({
			manufacturer: userInput.manufacturer,
			year: userInput.year,
			setModelData,
			setStatusInProgress: () => setFetchVehicleModelsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVehicleModelsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVehicleModelsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVehicleModelsCallStatus(ApiCallStatus.Error),
		});
	};

	const fetchVehicleYearsAsync = async () => {
		await fetchYears({
			manufacturer: userInput.manufacturer,
			model: userInput.model,
			variant: userInput.variant,
			setYearData,
			setStatusInProgress: () => setFetchVehicleYearsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVehicleYearsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVehicleYearsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVehicleYearsCallStatus(ApiCallStatus.Error),
		});
	};

	const fetchVehicleVariantsAsync = async () => {
		await fetchVariants({
			manufacturer: userInput.manufacturer,
			model: userInput.model,
			year: userInput.year,
			setVariantData,
			setStatusInProgress: () => setFetchVehicleVariantsCallStatus(ApiCallStatus.InProgress),
			setStatusSuccess: () => setFetchVehicleVariantsCallStatus(ApiCallStatus.Succeeded),
			setStatusFailed: () => setFetchVehicleVariantsCallStatus(ApiCallStatus.Failed),
			setStatusError: () => setFetchVehicleVariantsCallStatus(ApiCallStatus.Error),
		});
	};

	const handleUserVehicleChange = (name) => (event, newValue) => {
		let userInputCopy = cloneDeep(userInput);
		userInputCopy[name] = newValue;

		if (name === "manufacturer") {
			userInputCopy.model = "";
			userInputCopy.variant = "";
			userInputCopy.year = "";
		}
		else if (name === "model") {
			userInputCopy.variant = "";
			userInputCopy.year = "";
		}
		else if (name === "variant") {
			userInputCopy.year = "";
		}
		setUserInput(userInputCopy);
	};

	const closeModal = () => {
		openSavedVehiclesModal();
	};

	const handleModalClose = (confirmationChoice) => async () => {
		const shouldSaveVehicle =
			confirmationChoices.confirm === confirmationChoice;

		if (!!shouldSaveVehicle) {
			await fetchVehicleDetails({
				make: userInput.manufacturer,
				model: userInput.model,
				variant: userInput.variant,
				year: userInput.year,
				setVehicleToSave,
				setStatusInProgress: () => setFetchVehicleDetailsCallStatus(ApiCallStatus.InProgress),
				setStatusSuccess: () => setFetchVehicleDetailsCallStatus(ApiCallStatus.Succeeded),
				setStatusFailed: () => setFetchVehicleDetailsCallStatus(ApiCallStatus.Failed),
				setStatusError: () => setFetchVehicleDetailsCallStatus(ApiCallStatus.Error)
			});
		}

		if (confirmationChoice === confirmationChoices.cancel) {
			closeModal();
		}
	};

	useEffect(() => {
		let callsToMake = [
			fetchVehicleMakesAsync(),
			fetchVehicleYearsAsync()
		];

		if (userInput.manufacturer || userInput.year) {
			callsToMake.push(fetchVehicleModelsAsync());
		}

		if (userInput.manufacturer || userInput.model) {
			callsToMake.push(fetchVehicleVariantsAsync());
		}

		Promise.all(callsToMake);
	}, [userInput]);

	useEffect(() => {
		if (fetchVehicleManufacturersCallStatus === ApiCallStatus.Succeeded && !!manufacturerData) {
			const sortedManufacturers = manufacturerData.sort();
			setManufacturerOptions(sortedManufacturers);
		}
	}, [fetchVehicleManufacturersCallStatus, manufacturerData]);

	useEffect(() => {
		if (fetchVehicleModelsCallStatus === ApiCallStatus.Succeeded && !!modelData) {
			const sortedModels = modelData.sort();
			setModelOptions(sortedModels);
		}
	}, [fetchVehicleModelsCallStatus, modelData]);

	useEffect(() => {
		if (fetchVehicleYearsCallStatus === ApiCallStatus.Succeeded && !!yearData) {
			const sortedYears = yearData.sort();
			setYearOptions(sortedYears);
		}
	}, [fetchVehicleYearsCallStatus, yearData]);

	useEffect(() => {
		if (fetchVehicleVariantsCallStatus === ApiCallStatus.Succeeded && !!variantData) {
			const sortedVariants = variantData.sort();
			setVariantOptions(sortedVariants);
		}
	}, [fetchVehicleVariantsCallStatus, variantData]);

	useEffect(() => {
		if (fetchVehicleDetailsCallStatus === ApiCallStatus.Succeeded && !!vehicleToSave) {
			if (!vehicleToSave) {
				return;
			}

			createUserVehicleAsync(vehicleToSave);
		}
	}, [vehicleToSave, fetchVehicleDetailsCallStatus]);


	const allLoadingStates = [
		fetchVehicleManufacturersCallStatus,
		fetchVehicleModelsCallStatus,
		fetchVehicleYearsCallStatus,
		fetchVehicleVariantsCallStatus,
		fetchVehicleDetailsCallStatus
	];

	const pageIsLoading = allLoadingStates.includes(ApiCallStatus.InProgress);

	return (
		<>
			{screenSize === ScreenSize.Desktop &&
				<Modal open={open} onClose={handleModalClose(confirmationChoices.cancel)}>
					<Box sx={desktopStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								Choose your vehicle
							</Typography>
							<IconButton onClick={handleModalClose(confirmationChoices.cancel)} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Typography variant="caption" sx={{ mt: 1, color: "#555" }}>
							Specify the make, model, variant, and year to get the most accurate parts for your vehicle.
						</Typography>

						<Box component="form" sx={{ mt: 3 }}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.manufacturer}
										onChange={handleUserVehicleChange("manufacturer")}
										options={manufacturerOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Make"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={6}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.model}
										onChange={handleUserVehicleChange("model")}
										options={modelOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Model"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={6}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.variant}
										onChange={handleUserVehicleChange("variant")}
										options={variantOptions}
										getOptionLabel={(option) => option}
										disabled={(!userInput.manufacturer && !userInput.model) || pageIsLoading}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Variant"
												variant="outlined"

												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={6}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.year}
										onChange={handleUserVehicleChange("year")}
										options={yearOptions}
										getOptionLabel={(option) => option}
										size="small"
										disabled={
											(!userInput.manufacturer &&
												!userInput.model &&
												!userInput.variant) || pageIsLoading
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Year"
												variant="outlined"

												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>
							</Grid>
						</Box>

						<Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleModalClose(confirmationChoices.confirm)}
									disabled={pageIsLoading}
								>
									{pageIsLoading ? <CircularProgress size={24} /> : "Save"}
								</Button>
							</Grid>
							<Grid item>
								<Button onClick={handleModalClose(confirmationChoices.cancel)} size="small">Cancel</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
			{screenSize === ScreenSize.Tablet &&
				<Modal open={open} onClose={handleModalClose(confirmationChoices.cancel)}>
					<Box sx={tabletStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								Choose your vehicle
							</Typography>
							<IconButton onClick={handleModalClose(confirmationChoices.cancel)} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Typography variant="caption" sx={{ mt: 1, color: "#555" }}>
							Specify the make, model, variant, and year to get the most accurate parts for your vehicle.
						</Typography>

						<Box component="form" sx={{ mt: 3 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.manufacturer}
										onChange={handleUserVehicleChange("manufacturer")}
										options={manufacturerOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Make"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.model}
										onChange={handleUserVehicleChange("model")}
										options={modelOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Model"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.variant}
										onChange={handleUserVehicleChange("variant")}
										options={variantOptions}
										getOptionLabel={(option) => option}
										disabled={(!userInput.manufacturer && !userInput.model) || pageIsLoading}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Variant"
												variant="outlined"

												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.year}
										onChange={handleUserVehicleChange("year")}
										options={yearOptions}
										getOptionLabel={(option) => option}
										size="small"
										disabled={
											(!userInput.manufacturer &&
												!userInput.model &&
												!userInput.variant) || pageIsLoading
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Year"
												variant="outlined"

												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>
							</Grid>
						</Box>

						<Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleModalClose(confirmationChoices.confirm)}
									disabled={pageIsLoading}
								>
									{pageIsLoading ? <CircularProgress size={24} /> : "Save"}
								</Button>
							</Grid>
							<Grid item>
								<Button onClick={handleModalClose(confirmationChoices.cancel)} size="small" variant="text">Cancel</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>

			}
			{screenSize === ScreenSize.Mobile &&
				<Modal open={open} onClose={handleModalClose(confirmationChoices.cancel)}>
					<Box sx={mobileStyle}>
						<Grid container justifyContent="space-between" alignItems="center" sx={{ paddingBottom: 2 }}>
							<Typography variant="body2" fontWeight="bold">
								Choose your vehicle
							</Typography>
							<IconButton onClick={handleModalClose(confirmationChoices.cancel)} size="small">
								<CloseIcon fontSize="small" />
							</IconButton>
						</Grid>

						<Typography variant="caption" sx={{ mt: 1, color: "#555" }}>
							Specify the make, model, variant, and year to get the most accurate parts for your vehicle.
						</Typography>

						<Box component="form" sx={{ mt: 3 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.manufacturer}
										onChange={handleUserVehicleChange("manufacturer")}
										options={manufacturerOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Make"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										disabled={pageIsLoading}
										value={userInput.model}
										onChange={handleUserVehicleChange("model")}
										options={modelOptions}
										getOptionLabel={(option) => option}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Model"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.variant}
										onChange={handleUserVehicleChange("variant")}
										options={variantOptions}
										getOptionLabel={(option) => option}
										disabled={(!userInput.manufacturer && !userInput.model) || pageIsLoading}
										size="small"
										renderInput={(params) => (
											<TextField
												{...params}
												label="Variant"
												variant="outlined"

												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>

								<Grid item xs={12}>
									<Autocomplete
										loading={pageIsLoading}
										value={userInput.year}
										onChange={handleUserVehicleChange("year")}
										options={yearOptions}
										getOptionLabel={(option) => option}
										size="small"
										disabled={
											(!userInput.manufacturer &&
												!userInput.model &&
												!userInput.variant) || pageIsLoading
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Year"
												variant="outlined"
												InputLabelProps={{ style: { color: '#000000' } }}
												InputProps={{
													...params.InputProps,
													style: { color: '#000000' },
												}}
												sx={{
													'& .MuiOutlinedInput-root': {
														'& fieldset': {
															borderColor: '#000000',
														},
														'&:hover fieldset': {
															borderColor: '#000000',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#000000',
														},
													},
												}}
											/>
										)}
										clearOnEscape
										isOptionEqualToValue={(option, value) => option === value}
									/>
								</Grid>
							</Grid>
						</Box>

						<Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
							<Grid item>
								<Button
									variant="contained"
									color="error"
									size="small"
									onClick={handleModalClose(confirmationChoices.confirm)}
									disabled={pageIsLoading}
								>
									{pageIsLoading ? <CircularProgress size={24} /> : "Save"}
								</Button>
							</Grid>
							<Grid item>
								<Button onClick={handleModalClose(confirmationChoices.cancel)} size="small">Cancel</Button>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			}
		</>
	);
};
export default ChooseVehicleModal;
