import React, { useEffect, useState } from "react";
import { cloneDeep, compact, find, join, trim } from "lodash";
import { CartSummary } from "../CartSummary";
import { useNavigate } from "react-router-dom";
import useSystemHook from "../../hooks/SystemHook";
import { ScreenSize } from "../../utils/constants";
import {
	Box,
	Button, Divider,
	Grid, IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import PageHeader from "../PageHeader";
import AddIcon from "@mui/icons-material/Add";

const CheckoutAddressUi = {
	firstLoad: -1,
	noAddressOptions: 0,
	addressDropdown: 1,
	addressChosen: 3,
	error: 4
};

const CheckoutShippingAddress = ({
	checkoutData,
	countries,
	provinces,
	userAddresses,
	onSubmit,
	gotoCreateAddressPage
}) => {
	const {screenSize} = useSystemHook();
	const [currentUiState, setCurrentUiState] = useState(
		CheckoutAddressUi.firstLoad
	);
	const [chosenAddressId, setChosenAddressId] = useState(checkoutData?.shippingAddressId ?? null);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const onSaveAddressClick = async () => {
		if (!!chosenAddressId) {
			const checkoutDataClone = cloneDeep(checkoutData);
			checkoutDataClone.shippingAddressId = chosenAddressId;
			checkoutDataClone.collectionAddressId = null;

			await onSubmit({checkoutData: checkoutDataClone});
		}
	};

	const onUseAddressClick = (addressId) => {
		setChosenAddressId(addressId);
	};

	const showAddressDropdown = () => {
		setCurrentUiState(CheckoutAddressUi.addressDropdown);
	};

	const showNoAddressOptions = () => {
		setCurrentUiState(CheckoutAddressUi.noAddressOptions);
	};

	const renderAddressString = (address) => {
		const country = countries.find(x => x.countryId === address.countryId);
		const province = provinces.find(x => x.provinceId === address.provinceId);
		const addressArray = [
			address.streetAddress,
			address.suburb,
			address.city,
			address.postalCode,
			province?.name,
			country?.name
		];
		return addressArray.filter(Boolean).join(", ");
	};

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (!!userAddresses && userAddresses.length > 0) {
			showAddressDropdown();
		}
		else {
			showNoAddressOptions();
		}
	}, [userAddresses]);

	useEffect(() => {
		if (checkoutData?.shippingAddressId) {
			setChosenAddressId(checkoutData.shippingAddressId);
		}
	}, [checkoutData]);

	return (
		<>
			<PageHeader title="Checkout" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: 2 }}>
					{!!checkoutData && (
						<Grid container spacing={2}>
							<Grid item xs={12} md={9}>
								<Paper elevation={3} sx={{ padding: '1.5rem' }}>
									<Box sx={{ marginBottom: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
										<Typography variant="body2" color="textPrimary" sx={{ fontWeight: 'bold' }}>
											Shipping Address
										</Typography>
										<Button
											size="small"
											variant="outlined"
											color="error"
											startIcon={<AddIcon fontSize="small" />}
											onClick={gotoCreateAddressPage}
										>
											Add Address
										</Button>
									</Box>
									{currentUiState === CheckoutAddressUi.noAddressOptions && (
										<Typography variant="body2" sx={{ color: 'gray' }}>
											No addresses found.
										</Typography>
									)}
									{currentUiState === CheckoutAddressUi.addressDropdown && (
										<List sx={{ width: '100%', backgroundColor: '#fafafa', borderRadius: '8px' }}>
											{userAddresses.map((address, index) => {
												const country = countries.find((x) => x.countryId === address.countryId);
												const province = provinces.find((x) => x.provinceId === address.provinceId);
												const displayName = `${address.firstName} ${address.lastName}`.trim();
												const addressArray = [
													address.streetAddress,
													address.suburb,
													address.city,
													address.postalCode,
													province?.name,
													country?.name,
												];
												const addressString = addressArray.filter(Boolean).join(', ');

												return (
													<React.Fragment key={index}>
														<ListItem sx={{ paddingY: '0.5rem' }}>
															<ListItemText
																primary={displayName}
																secondary={addressString}
																sx={{ flexGrow: 1 }}
															/>
															{chosenAddressId === address.addressId ? (
																<Button variant="contained"
																        color="success"
																        size="small"
																        startIcon={<CheckIcon fontSize="small" />}
																        disabled>
																	Selected
																</Button>
															) : (
																<Button variant="contained"
																        color="error"
																        size="small"
																        onClick={() => onUseAddressClick(address.addressId)}
																        sx={{ textTransform: 'none' }}
																>
																	Use
																</Button>
															)}
														</ListItem>
														{index < userAddresses.length - 1 && <Divider />}
													</React.Fragment>
												);
											})}
										</List>
									)}
								</Paper>
							</Grid>
							<Grid item xs={12} md={3}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									logisticsFee={checkoutData.logisticsFee}
									total={checkoutData.total}
									sx={{ marginBottom: '2rem' }}
									buttonDisabled={!chosenAddressId}
									buttonText="Continue"
									onButtonClick={onSaveAddressClick}
								/>
							</Grid>
						</Grid>
					)}
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ backgroundColor: '#f9f9f9', minHeight: '50vh', padding: 1 }}>
					<Paper elevation={3} sx={{ padding: '1rem', borderRadius: '8px', backgroundColor: '#ffffff', marginBottom: '1rem' }}>
						<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
							<Typography variant="body2" color="textPrimary">
								Shipping Address
							</Typography>
							<IconButton
								color="error"
								onClick={gotoCreateAddressPage}
							>
								<AddCircleIcon fontSize="small" />
							</IconButton>
						</Box>

						{currentUiState === CheckoutAddressUi.noAddressOptions && (
							<Typography variant="body2" sx={{ color: '#777', padding: '1rem 0', textAlign: 'center' }}>
								No addresses found.
							</Typography>
						)}

						{currentUiState === CheckoutAddressUi.addressDropdown && (
							<>
								<Divider sx={{ marginBottom: '1rem' }} />
								<Box>
									{userAddresses.map((address, index) => (
										<Box key={index} sx={{ padding: '1rem', borderRadius: '8px', border: '1px solid #ddd', marginBottom: '1rem' }}>
											<Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
												{`${address.firstName} ${address.lastName}`}
											</Typography>
											<Typography variant="body2" color="textSecondary" sx={{ marginBottom: '0.5rem' }}>
												{renderAddressString(address)}
											</Typography>
											{chosenAddressId === address.addressId ? (
												<Button variant="contained" size="small" color="success" startIcon={<CheckIcon fontSize="small" />} fullWidth disabled>
													Selected
												</Button>
											) : (
												<Button
													variant="outlined"
													size="small"
													color="error"
													onClick={() => onUseAddressClick(address.addressId)}
													fullWidth
													sx={{ textTransform: 'none' }}
												>
													Use
												</Button>
											)}
										</Box>
									))}
								</Box>
							</>
						)}
					</Paper>

					<CartSummary
						vat={checkoutData.vatPercentage}
						cartNettTotal={checkoutData.subtotal}
						settlementAmount={checkoutData.settlementAmount}
						vatAmount={checkoutData.vatAmount}
						logisticsFee={checkoutData.logisticsFee}
						total={checkoutData.total}
						buttonText="Continue"
						buttonDisabled={!chosenAddressId}
						onButtonClick={onSaveAddressClick}
					/>
				</Box>
			}
		</>
	);
};

export default CheckoutShippingAddress;
