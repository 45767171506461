import {
	getApplicationManufacturers, getApplicationModels, getApplicationVariants, getApplicationYears,
	getManufacturers,
	getModels,
	getVariants,
	getVehicles,
	getYears
} from "../../api/vehicleController";
import { capitalize } from "lodash";

export const fetchManufacturers = async ({
	year,
	setManufacturerData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getManufacturers(year);
		if (response.success) {
			setManufacturerData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchApplicationMakes = async ({
	auth0Token,
	sku,
	setApplicationMakes,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getApplicationManufacturers({
			auth0Token,
			sku
		});
		if (response.success) {
			setApplicationMakes(response.data?.sort());
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchModels = async ({
	manufacturer,
	year,
	setModelData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getModels(manufacturer, year);
		if (response.success) {
			setModelData(response.data?.sort());
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchApplicationModels = async ({
	auth0Token,
	sku,
	make,
	setApplicationModels,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getApplicationModels({
			auth0Token,
			sku,
			make
		});
		if (response.success) {
			setApplicationModels(response.data?.sort());
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchYears = async ({
	manufacturer,
	model,
	variant,
	setYearData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getYears(manufacturer, model, variant);
		if (response.success) {
			setYearData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchApplicationYears = async ({
	auth0Token,
	sku,
	make,
	model,
	variant,
	setApplicationYears,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getApplicationYears({
			auth0Token,
			sku,
			make,
			model,
			variant
		});
		if (response.success) {
			setApplicationYears(response.data?.sort());
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchVariants = async ({
	manufacturer,
	model,
	year,
	setVariantData,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getVariants(manufacturer, model, year);
		if (response.success) {
			setVariantData(response.data);
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};

export const fetchApplicationVariants = async ({
	auth0Token,
	sku,
	make,
	model,
	setApplicationVariants,
	setStatusInProgress,
	setStatusSuccess,
	setStatusFailed,
	setStatusError
}) => {
	setStatusInProgress();
	try {
		const response = await getApplicationVariants({
			auth0Token,
			sku,
			make,
			model
		});
		if (response.success) {
			setApplicationVariants(response.data?.sort());
			setStatusSuccess();
		}
		else {
			setStatusFailed();
		}
	}
	catch (e) {
		console.error(e);
		setStatusError();
	}
};
