import React, { useEffect, useState } from "react";
import { AccountType, LogisticsMethod, ScreenSize } from "../../utils/constants";
import { find, round } from "lodash";
import { CartSummary } from "../CartSummary";
import useSystemHook from "../../hooks/SystemHook";
import {
	Alert,
	Box,
	Button,
	Collapse,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardOutlined";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import PageHeader from "../PageHeader";
import { SouthAfricanRand } from "../../utils/helpers";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CheckoutSummary = ({
	checkoutData,
	accountType,
	billingAddresses,
	shippingAddresses,
	collectionAddresses,
	salesPeople,
	tecfinityAccountName,
	tecfinityAccountNumber,
	countries,
	provinces,
	gotoCheckoutOrderInformation,
	gotoCheckoutBillingAddress,
	gotoCheckoutCollectionAddress,
	gotoCheckoutDeliveryMethod,
	gotoCheckoutShippingAddress,
	onSubmit
}) => {
	const {screenSize} = useSystemHook();
	const [salesPerson, setSalesPerson] = useState(null);
	const [billingAddress, setBillingAddress] = useState(null);
	const [shippingAddress, setShippingAddress] = useState(null);
	const [collectionAddress, setCollectionAddress] = useState(null);
	const [showValidationErrors, setShowValidationErrors] = useState(null);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	const onGoToPaymentClick = async () => {
		if (!checkoutData?.billingAddressId || !checkoutData?.logisticsMethod ||
			(checkoutData?.logisticsMethod === LogisticsMethod.Ship && !checkoutData?.shippingAddressId)) {
			setShowValidationErrors(true);
		}
		await onSubmit(checkoutData);
	};

	const onChangeBillingAddressClick = () => {
		setShowValidationErrors(false);
		gotoCheckoutBillingAddress();
	}

	const onChangeOrderInformationClick = () => {
		setShowValidationErrors(false);
		gotoCheckoutOrderInformation();
	}

	const onChangeLogisticsMethodClick = () => {
		setShowValidationErrors(false);
		gotoCheckoutDeliveryMethod();
	}

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		if (
			!!billingAddresses &&
			!!checkoutData?.billingAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				billingAddresses,
				(x) => x.addressId === checkoutData.billingAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setBillingAddress(address);
			}
		}
	}, [checkoutData?.billingAddressId, billingAddresses, countries, provinces]);

	useEffect(() => {
		if (
			!!shippingAddresses &&
			!!checkoutData?.shippingAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				shippingAddresses,
				(x) => x.addressId === checkoutData.shippingAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setShippingAddress(address);
			}
		}
	}, [
		checkoutData?.shippingAddressId,
		shippingAddresses,
		countries,
		provinces
	]);

	useEffect(() => {
		if (
			!!collectionAddresses &&
			!!checkoutData?.collectionAddressId &&
			!!countries &&
			!!provinces
		) {
			const address = find(
				collectionAddresses,
				(x) => x.addressId === checkoutData.collectionAddressId
			);
			if (address) {
				const country = find(
					countries,
					(x) => x.countryId === address.countryId
				);
				const province = find(
					provinces,
					(x) => x.provinceId === address.provinceId
				);

				address.country = country.name ?? "";
				address.province = province.name ?? "";

				setCollectionAddress(address);
			}
		}
	}, [
		checkoutData?.collectionAddressId,
		collectionAddresses,
		countries,
		provinces
	]);

	useEffect(() => {
		if (!!checkoutData?.salesPersonCode && !!salesPeople) {
			const person = find(
				salesPeople,
				(x) => x.code === checkoutData.salesPersonCode
			);
			setSalesPerson(person);
		}
	}, [checkoutData?.salesPersonCode]);

	return (
		<>
			<PageHeader title="Checkout" />
			{screenSize === ScreenSize.Desktop &&
				<Box sx={{ minHeight: '100vh', backgroundColor: '#f7f7f7', padding: 2 }}>

					{!!checkoutData && (
						<Grid container spacing={3}>
							<Grid item xs={12} md={9}>
								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<ShoppingCartIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
										Order Information
										<Button
											size="small"
											variant="outlined"
											color="error"
											startIcon={<EditIcon fontSize="small" />}
											sx={{ marginLeft: 'auto' }}
											onClick={onChangeOrderInformationClick}
										>
											Change
										</Button>
									</Typography>

									<List disablePadding>
										<ListItem>
											<ListItemText primary={<Typography variant="caption"><b>Email:</b> {checkoutData.email}</Typography>} />
										</ListItem>
										{accountType === AccountType.Commercial && !!tecfinityAccountName && !!tecfinityAccountNumber && (
											<ListItem>
												<ListItemText primary={<Typography variant="caption"><b>Account:</b> {`${tecfinityAccountName} - ${tecfinityAccountNumber}`}</Typography>} />
											</ListItem>
										)}
										{!!salesPerson && (
											<ListItem>
												<ListItemText primary={<Typography variant="caption"><b>Sales Person:</b> {salesPerson.name}</Typography>} />
											</ListItem>
										)}
									</List>
								</Paper>

								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<HomeIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
										Billing Address
										<Button
											variant="outlined"
											size="small"
											color="error"
											startIcon={<EditIcon fontSize="small" />}
											sx={{ marginLeft: 'auto' }}
											onClick={onChangeBillingAddressClick}
										>
											Change
										</Button>
									</Typography>

									{!!billingAddress &&
										<List disablePadding>
											<ListItem>
												<ListItemText primary={<Typography variant="caption">{billingAddress?.firstName} {billingAddress?.lastName}</Typography>} />
											</ListItem>
											<ListItem>
												<ListItemText primary={<Typography variant="caption">{billingAddress?.streetAddress}, {billingAddress?.city}</Typography>} />
											</ListItem>
											<ListItem>
												<ListItemText primary={<Typography variant="caption">{billingAddress?.province}, {billingAddress?.country}</Typography>} />
											</ListItem>
										</List>
									}

									{!billingAddress && showValidationErrors === true &&
										<Alert severity="warning">Please provide a billing address</Alert>
									}
								</Paper>

								<Paper elevation={3} sx={{ padding: '1.5rem', marginBottom: '1.5rem' }}>
									<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
										<LocalShippingIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
										Delivery Method
										<Button
											size="small"
											variant="outlined"
											color="error"
											startIcon={<EditIcon fontSize="small" />}
											sx={{ marginLeft: 'auto' }}
											onClick={onChangeLogisticsMethodClick}
										>
											Change
										</Button>
									</Typography>


									{!checkoutData?.logisticsMethod && showValidationErrors === true &&
										<Alert severity="warning">Please choose a delivery method.</Alert>
									}

									{!!checkoutData?.logisticsMethod &&
										<List disablePadding>
											<ListItem>
												<ListItemText primary={<Typography variant="caption">{checkoutData.logisticsMethod === LogisticsMethod.Ship ? 'Delivery' : 'Collection'}</Typography>} />
											</ListItem>
										</List>
									}

									{checkoutData.logisticsMethod === LogisticsMethod.Ship && (
										<>
											{!shippingAddress && showValidationErrors === true &&
												<Alert severity="warning">Please choose a delivery address.</Alert>
											}
											{!!shippingAddress &&
												<>
													<Divider sx={{ my: '1.5rem' }} />
													<Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
														<LocalShippingIcon sx={{ marginRight: '0.5rem' }} fontSize="small" />
														Shipping Address
														<Button
															size="small"
															variant="outlined"
															color="error"
															startIcon={<EditIcon fontSize="small" />}
															sx={{ marginLeft: 'auto' }}
															onClick={gotoCheckoutShippingAddress}
														>
															Change
														</Button>
													</Typography>
													<List disablePadding>
														<ListItem>
															<ListItemText primary={<Typography variant="caption">{shippingAddress?.firstName} {shippingAddress?.lastName}</Typography>} />
														</ListItem>
														<ListItem>
															<ListItemText primary={<Typography variant="caption">{shippingAddress?.streetAddress}, {shippingAddress?.city}</Typography>} />
														</ListItem>
														<ListItem>
															<ListItemText primary={<Typography variant="caption">{shippingAddress?.province}, {shippingAddress?.country}</Typography>} />
														</ListItem>
													</List>
												</>
											}
										</>
									)}
								</Paper>
							</Grid>
							<Grid item xs={12} md={3}>
								<CartSummary
									vat={checkoutData.vatPercentage}
									cartNettTotal={checkoutData.subtotal}
									settlementAmount={checkoutData.settlementAmount}
									vatAmount={checkoutData.vatAmount}
									logisticsFee={checkoutData.logisticsFee}
									total={checkoutData.total}
									sx={{ marginBottom: '2rem' }}
									buttonText="Go to Payment"
									onButtonClick={onGoToPaymentClick}
								/>
							</Grid>
						</Grid>
					)}
				</Box>
			}
			{screenSize === ScreenSize.Mobile &&
				<Box sx={{ minHeight: '80vh', padding: 2, backgroundColor: '#f7f7f7' }}>
					{!!checkoutData && (
						<>
							<Paper elevation={3} sx={{ padding: 2, mb: 2 }}>
								<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
									<ShoppingCartIcon fontSize="small" sx={{ mr: 1 }} />
									<Typography variant="body2" sx={{ flex: 1 }}>
										Order Information
									</Typography>
									<IconButton size="small"
									            color="error"
									            onClick={onChangeOrderInformationClick}>
										<EditIcon fontSize="small" />
									</IconButton>
								</Box>

								<List disablePadding>
									<ListItem>
										<ListItemText primary={<Typography variant="caption"><b>Email:</b> {checkoutData.email}</Typography>} />
									</ListItem>
									{accountType === AccountType.Commercial && !!tecfinityAccountName && !!tecfinityAccountNumber && (
										<ListItem>
											<ListItemText primary={<Typography variant="caption"><b>Account:</b> {`${tecfinityAccountName} - ${tecfinityAccountNumber}`}</Typography>} />
										</ListItem>
									)}
									{!!salesPerson && (
										<ListItem>
											<ListItemText primary={<Typography variant="caption"><b>Sales Person:</b> {salesPerson.name}</Typography>} />
										</ListItem>
									)}
								</List>
							</Paper>

							<Paper elevation={3} sx={{ padding: 2, mb: 2 }}>
								<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
									<HomeIcon fontSize="small" sx={{ mr: 1 }} />
									<Typography variant="body2" sx={{ flex: 1 }}>
										Billing Address
									</Typography>
									<IconButton size="small"
									            color="error"
									            onClick={onChangeBillingAddressClick}>
										<EditIcon fontSize="small" />
									</IconButton>
								</Box>

								{!!billingAddress &&
									<List disablePadding>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.firstName} {billingAddress?.lastName}</Typography>} />
										</ListItem>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.streetAddress}, {billingAddress?.city}</Typography>} />
										</ListItem>
										<ListItem>
											<ListItemText primary={<Typography variant="caption">{billingAddress?.province}, {billingAddress?.country}</Typography>} />
										</ListItem>
									</List>
								}
								{!billingAddress && showValidationErrors === true &&
									<Alert severity="warning">Please provide a billing address</Alert>
								}
							</Paper>

							<Paper elevation={3} sx={{ padding: 2 }}>
								<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
									<LocalShippingIcon fontSize="small" sx={{ mr: 1 }} />
									<Typography variant="body2" sx={{ flex: 1 }}>
										Delivery Method
									</Typography>
									<IconButton size="small"
									            color="error"
									            onClick={onChangeLogisticsMethodClick}>
										<EditIcon fontSize="small" />
									</IconButton>
								</Box>

								{!!checkoutData?.logisticsMethod &&
									<List disablePadding>
										<ListItem>
											<ListItemText primary={<Typography variant="caption" component="p">
												{checkoutData.logisticsMethod === "Ship" ? 'Delivery' : 'Collection'}
											</Typography>} />
										</ListItem>
									</List>
								}
								{!checkoutData?.logisticsMethod && showValidationErrors === true &&
									<Alert severity="warning">Please choose a delivery method.</Alert>
								}

								{checkoutData.logisticsMethod === "Ship" && (
									<>
										{!shippingAddress && showValidationErrors === true &&
											<Alert severity="warning">Please choose a delivery address.</Alert>
										}
										{!!shippingAddress &&
											<>
												<Divider sx={{ my: 2 }} />
												<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
													<LocalShippingIcon fontSize="small" sx={{ mr: 1 }} />
													<Typography variant="body2" sx={{ flex: 1 }}>
														Shipping Address
													</Typography>
													<IconButton size="small"
													            color="error"
													            onClick={gotoCheckoutShippingAddress}>
														<EditIcon fontSize="small" />
													</IconButton>
												</Box>
												<List disablePadding>
													<ListItem>
														<ListItemText primary={<Typography variant="caption">{shippingAddress?.firstName} {shippingAddress?.lastName}</Typography>} />
													</ListItem>
													<ListItem>
														<ListItemText primary={<Typography variant="caption">{shippingAddress?.streetAddress}, {shippingAddress?.city}</Typography>} />
													</ListItem>
													<ListItem>
														<ListItemText primary={<Typography variant="caption">{shippingAddress?.province}, {shippingAddress?.country}</Typography>} />
													</ListItem>
												</List>
											</>
										}
									</>
								)}
							</Paper>

							<CartSummary
								vat={checkoutData.vatPercentage}
								cartNettTotal={checkoutData.subtotal}
								settlementAmount={checkoutData.settlementAmount}
								vatAmount={checkoutData.vatAmount}
								logisticsFee={checkoutData.logisticsFee}
								total={checkoutData.total}
								sx={{ marginBottom: '2rem' }}
								buttonText="Go to Payment"
								onButtonClick={onGoToPaymentClick}
							/>
						</>
					)}
				</Box>
			}
		</>
	);
};

export default CheckoutSummary;
